import { getRequest, postRequest, putRequest } from "utils/useApi";

export const handleGetUsers = async (filter?: any) => {
  return getRequest("users", filter).then((response) => {
    return response.data;
  });
};

export const handleCreateTeamUserLink = async (user: any) => {
  return postRequest("teams-users-link", user).then((response) => {
    return response.data;
  });
};

export const handleUpdateTeamUserLink = async (id: Number, user: any) => {
  return putRequest(`teams-users-link/${id}`, user).then((response) => {
    return response.data;
  });
};
