import { TeamUserLink } from "users/types";
import styles from "../Filter.module.scss";
import { User } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { RootState } from "app/redux/store";

type Props = {
  handleSelect: (type: "user" | "customer", value: string, id: number) => void;
  users: TeamUserLink[];
};

const FilterUsers = ({ handleSelect, users }: Props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const notMeUsers = users.filter((u) => u.user_id !== user.id);
  const me = users.find((u) => u.user_id === user.id);

  const renderProfilPicture = (user: TeamUserLink) => {
    if (user?.media?.url) {
      return (
        <div className={styles.picture}>
          <img src={`${process.env.REACT_APP_API}${user.media.url}`} alt="" />
        </div>
      );
    } else {
      return (
        <div className={styles.profilSvg}>
          <User />
        </div>
      );
    }
  };

  return (
    <>
      {notMeUsers.map((u) => (
        <li key={u.id}>
          <button
            className={styles.actionItemUser}
            onClick={() => handleSelect("user", u?.username, Number(u.user_id))}
          >
            {renderProfilPicture(u)}
            {u?.username}
            <p className={styles.points}>
              {u?.nbPointsDone} sur {u?.nbPointsTotal}
            </p>
          </button>
        </li>
      ))}
      {me && (
        <li className={styles.test} key={me.id}>
          <button
            className={styles.actionItemUserMe}
            onClick={() =>
              handleSelect("user", me?.username, Number(me.user_id))
            }
          >
            {renderProfilPicture(me)}
            Filtrer sur mes tâches
          </button>
        </li>
      )}
    </>
  );
};

export default FilterUsers;
