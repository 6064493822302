const FenekIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 107">
      <mask
        id="FenekIcon"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="88"
        height="107"
      >
        <path
          d="M88 62.955c0 8.81-.43 20.824-4.889 27.705-1.026 1.583-2.085 1.654-5.523.72a44.268 44.268 0 0 1-6.268 6.068A43.813 43.813 0 0 1 44 106.955c-24.3 0-44-19.7-44-44A43.8 43.8 0 0 1 5.56 41.53c1.068-1.914-3.154-16.897-1.821-18.62 3.361-4.35 3.604-3.707 8.34-6.543C18.678 12.414 31.72 0 39.973 0 47.17 0 65.323 3.67 71.32 6.733c4.63 2.364 10.735 9.237 14.236 13.013 1.12 1.209-7.109 15.782-6.128 17.11A43.803 43.803 0 0 1 88 62.956Z"
          fill="#EEC42E"
        />
      </mask>
      <g mask="url(#FenekIcon)">
        <circle cx="44" cy="62.955" r="44" fill="#EEC42E" />
        <path
          d="m26.848 78.613-21.24 11.77v24.869l20.09 9.292 21.682 2.744 16.284-2.744 18.585-5.487v-13.806L79.77 88.525l-14.868-7.611v7.61l-8.142 19.648-19.47-17.17c1.983-.707 5.782-6.607 7.434-9.469-2.69.071-10.797-3.097-14.514-4.69l-3.363 1.77Z"
          fill="#241E79"
        />
        <path
          d="m56.938 108.26-19.47-16.726 2.92-3.275 6.284-6.726 2.92 2.92 10 3.01 5.222-3.01v7.08l-7.876 16.727Z"
          fill="#AFB9F9"
        />
        <path
          d="m47.911 101.8 1.062-17.877 8.762 3.363 3.808 10.394-5.224 10.492-8.408-6.372Z"
          fill="#EE702E"
        />
        <path
          d="M34.99 126.58c-.85-15.576-10.443-35.872-15.134-44.073l6.815-3.186c7.01 2.903 15.015 33.128 18.142 47.878l-9.823-.619ZM66.85 123.925c.567-6.656-1.474-31.093-2.566-42.48l5.222.796c2.69 2.974 4.366 27.435 4.867 39.294l-7.522 2.39Z"
          fill="#788DF5"
        />
        <path
          d="M63.769 78.876c-1.357-2.124-4.204-6.15-4.956-7.257-.85.44-2.653 1.424-4.248 2.27m9.204 4.987c1.045.93 3.16 2.249 5.575 3.62m-5.575-3.62c.206 1.248.438 2.802.68 4.602m-37.673-4.602c2.903-1.486 6.726-6.46 9.027-9.292 3.79-.433 10.778 1.9 14.602 4.025m-23.63 5.267c4.72 7.349 14.851 27.267 17.612 48.149M26.776 78.876c-1.156.592-3.752 2.055-6.814 3.81m6.814-3.81c2.69 4.138 6.425 8.437 10.426 12.479m7.185 35.67a47.3 47.3 0 0 0 2.744-.093c7.203-.462 13.82-1.643 19.381-3.02m-22.125 3.113c-3.14.015-6.294-.275-9.38-.781M19.961 82.685a1748.425 1748.425 0 0 0-13.718 7.962v24.868c5.087 3.213 16.39 8.703 28.762 10.729M19.962 82.685c4.041 6.296 12.708 23.823 15.044 43.559m34.338-43.748c3.472 1.97 7.565 4.047 10 5.23 2.833 7.364 2.833 23.718 2.479 30.975-1.698.838-4.563 2.016-8.32 3.227m-4.159-39.432c1.918 6.343 5.434 23.11 4.16 39.432m0 0c-2.074.669-4.42 1.347-6.992 1.984m0 0c.775-14.423-.777-30.887-2.062-40.434m0 0c-3.181-3.522-6.157-6.347-8.115-8.084m8.115 8.084c-.013 2.892-1.634 10.146-3.49 15.874m-6.395-25.464c-.813.43-1.572.826-2.124 1.094-.468-.424-1.171-.893-2.036-1.373m4.16.28c.406.323 1.012.833 1.77 1.505m-5.93-1.785c-.656 1.257-1.728 3.127-3.107 5.267M37.202 91.355c3.944-3.632 7.544-8.517 10.096-12.479M37.202 91.355c3.485 3.52 7.173 6.846 10.548 9.699m8.585-25.66c-.024.973-.719 3.439-3.098 6.588m-5.939-3.106c.522 1.007.952 1.853 2.001 3.106m3.938 0a27.918 27.918 0 0 1-1.858 2.204c-.336-.372-1.579-1.606-2.08-2.204m3.938 0c2.098 2.357 5.755 10.573 7.723 17.37m0 0c-1.174 3.623-1.824 5.553-4.183 8.818-2.262-1.631-5.47-4.111-9.027-7.116m1.55-19.072c-.836 1.745-1.691 8.565-1.55 19.072"
          stroke="#000"
          strokeWidth="2.876"
        />
        <path
          d="M47.278 80.997c-8.748.757-26.319-3.524-26.61-26.7C13.094 44.033 9.68 41.49 9.68 21.873c7.235.152 23.449 3.36 30.424 14.985 2.028-.848 5.54-1.271 8.9 0 2.422-5.964 4.45-13.713 24.067-24.975 4.177 18.164.908 24.975-2.906 37.871-1.573 5.316-.546 15.984-.546 21.433 0 3.996-.926 8.5-4.631 9.082-.212 2.452-2.906 6.54-5.903 6.993-5.22.791-9.082-2.543-11.807-6.266Z"
          fill="#F7E0C7"
        />
        <path
          d="M65.308 41.7c-4.525-1.927-5.485-2.047-8.366-2.752.769-8.926 8.992-15.12 11.647-16.517.96 9.51-.797 13.73-3.28 19.268ZM32.475 40.22c-5.818.903-8.327 4.463-9.263 5.63-4.287-4.787-5.358-13.135-5.358-16.71 5.92 2.438 11.942 8.068 14.621 11.08Z"
          fill="#EAC49B"
        />
        <path
          opacity=".1"
          d="M45.008 72.733c3.197 2.18 5.176 6.326 4.722 8.264-22.304.944-29.425-16.832-29.152-25.974L12.677 43.76 9.77 28.322l1.362-4.904c1.308 13.005 14.622 23.976 20.252 26.973-1.635 1.635-5.176 10.081-1.18 15.167 4.995 6.357 10.807 4.45 14.803 7.174Z"
          fill="#93520C"
        />
        <path
          d="M59.085 87.263c2.997-.454 5.69-4.54 5.903-6.993-.515-.424-2.144-1.199-4.541-.908-2.997.363-7.992 1.763-13.169 1.635 2.725 3.723 6.586 7.057 11.807 6.266Z"
          fill="#EAC49B"
        />
        <path
          d="M47.278 80.997c-8.748.757-26.319-3.524-26.61-26.7C13.094 44.033 9.68 41.49 9.68 21.873c7.235.152 23.449 3.36 30.424 14.985 2.028-.848 5.54-1.271 8.9 0 2.422-5.964 4.45-13.713 24.067-24.975 4.177 18.164.908 24.975-2.906 37.871-1.573 5.316-.546 15.984-.546 21.433 0 3.996-.926 8.5-4.631 9.082m-17.71.727c2.725 3.723 6.586 7.057 11.807 6.266 2.997-.454 5.69-4.54 5.903-6.993m-17.71.727c5.177.128 10.172-1.272 13.169-1.635 2.397-.29 4.026.484 4.54.908"
          stroke="#000"
          strokeWidth="2.876"
        />
        <path
          d="M50.491 60.672c-.89 5.84-6.428 9.875-12.407 8.963-5.978-.911-10.06-6.414-9.17-12.254.89-5.84 6.428-9.875 12.406-8.964 5.979.912 10.062 6.416 9.171 12.255Z"
          stroke="#000"
          strokeWidth="2.876"
        />
        <path
          d="M47.564 52.05c1.276-.94 4.471-2.332 7.042-.396"
          stroke="#000"
          strokeWidth="2.876"
        />
        <path
          d="M75.358 57.85c-.89 5.84-6.428 9.875-12.406 8.964-5.979-.912-10.062-6.415-9.171-12.255.89-5.84 6.428-9.875 12.407-8.963 5.978.911 10.06 6.415 9.17 12.254Z"
          stroke="#000"
          strokeWidth="2.876"
        />
        <path
          d="m55.634 73.14 5.006 3.134 5.347-3.693c-3.64-3.76-8.42-1.193-10.353.56Z"
          fill="#000"
        />
        <path
          d="M38.225 60.725c.261-.94 1.093-2.887 2.329-3.157 1.236-.269 2.943 1.125 3.642 1.856M59.424 58.47c.261-.94 1.093-2.887 2.33-3.157 1.236-.269 2.942 1.125 3.641 1.856"
          stroke="#000"
          strokeWidth="2.876"
          strokeLinecap="round"
        />
        <path
          d="M61.858 37.453c-11.297 6.948-27.81 6.113-34.654 4.827L40.358 6.392l21.5 31.061Z"
          fill="#EB3E2A"
        />
        <ellipse
          cx="33.53"
          cy="30.97"
          rx="2.731"
          ry="2.496"
          transform="rotate(-1.416 33.53 30.97)"
          fill="#fff"
        />
        <ellipse
          cx="46.105"
          cy="17.191"
          rx="2.731"
          ry="2.496"
          transform="rotate(-1.416 46.105 17.191)"
          fill="#fff"
        />
        <ellipse
          cx="52.684"
          cy="35.49"
          rx="2.731"
          ry="2.496"
          transform="rotate(-1.416 52.684 35.49)"
          fill="#fff"
        />
        <ellipse
          cx="37.914"
          cy="40.848"
          rx="2.731"
          ry="2.496"
          transform="rotate(-1.416 37.914 40.848)"
          fill="#fff"
        />
        <ellipse
          cx="44.885"
          cy="28.116"
          rx="2.731"
          ry="2.496"
          transform="rotate(-1.416 44.885 28.116)"
          fill="#fff"
        />
        <ellipse
          cx="37.398"
          cy="19.979"
          rx="2.731"
          ry="2.496"
          transform="rotate(-1.416 37.398 19.979)"
          fill="#fff"
        />
        <path
          opacity=".13"
          d="M44 10.497 33.36 42.85h-5.465L40.261 6.758l3.74 3.739Z"
          fill="#000"
        />
        <path
          d="M61.858 37.454C50.56 44.4 34.047 43.566 27.203 42.28L40.358 6.392l21.5 31.062Z"
          stroke="#000"
          strokeWidth="2.876"
          strokeLinecap="square"
        />
        <path
          d="m81.85 84.827-20.235-5.31-12.438 2.012 31.078 9.157a1.15 1.15 0 0 0 1.436-.805l.977-3.644a1.15 1.15 0 0 0-.819-1.41Z"
          fill="#F5F5F5"
        />
        <path
          d="M60.248 84.549c2.876-.36 5.076-.863 7.837-3.451M66.863 86.778c3.667-.144 4.213-1.798 6.974-4.386M73.908 88.79c2.588-.79 3.422-2.084 6.183-4.672"
          stroke="#EB3E2A"
          strokeWidth="2.301"
        />
        <path
          d="m81.85 84.827-20.235-5.31-12.438 2.012 31.078 9.157a1.15 1.15 0 0 0 1.436-.805l.977-3.644a1.15 1.15 0 0 0-.819-1.41Z"
          stroke="#000"
          strokeWidth="2.876"
        />
      </g>
    </svg>
  );
};

export default FenekIcon;
