import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/useApi";
import { CreateMission, UpdateOrderMission } from "clients/interfaces/mission";

export const handleGetAllMissions = (filters?: any) => {
  return getRequest("missions", filters).then((response) => {
    return response.data;
  });
};

export const handleGetMission = (id: any) => {
  return getRequest(`missions/${id}`).then((response) => {
    return response.data;
  });
};

export const handleCreateMission = (mission: CreateMission) => {
  return postRequest("missions", mission).then((response) => {
    return response.data;
  });
};

export const handleUpdateMission = (
  id: number,
  mission: CreateMission | UpdateOrderMission
) => {
  return putRequest(`missions/${id}`, mission).then((response) => {
    return response.data;
  });
};

export const handleDeleteMission = (id: number) => {
  return deleteRequest(`missions/${id}`).then((response) => {
    return response.data;
  });
};
