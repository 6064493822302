import { ArrowRight, CalendarBlank, Trash, X } from "@phosphor-icons/react";
import Switch from "ui/components/switch/Switch";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import { useParams } from "react-router-dom";
import {
  handleCreateMissionProjectLink,
  handleDeleteMissionProjectLink,
  handleUpdateMissionProjectLink,
} from "clients/controllers/missionsProjectsLink";
import {
  CreateMissionProjectLink,
  MissionProjectLink,
} from "clients/interfaces/missionProjectLink";
import { useState } from "react";
import styles from "./AddMissionProjectPopup.module.scss";
import { Mission } from "clients/interfaces/mission";
import Popup from "ui/components/popup/Popup";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  mission: Mission;
  setPopupOpen: (value: boolean) => void;
  edit?: boolean;
  data?: MissionProjectLink;
  setEditMissions: (value: boolean) => void;
  setModifyingProject: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddMissionProjectPopup = ({
  mission,
  setPopupOpen,
  edit,
  data,
  setEditMissions,
  setModifyingProject,
}: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [hasDate, setHasDate] = useState(
    (data && data.start_date !== null && data.end_date !== null) || false
  );
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(
    (data?.start_date && new Date(data?.start_date)) || new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    (data?.end_date && new Date(data?.end_date)) || null
  );
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const humanStartDate = startDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const humanEndDate = endDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const handleDeleteOpen = () => {
    setDeletePopupOpen(true);
  };

  const handleCalendarOpen = () => {
    setShowCalendar(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!id) {
      return;
    }
    let missionProjectLink: CreateMissionProjectLink;
    if (hasDate && startDate && endDate) {
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      missionProjectLink = {
        hours: Number(e.target.hours.value),
        mission_id: mission.id,
        project_id: Number(id),
        start_date: startDateString,
        end_date: endDateString,
      };
    } else {
      missionProjectLink = {
        hours: Number(e.target.hours.value),
        mission_id: mission.id,
        project_id: Number(id),
        start_date: null,
        end_date: null,
      };
    }
    if (edit) {
      handleUpdateMissionProjectLink(Number(data?.id), missionProjectLink)
        .then((res) => {
          setEditMissions(true);
          setModifyingProject(true);
          setPopupOpen(false);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    } else {
      handleCreateMissionProjectLink(missionProjectLink)
        .then((res) => {
          setEditMissions(true);
          setModifyingProject(true);
          setPopupOpen(false);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  };

  const handleDeleteMission = () => {
    if (data?.id) {
      handleDeleteMissionProjectLink(Number(data.id))
        .then((res) => {
          setEditMissions(true);
          setModifyingProject(true);
          setPopupOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="m-popup__header"
        style={{ backgroundColor: mission.color }}
      >
        <p>
          {edit ? "Modifier" : "Ajouter"} une mission - {mission.name}
        </p>
        <button
          className="m-popup__header__close"
          onClick={() => setPopupOpen(false)}
        >
          <X weight="bold" />
        </button>
      </div>
      <div className="m-popup__content">
        <div className={styles.input}>
          <label htmlFor="hours" className="m-label">
            Nombre d’heures
          </label>
          <input
            defaultValue={data?.hours || 0}
            min={0}
            step="0.5"
            type="number"
            id="hours"
            className="m-input m-input--grey"
          />
        </div>
        <div className={styles.date}>
          <div className={styles.dateButton}>
            <p>Définir une période pour cette mission</p>
            <Switch
              id="qs"
              name="dqds"
              checked={hasDate}
              setChecked={setHasDate}
            />
          </div>
          {hasDate && (
            <>
              <button
                className={styles.selectDate}
                onClick={handleCalendarOpen}
                type="button"
              >
                <CalendarBlank weight="fill" />
                <p className={styles.startDate}>{humanStartDate}</p>
                <ArrowRight />
                <p className={styles.endDate}>
                  {humanEndDate || "Choisir une date"}
                </p>
              </button>
              {showCalendar && (
                <Popup setIsOpen={setShowCalendar} width="648px">
                  <div className={styles.calendar}>
                    <CalendarDouble
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      setIsOpen={setShowCalendar}
                    />
                  </div>
                </Popup>
              )}
            </>
          )}
        </div>
        <div className={styles.buttons}>
          <button type="submit" className="m-button m-button--black">
            Enregistrer
          </button>
          {edit && (
            <button
              onClick={handleDeleteOpen}
              type="button"
              className="m-button"
            >
              <Trash weight="fill" />
              Supprimer la mission
            </button>
          )}
          {deletePopupOpen && (
            <Popup setIsOpen={setDeletePopupOpen} width="496px">
              <div className="m-popup__header">
                <p>Supprimer la mission</p>
                <button
                  className="m-popup__header__close"
                  onClick={() => setDeletePopupOpen(false)}
                >
                  <X weight="bold" />
                </button>
              </div>
              <div className="m-popup__content">
                <p className="m-popup__content__text-grey">
                  Confirmez-vous la suppression de la mission de ce projet ?
                  Cette action est irréversible.
                </p>
                <button
                  type="button"
                  onClick={handleDeleteMission}
                  className="m-button m-button--black"
                >
                  <Trash weight="fill" /> Supprimer la mission
                </button>
              </div>
            </Popup>
          )}
        </div>
      </div>
    </form>
  );
};

export default AddMissionProjectPopup;
