import { actionTypesBacklogStories } from "sprint/actions/backlogStories";
import { StoryType } from "story/types";

export type State = Readonly<StoryType[]>;

const initialState: State = [];

const backlogStoriesState = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypesBacklogStories.UPDATE_BACKLOG_STORIES:
      return [...action.payload];
    
    default:
      return state;
  }
};

export default backlogStoriesState;
