import { LightningSlash, X } from "@phosphor-icons/react";
import { useState } from "react";
import FinishSprintModal from "sprint/components/finish-sprint/finish-sprint-modal/FinishSprintModal";
import Popup from "ui/components/popup/Popup";

type Props = {
  closePopup: (e: React.MouseEvent) => void;
  sprintNb: string;
  sprintId: number;
};

const FinishPopup = ({ closePopup, sprintNb, sprintId }: Props) => {
  const [finishSprintPopup, setFinishSprintPopup] = useState<boolean>(false);

  const openFinishSprintPopup = () => {
    setFinishSprintPopup(true);
  };

  return (
    <>
      <div className="m-popup__header">
        <p>Terminer le sprint</p>
        <button className="m-popup__header__close" onClick={closePopup}>
          <X weight="bold" />
        </button>
      </div>
      <div className="m-popup__content">
        <p className="m-popup__content__text-grey">
          Voulez-vous vraiment terminer le <b>sprint n°{sprintNb}</b> ?
        </p>
        <button
          className="m-button m-button--black"
          onClick={openFinishSprintPopup}
        >
          <LightningSlash weight="fill" />
          Terminer le sprint
        </button>
      </div>
      {finishSprintPopup && (
        <Popup setIsOpen={setFinishSprintPopup} width="496px">
          <FinishSprintModal setIsOpen={setFinishSprintPopup} />
        </Popup>
      )}
    </>
  );
};

export default FinishPopup;
