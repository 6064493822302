import { useCallback, useEffect, useMemo } from "react";

import styles from "./Toast.module.scss";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "app/redux/store";
import { Check, X } from "@phosphor-icons/react";

import { showModal } from "app/actions/modal";

const Toast = () => {
  const toast = useSelector((state: RootState) => state.toast.modal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toast && toast.status)
      var timer = setTimeout(() => {
        dispatch(showModal(null));
      }, 3000);

    return () => clearTimeout(timer);
  }, [toast, dispatch]);

  const renderIcon = useMemo(() => {
    if (toast && toast.error) return <X weight="bold" />;
    return <Check weight="bold" />;
  }, [toast]);

  const renderMessage = useCallback(() => {
    if (toast.message) return toast.message;
    else if (toast.error) return "Une erreur est survenue";
    else return "Opération réussie";
  }, [toast]);

  const renderToast = useMemo(() => {
    if (!toast) return null;
    return (
      <div
        data-cy="toast"
        className={`${styles.main} ${
          toast.error ? styles.error : styles.success
        }`}
      >
        {renderIcon}
        {renderMessage()}
      </div>
    );
  }, [toast, renderIcon, renderMessage]);

  return <>{renderToast}</>;
};

export default Toast;
