import { Mission } from "clients/interfaces/mission";
import styles from "./ReportMission.module.scss";
import Switch from "ui/components/switch/Switch";

type Props = {
  mission: Mission;
  isChecked: boolean;
  missionsChecked: number[];
  setMissionsChecked: (missionsChecked: number[]) => void;
};

const ReportMission = ({
  mission,
  missionsChecked,
  setMissionsChecked,
}: Props) => {
  const handleCheckMission = (check: boolean) => {
    if (check) {
      setMissionsChecked([...missionsChecked, mission.id]);
    } else {
      setMissionsChecked(
        missionsChecked.filter((missionId) => missionId !== mission.id)
      );
    }
  };

  return (
    <div className={styles.container}>
      <label
        className={styles.mission}
        htmlFor={mission.name}
        style={{ backgroundColor: mission.color }}
      >
        {mission.name}
      </label>
      <Switch
        id={mission.name}
        checked={missionsChecked.includes(mission.id)}
        setChecked={handleCheckMission}
        name={mission.name}
      />
    </div>
  );
};

export default ReportMission;
