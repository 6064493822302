import { errorsAPI } from "app/constants/errors";
import { ModalType } from "app/types/modal";
import { destroyCookie } from "nookies";
export const actionTypesModal = {
  SHOW_MODAL: "SHOW_MODAL",
};
type Nullable<T> = T | null;

export const showModal = (modal: Nullable<ModalType>) => {
  if ((!modal?.message || modal.message === "") && modal?.error === true) {
    modal!.message = "Une erreur est survenue";
  }
  if (modal && modal.message === errorsAPI.TOKEN_FALSE) {
    destroyCookie(null, "token");
  }
  return {
    type: actionTypesModal.SHOW_MODAL,
    payload: modal,
  };
};
