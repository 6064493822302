import { createContext, useState } from "react";

type EditingTimerContextType = {
  editingTimer: boolean;
  setEditingTimer: (value: boolean) => void;
};

export const EditingTimerContext = createContext<EditingTimerContextType>({
  editingTimer: false,
  setEditingTimer: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const EditingTimerProvider = ({ children }: Props) => {
  const [editingTimer, setEditingTimer] = useState(false);

  return (
    <EditingTimerContext.Provider value={{ editingTimer, setEditingTimer }}>
      {children}
    </EditingTimerContext.Provider>
  );
};
