import { Mission } from "clients/interfaces/mission";
import { ArrowLeft } from "@phosphor-icons/react";
import styles from "./HandleMissions.module.scss";
import MissionItem from "../mission-item/MissionItem";
import { ReactSortable } from "react-sortablejs";
import { handleUpdateMission } from "clients/controllers/missions";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  changeHandleMissions: () => void;
  setEditMissions: (value: boolean) => void;
  missions: Mission[];
  setMissions: (value: Mission[]) => void;
};

const HandleMissions = ({
  changeHandleMissions,
  setEditMissions,
  missions,
  setMissions,
}: Props) => {
  const dispatch = useDispatch();

  const onChange = (e: any) => {
    const liList = e.to.querySelectorAll("li");
    liList.forEach((li: HTMLElement) => {
      const id = li.getAttribute("data-id");
      const index = Array.prototype.indexOf.call(liList, li);
      handleUpdateMission(Number(id), { order: index })
        .then(() => {})
        .catch(() => {
          console.log("error");
          const toastData = {
            status: true,
            message: "Une erreur est survenue",
            error: true,
          };
          dispatch(showModal(toastData));
        });
    });
  };

  return (
    <>
      <div className={styles.head}>
        <button
          className={`m-button m-button--grey m-button--ratio`}
          onClick={changeHandleMissions}
        >
          <ArrowLeft weight="bold" />
          <span className="sr-only">Retour</span>
        </button>
        <p className={styles.title}>Gérer les missions</p>
      </div>
      <ReactSortable
        handle=".handle"
        animation={300}
        easing="cubic-bezier(1, 0, 0, 1)"
        tag="ul"
        list={missions}
        className={styles.missions}
        setList={setMissions}
        onChange={onChange}
      >
        {missions.map((mission) => (
          <li
            key={mission.id}
            data-id={mission.id}
            className={styles.missionItem}
          >
            <MissionItem data={mission} setEditMissions={setEditMissions} />
          </li>
        ))}
      </ReactSortable>
    </>
  );
};

export default HandleMissions;
