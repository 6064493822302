import { getTokenFromCookie } from "middlewares/authorizations";
import { Navigate } from "react-router-dom";

export const RedirectedRoutes = ({ children }: any) => {
  const token = getTokenFromCookie();
  if (token) {
    return <Navigate to={"/planification"} />;
  }
  return children;
};
