import styles from "./TextTrunc.module.scss";
import { useState, useRef, useEffect } from "react";

type Props = {
  text: string;
  color: string;
  maxWidth: number;
};

const TextTrunc = ({ text, color, maxWidth }: Props) => {
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const pRef = useRef<HTMLParagraphElement>(null);

  const renderDivClasses = () => {
    if (isTextTruncated) {
      return `${styles.container} ${styles.containerData}`;
    } else {
      return styles.container;
    }
  };

  useEffect(() => {
    if (pRef.current) {
      setIsTextTruncated(maxWidth === pRef.current.clientWidth);
      return;
    }
    setIsTextTruncated(false);
  }, [maxWidth, pRef]);

  return (
    <div className={renderDivClasses()} data-text={text}>
      <p
        className={isTextTruncated ? styles.tooltip : styles.tag}
        style={{
          color: color,
          maxWidth: maxWidth,
        }}
        ref={pRef}
      >
        {text}
      </p>
    </div>
  );
};

export default TextTrunc;
