import { setCookie } from "nookies";

import { userLogin, userType } from "account/types";
import { postRequest, postRequestWithoutToken, putRequest } from "utils/useApi";

export const handleLogin = (user: userLogin) => {
  const cookiesOptions = {
    maxAge: 31536000,
    path: "/",
  };

  return postRequestWithoutToken("login", user).then((response) => {
    setCookie(null, "token", response.data.data.token.token, cookiesOptions);
    return response.data;
  });
};

export const handleRegistration = (user: userType) => {
  return postRequest("users", user).then((response) => {
    return response.data;
  });
};

export const handleModifyUser = (user: userType, id: string) => {
  return putRequest(`users/${id}`, user).then((response) => {
    return response.data;
  });
};

export const handleForgetPassword = (email: string) => {
  return postRequest(`forget-password`, email).then((response) => {
    return response.data;
  });
};

export const handleEditProfil = (form: object) => {
  return putRequest(`edit-profil`, form).then((response) => {
    return response.data;
  });
};
