import styles from "./Profil.module.scss";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import HeaderColor from "ui/components/header-color/HeaderColor";
import { useState } from "react";
import InformationsPersonnelles from "profil/components/informations-personnelles/InformationsPersonnelles";
import MotDePasse from "profil/components/mot-de-passe/MotDePasse";

const Profil = () => {
  const [active, setActive] = useState("infos");

  const changePage = () => {
    if (active === "infos") {
      setActive("password");
    } else {
      setActive("infos");
    }
  };

  const renderLinks = () => {
    if (active === "infos") {
      return (
        <>
          <p className={styles.activePage}>Informations personnelles</p>
          <button onClick={changePage} className={styles.inactivePage}>
            Mot de passe
          </button>
        </>
      );
    } else {
      return (
        <>
          <button onClick={changePage} className={styles.inactivePage}>
            Informations personnelles
          </button>
          <p className={styles.activePage}>Mot de passe</p>
        </>
      );
    }
  };

  return (
    <ProfilLayout>
      <div className="layout-container">
        <HeaderColor color="#151419">
          <div className={styles.headerContainer}>
            <h1 className={styles.title}>Mon profil</h1>
            <div className={styles.subpages}>{renderLinks()}</div>
          </div>
        </HeaderColor>
        <div className={styles.container}>
          {active === "infos" ? <InformationsPersonnelles /> : <MotDePasse />}
        </div>
      </div>
    </ProfilLayout>
  );
};

export default Profil;
