import styles from "./CalendarDouble.module.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

registerLocale("fr", fr);

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setIsOpen?: (value: boolean) => void;
};

const CalendarDouble = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setIsOpen,
}: Props) => {
  const onChange = (dates: (Date | null)[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end !== null) {
      setIsOpen && setIsOpen(false);
    }
  };

  return (
    <div className={styles.container}>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        locale="fr"
        monthsShown={2}
        selectsRange
        inline
        startDate={startDate}
        endDate={endDate}
        dateFormat={"dd/MM/yyyy"}
        formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 3)}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
          <div className={styles.calendarHeader}>
            <button
              type="button"
              onClick={decreaseMonth}
              className={styles.decreaseMonth}
            >
              <CaretLeft weight="bold" />
            </button>
            <p>
              {monthDate.toLocaleString("fr", {
                month: "long",
                year: "numeric",
              })}
            </p>
            <button
              type="button"
              onClick={increaseMonth}
              className={styles.increaseMonth}
            >
              <CaretRight weight="bold" />
            </button>
          </div>
        )}
      />
    </div>
  );
};

export default CalendarDouble;
