import { useState, useRef, useContext } from "react";
import styles from "./UpdateTimer.module.scss";
import { ArrowRight } from "@phosphor-icons/react";
import CalendarSingle from "app/components/calendar-single/CalendarSingle";
import { formatDay, getHours, getMinutes } from "utils/formatDate";
import { handleUpdateTimer } from "timer/controllers/timer";
import { EditingTimerContext } from "timer/context/EditingTimerProvider";
import { TimerType } from "timer/types";

type Props = {
  startDate: string;
  endDate: string;
  timerId: number;
  setIsOpen: (value: boolean) => void;
  setAllTimers?: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const UpdateTimer = ({
  startDate,
  endDate,
  timerId,
  setIsOpen,
  setAllTimers,
}: Props) => {
  const { setEditingTimer } = useContext(EditingTimerContext);

  const startHourRef = useRef<HTMLInputElement>(null);
  const startMinuteRef = useRef<HTMLInputElement>(null);
  const endHourRef = useRef<HTMLInputElement>(null);
  const endMinuteRef = useRef<HTMLInputElement>(null);

  const [startHour, setStartHour] = useState<string>(getHours(startDate));
  const [startMinute, setStartMinute] = useState<string>(getMinutes(startDate));
  const [endHour, setEndHour] = useState<string>(getHours(endDate));
  const [endMinute, setEndMinute] = useState<string>(getMinutes(endDate));
  const [day, setDay] = useState<Date>(new Date(startDate));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newStartDate = new Date(day);
    newStartDate.setHours(Number(startHour));
    newStartDate.setMinutes(Number(startMinute));
    newStartDate.setSeconds(0);

    const newEndDate = new Date(day);
    newEndDate.setHours(Number(endHour));
    newEndDate.setMinutes(Number(endMinute));
    newEndDate.setSeconds(0);

    await handleUpdateTimer(timerId, {
      start_at: formatDay(newStartDate),
      end_at: formatDay(newEndDate),
    });

    if (setAllTimers) {
      setAllTimers((allTimers) =>
        allTimers.map((t) =>
          t.id === timerId
            ? {
                ...t,
                start_at: formatDay(newStartDate),
                end_at: formatDay(newEndDate),
              }
            : t
        )
      );
    }

    setIsOpen(false);
    setEditingTimer(true);
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles.hours}>
        <div className={styles.begin}>
          <p className={styles.hourTitle}>Début</p>
          <div className={styles.inputs}>
            <input
              type="text"
              min="0"
              max="23"
              value={startHour}
              ref={startHourRef}
              onChange={(e) => {
                let value = e.target.value;
                if (value.length > 1) {
                  value = value.replace(/^0+/, ""); // Remove leading zeros if more than one digit
                }
                let numericValue = parseInt(value, 10) || 0;
                if (numericValue > 23) {
                  numericValue = 23; // Set to 23 if value is not a number or exceeds 23
                }

                if (numericValue > 9) {
                  // focus next input if value is two digits
                  startMinuteRef.current?.focus();
                }

                if (numericValue < 10) {
                  setStartHour("0" + numericValue.toString());
                } else {
                  setStartHour(numericValue.toString());
                }
              }}
            />
            <span>:</span>
            <input
              ref={startMinuteRef}
              type="text"
              min="0"
              max="59"
              value={startMinute}
              onChange={(e) => {
                let value = e.target.value;
                if (value.length > 1) {
                  value = value.replace(/^0+/, ""); // Remove leading zeros if more than one digit
                }
                let numericValue = parseInt(value, 10) || 0;
                if (numericValue > 59) {
                  numericValue = 59; // Set to 23 if value is not a number or exceeds 23
                }

                if (numericValue > 9) {
                  // focus next input if value is two digits
                  endHourRef.current?.focus();
                }

                if (numericValue < 10) {
                  setStartMinute("0" + numericValue.toString());
                } else {
                  setStartMinute(numericValue.toString());
                }
              }}
            />
          </div>
        </div>
        <ArrowRight className={styles.arrow} />
        <div className={styles.end}>
          <p className={styles.hourTitle}>Fin</p>
          <div className={styles.inputs}>
            <input
              type="text"
              min="0"
              max="23"
              ref={endHourRef}
              value={endHour}
              onChange={(e) => {
                let value = e.target.value;
                if (value.length > 1) {
                  value = value.replace(/^0+/, ""); // Remove leading zeros if more than one digit
                }
                let numericValue = parseInt(value, 10) || 0;
                if (numericValue > 23) {
                  numericValue = 23; // Set to 23 if value is not a number or exceeds 23
                }

                if (numericValue > 9) {
                  // focus next input if value is two digits
                  endMinuteRef.current?.focus();
                }

                if (numericValue < 10) {
                  setEndHour("0" + numericValue.toString());
                } else {
                  setEndHour(numericValue.toString());
                }
              }}
            />
            <span>:</span>
            <input
              ref={endMinuteRef}
              type="text"
              min="0"
              max="59"
              value={endMinute}
              onChange={(e) => {
                let value = e.target.value;
                if (value.length > 1) {
                  value = value.replace(/^0+/, ""); // Remove leading zeros if more than one digit
                }
                let numericValue = parseInt(value, 10) || 0;
                if (numericValue > 59) {
                  numericValue = 59; // Set to 23 if value is not a number or exceeds 23
                }

                if (numericValue < 10) {
                  setEndMinute("0" + numericValue.toString());
                } else {
                  setEndMinute(numericValue.toString());
                }
              }}
            />
          </div>
        </div>
      </div>
      <CalendarSingle startDate={day} setStartDate={setDay} size="small" />
      <button className="m-button m-button--black" type="submit">
        Modifier
      </button>
    </form>
  );
};

export default UpdateTimer;
