import { useState, useEffect } from "react";
import styles from "./CustomerProjectTimer.module.scss";
import Dropdown from "app/components/dropdown/Dropdown";
import { Customer } from "clients/interfaces/customer";
import { Project } from "clients/interfaces/project";
import { handleGetCustomerSuggestions } from "timer/controllers/timer";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import useDebounce from "hooks/useDebounce";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { useContext } from "react";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

const CustomerProjectTimer = ({ setIsMenuOpen }: Props) => {
  const dispatch = useDispatch();
  const { currentTimer, setCurrentTimer } = useContext(CurrentTimerContext);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] =
    useState<Array<{ customer: Customer; project?: Project }>>();

  const debouncedSearchValue = useDebounce(inputValue, 300);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const selectSuggestion = (suggestion: {
    customer: Customer;
    project?: Project;
  }) => {
    setCurrentTimer({
      ...currentTimer,
      customer: suggestion.customer,
      project: suggestion?.project,
      customer_id: suggestion.customer.id,
      project_id: suggestion.project?.id,
    });
    setIsOpen(false);
    setInputValue("");
  };

  const handleResetSelect = () => {
    setCurrentTimer({
      ...currentTimer,
      customer_id: undefined,
      project_id: undefined,
      customer: undefined,
      project: undefined,
    });
    setInputValue("");
  };

  const renderSuggestion = (
    suggestion: { customer: Customer; project?: Project },
    canDelete: boolean
  ) => {
    if (suggestion.project) {
      return (
        <ProjectTag
          content={suggestion.customer.name}
          contentColor={suggestion.customer.color}
          project={suggestion.project.name}
          size="small"
          onDelete={canDelete ? handleResetSelect : undefined}
          mainTimer={true}
        />
      );
    } else {
      return (
        <ProjectTag
          content={suggestion.customer.name}
          contentColor={suggestion.customer.color}
          size="small"
          onDelete={canDelete ? handleResetSelect : undefined}
          mainTimer={true}
        />
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [isOpen, setIsMenuOpen]);

  useEffect(() => {
    if (!isOpen) return;
    handleGetCustomerSuggestions(debouncedSearchValue)
      .then((res) => {
        setSuggestions(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [debouncedSearchValue, isOpen, dispatch]);

  return (
    <>
      {currentTimer?.customer ? (
        <div className={styles.selectedCustomer}>
          {renderSuggestion(
            { customer: currentTimer.customer, project: currentTimer.project },
            true
          )}
        </div>
      ) : (
        <input
          className={styles.input}
          type="text"
          placeholder="Client et projet"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
        />
      )}
      {isOpen && (
        <div className={styles.container}>
          <Dropdown setIsOpen={setIsOpen} padding={false} color={false}>
            <div className={styles.suggestionsContainer}>
              <p className={styles.suggestions}>
                {inputValue === "" ? "Suggérés" : "Recherche"}
              </p>
              <div className={styles.tags}>
                {suggestions?.map((suggestion) => (
                  <button
                    key={`${suggestion.customer.id}-${
                      suggestion.project?.id || suggestion.customer.name
                    }`}
                    onClick={() => selectSuggestion(suggestion)}
                  >
                    {renderSuggestion(suggestion, false)}
                  </button>
                ))}
              </div>
            </div>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default CustomerProjectTimer;
