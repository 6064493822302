import styles from "./ProjectCard.module.scss";

type Props = {
  title: string;
  projectsNb: number;
  color: string;
};

const ProjectCard = ({ title, projectsNb, color }: Props) => {
  return (
    <div className={styles.container} style={{ borderLeftColor: color }}>
      <p className={styles.title}>{title}</p>
      <p className={styles.text}>
        {projectsNb} projet{projectsNb > 1 ? "s" : ""}
      </p>
    </div>
  );
};

export default ProjectCard;
