import styles from "./Planning.module.scss";
import CtaIcon from "ui/components/cta-icon/CtaIcon";
import PlanningIcon from "ui/icons/PlanningIcon";

const Planning = () => {
  return (
    <div className="layout-container">
      <div className={styles.wrapper}>
        <div className="container">
          <h1 className={styles.title}>Planning</h1>
          <div className={styles.content}>
            <CtaIcon
              icon={<PlanningIcon />}
              title="Créez vos premiers clients, projets
              et missions pour commencer"
              subtitle="Vous pourrez ensuite définir des périodes pour gérer votre planning macro"
              buttonLabel="Gérer vos clients et projets"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planning;
