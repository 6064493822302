import styles from "./SelectCustomer.module.scss";
import { Customer } from "clients/interfaces/customer";
import { Project } from "clients/interfaces/project";
import useDebounce from "hooks/useDebounce";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useState, useEffect } from "react";
import {
  handleGetCustomerSuggestions,
  handleUpdateTimer,
} from "timer/controllers/timer";
import { TimerType } from "timer/types";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  timer: TimerType;
  timerCustomer?: Customer;
  setTimerCustomer: (customer: Customer) => void;
  timerProject?: Project;
  setTimerProject: (project: Project) => void;
  setAllTimers?: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const SelectCustomer = ({
  timer,
  timerCustomer,
  setTimerCustomer,
  timerProject,
  setTimerProject,
  setAllTimers,
}: Props) => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] =
    useState<Array<{ customer: Customer; project?: Project }>>();
  const [search, setSearch] = useState<string>("");

  const debouncedSearchValue = useDebounce(search, 300);

  const searchSuggestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const renderSuggestion = (suggestion: {
    customer: Customer;
    project?: Project;
  }) => {
    if (suggestion.project) {
      return (
        <ProjectTag
          content={suggestion.customer.name}
          contentColor={suggestion.customer.color}
          project={suggestion.project.name}
          size="small"
        />
      );
    } else {
      return (
        <ProjectTag
          content={suggestion.customer.name}
          contentColor={suggestion.customer.color}
          size="small"
        />
      );
    }
  };

  const filteredSuggestions = suggestions?.filter(
    (suggestion) =>
      !(
        suggestion.customer.id === timerCustomer?.id &&
        suggestion.project?.id === timerProject?.id
      )
  );

  const selectSuggestion = async (suggestion: {
    customer: Customer;
    project?: Project;
  }) => {
    if (
      timerCustomer?.id === suggestion.customer.id &&
      timerProject?.id === suggestion.project?.id
    ) {
      setTimerCustomer({} as Customer);
      setTimerProject({} as Project);
    } else {
      setTimerCustomer(suggestion.customer);
      setTimerProject(suggestion.project || ({} as Project));
      setSearch("");
    }
    try {
      if (timerCustomer?.id === suggestion.customer.id) {
        await handleUpdateTimer(timer.id, {
          customer_id: null,
          project_id: null,
        });
      } else {
        await handleUpdateTimer(timer.id, {
          customer_id: suggestion.customer?.id || null,
          project_id: suggestion.project?.id || null,
        });
      }
      if (setAllTimers) {
        if (timerCustomer?.id === suggestion.customer.id) {
          setAllTimers((allTimers: TimerType[]) =>
            allTimers.map((t) => {
              if (t.id === timer.id) {
                return {
                  ...t,
                  customer: undefined,
                  project: undefined,
                  customer_id: undefined,
                  project_id: undefined,
                };
              } else {
                return t;
              }
            })
          );
        } else {
          setAllTimers((allTimers: TimerType[]) =>
            allTimers.map((t) => {
              if (t.id === timer.id) {
                return {
                  ...t,
                  customer: suggestion.customer,
                  project: suggestion.project,
                  customer_id: suggestion.customer.id,
                  project_id: suggestion.project?.id,
                };
              } else {
                return t;
              }
            })
          );
        }
      }
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetCustomerSuggestions(debouncedSearchValue)
      .then((res) => {
        setSuggestions(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [debouncedSearchValue, dispatch]);

  return (
    <div className={styles.container}>
      <div className="m-search m-search--grey">
        <input
          type={"text"}
          placeholder="Rechercher un client ou un projet"
          onChange={searchSuggestion}
          value={search}
        />
        <MagnifyingGlass weight="bold" />
      </div>
      {timerCustomer?.id && (
        <div className={styles.selectedCustomer}>
          <p className={styles.suggestions}>Sélectionné</p>
          <button
            className={styles.selectedCustomerButton}
            onClick={() =>
              selectSuggestion({
                customer: timerCustomer,
                project: timerProject,
              })
            }
          >
            {renderSuggestion({
              customer: timerCustomer,
              project: timerProject,
            })}
          </button>
        </div>
      )}
      {filteredSuggestions && filteredSuggestions.length > 0 && (
        <>
          <p className={styles.suggestions}>
            {search === "" ? "Suggérés" : "Recherche"}
          </p>
          <ul className={styles.suggestionsList}>
            {filteredSuggestions?.map((suggestion) => (
              <button
                key={`${suggestion.customer.id}-${
                  suggestion.project?.id || suggestion.customer.name
                }`}
                onClick={() => selectSuggestion(suggestion)}
              >
                {renderSuggestion(suggestion)}
              </button>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default SelectCustomer;
