import styles from "./Report.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { handleGetReport } from "clients/controllers/reports";
import { ArrowRight, FileText, Printer } from "@phosphor-icons/react";
import LogoIcon from "ui/icons/LogoIcon";
import ProgressBar from "ui/components/progress-bar/ProgressBar";
import { ReportType } from "clients/interfaces/report";
import { secondsToHours } from "utils/formatTime";
import { Mission } from "clients/interfaces/mission";
import MissionCard from "clients/components/mission-card/MissionCard";
import History from "clients/components/history/History";
import LoadingIcon from "ui/icons/LoadingIcon";
import { formatDate } from "utils/formatDate";
import SidePopup from "ui/components/side-popup/SidePopup";
import ReportSettings from "clients/components/report-settings/ReportSettings";
import { getTokenFromCookie } from "middlewares/authorizations";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

const Report = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState<ReportType>({} as ReportType);
  const [loading, setLoading] = useState<boolean>(true);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const openSettings = () => {
    setSettingsOpen(true);
  };

  const print = () => {
    window.print();
  };

  useEffect(() => {
    if (!id) return;
    handleGetReport(id)
      .then((res) => {
        setLoading(false);
        setReport(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        setLoading(false);
        const token = getTokenFromCookie();
        if (!token) navigate("/connexion");
        else navigate("/planification");
      });
  }, [id, navigate, dispatch]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>
          <LoadingIcon />
        </div>
      ) : (
        <>
          <header className={styles.header}>
            <div className={styles.logo}>
              <LogoIcon />
            </div>
            <div className={styles.infos}>
              <h1 className={styles.title}>{report.project.name}</h1>
              <p>
                {report.customer.name} • {formatDate(report.start_at)}{" "}
                <ArrowRight weight="bold" /> {formatDate(report.end_at)}
              </p>
            </div>

            <div className={styles.buttons}>
              <button
                onClick={print}
                className="m-button m-button--black-opacity"
              >
                <Printer weight="fill" />
                Imprimer en PDF
              </button>
              <button
                onClick={openSettings}
                className="m-button m-button--white"
              >
                <FileText weight="fill" />
                Partager
              </button>
              {settingsOpen && (
                <SidePopup setIsOpen={setSettingsOpen} dataLoaded={true}>
                  <ReportSettings
                    isPublicData={report.is_public}
                    reportId={report.id}
                    setReport={setReport}
                  />
                </SidePopup>
              )}
            </div>
          </header>
          <main className={styles.mainWrapper}>
            <h2 className={styles.subtitle}>Missions du projet</h2>
            {report.missions && report.missions.length > 0 ? (
              <>
                <div className={styles.time}>
                  <p className={styles.timeTitle}>Temps total</p>
                  <ProgressBar
                    color="#000000"
                    backgroundColor="#D4D9E8"
                    completedPercent={
                      (report.used_time &&
                        report.total_hours &&
                        (report.used_time * 100) / (report.hours * 3600)) ||
                      0
                    }
                  />
                  <div className={styles.timeNumbers}>
                    <p>
                      {report.used_time && secondsToHours(report.used_time)}{" "}
                      consommées
                      {report.total_hours &&
                      report.used_time &&
                      report.total_hours * 3600 - report.used_time < 0 ? (
                        <span className={styles.red}>
                          +{" "}
                          {secondsToHours(
                            Math.abs(report.hours * 3600 - report.used_time)
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <p>sur {report.total_hours}h</p>
                  </div>
                </div>
                <ul className={styles.missionsList}>
                  {report.missions &&
                    report.missions.map((mission: Mission) => (
                      <li key={mission.id} className={styles.missionsItem}>
                        <MissionCard mission={mission} />
                      </li>
                    ))}
                </ul>
              </>
            ) : (
              <p className={styles.noMission}>
                Aucune mission n’a été créée sur ce projet
              </p>
            )}

            <h2 className={styles.timersTitle}>Historique du projet</h2>
            {report.timers.length > 0 ? (
              <ul className={styles.historyList}>
                {report.timers.map((timer) => (
                  <li key={timer.id}>
                    <History timer={timer} />
                  </li>
                ))}
              </ul>
            ) : (
              <p className={styles.noTimer}>
                Aucun timer n'est disponible pour ces filtres
              </p>
            )}
          </main>
        </>
      )}
    </div>
  );
};

export default Report;
