import {
  CaretDown,
  MagnifyingGlass,
  SortAscending,
  SortDescending,
} from "@phosphor-icons/react";
import styles from "./MissionsStatistics.module.scss";
import { useState, useEffect } from "react";
import Dropdown from "app/components/dropdown/Dropdown";
import { MissionStats } from "statistics/types/statistics";
import { handleGetMissionsStatitistics } from "statistics/controllers/statistics";
import StatisticItem from "../statistic-item/StatisticItem";
import useDebounce from "hooks/useDebounce";
import LoadingIcon from "ui/icons/LoadingIcon";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { secondsToHours } from "utils/formatTime";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  user_id: string | undefined;
};

const MissionsStatistics = ({ startDate, endDate, user_id }: Props) => {
  const dispatch = useDispatch();
  const [filterByTime, setFilterByTime] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [missionsStatistics, setMissionsStatistics] = useState<
    Array<MissionStats>
  >([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const debouncedSearch = useDebounce(search, 300);

  const handleClickFilter = (newState: boolean) => {
    setFilterByTime(newState);
    setOpenFilter(false);
  };

  useEffect(() => {
    const nextDayStart = startDate && new Date(startDate);
    nextDayStart && nextDayStart.setDate(nextDayStart.getDate() + 1);

    const nextDayEnd = endDate && new Date(endDate);
    nextDayEnd && nextDayEnd.setDate(nextDayEnd.getDate() + 2);

    const startDateString = nextDayStart?.toISOString().split("T")[0];
    const endDateString = nextDayEnd?.toISOString().split("T")[0];
    const filters = {
      orderBy: filterByTime ? "time" : "",
      search: debouncedSearch,
      startDate: startDateString,
      endDate: endDateString,
      userFilter: user_id,
    };
    handleGetMissionsStatitistics(filters)
      .then((res) => {
        setMissionsStatistics(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [filterByTime, debouncedSearch, startDate, endDate, user_id, dispatch]);

  const totalTime = missionsStatistics.reduce(
    (total, project) => total + project.time,
    0
  );

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div className={styles.input}>
          <div className="m-search m-search--white">
            <input
              type={"text"}
              placeholder="Rechercher"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <MagnifyingGlass weight="bold" />
          </div>
        </div>
        <div className={styles.sort}>
          <button
            className={`m-button m-button--grey`}
            onClick={() => setOpenFilter(true)}
          >
            {!filterByTime ? (
              <>
                <SortDescending weight="bold" />
                <span>Temps croissants</span>
              </>
            ) : (
              <>
                <SortAscending weight="bold" />
                <span>Temps décroissants</span>
              </>
            )}
            <CaretDown weight="bold" />
          </button>
          {openFilter && (
            <div className={styles.filter}>
              <Dropdown setIsOpen={setOpenFilter}>
                <ul>
                  <li>
                    <button
                      className={styles.dropdownItem}
                      onClick={() => handleClickFilter(false)}
                    >
                      <SortDescending weight="bold" />
                      Temps croissants
                    </button>
                  </li>
                  <li>
                    <button
                      className={styles.dropdownItem}
                      onClick={() => handleClickFilter(true)}
                    >
                      <SortAscending weight="bold" />
                      Temps décroissants
                    </button>
                  </li>
                </ul>
              </Dropdown>
            </div>
          )}
          <button type="button" className={styles.totalTime}>
            Total : {totalTime && secondsToHours(totalTime)}
          </button>
        </div>
      </div>
      {loading ? (
        <div className={styles.loading}>
          <LoadingIcon />
        </div>
      ) : (
        <>
          {missionsStatistics.length === 0 ||
          (user_id && !missionsStatistics.some((m) => m.time > 0)) ? (
            <p className={styles.noMission}>
              Commencez à vous timer sur vos stories de vos clients et projets
              pour visualiser les statistiques
            </p>
          ) : (
            <ul className={styles.list}>
              {missionsStatistics.map((mission) => {
                if (mission.time === 0 && user_id) return "";
                return (
                  <li key={mission.id}>
                    <StatisticItem statistic={mission} />
                  </li>
                );
              })}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default MissionsStatistics;
