import { useState, useEffect, useCallback } from "react";

const useLocalStorage = (key: string, defaultValue: any = null) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue !== null) {
        setValue(JSON.parse(storedValue));
      }
    } catch (error) {
      console.error("Error retrieving data from localStorage:", error);
    }
  }, [key]);

  const updateValue = useCallback(
    (newValue: any) => {
      setValue(newValue);
      localStorage.setItem(key, JSON.stringify(newValue));
    },
    [key]
  );

  const remove = useCallback(() => {
    setValue(defaultValue);
    localStorage.removeItem(key);
  }, [key, defaultValue]);

  return [value, updateValue, remove];
};

export default useLocalStorage;
