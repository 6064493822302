const ClientIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g clipPath="url(#ClientIcon)">
        <path d="M21 4H11a7.008 7.008 0 0 0-7 7v10a7.008 7.008 0 0 0 7 7h6a1 1 0 0 0 .316-.051c3.28-1.093 9.54-7.354 10.633-10.633A1 1 0 0 0 28 17v-6a7.008 7.008 0 0 0-7-7Zm-4 21.928V22a5.006 5.006 0 0 1 5-5h3.928c-1.158 2.694-6.234 7.77-8.928 8.928Z" />
      </g>
      <defs>
        <clipPath id="ClientIcon">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClientIcon;
