import { getRequest } from "utils/useApi";

export const handleGetMissionsStatitistics = (filters: any) => {
  return getRequest("missions/stats", filters).then((response) => {
    return response.data;
  });
};

export const handleGetProjectsStatitistics = (filters: any) => {
  return getRequest("projects/stats", filters).then((response) => {
    return response.data;
  });
};

export const handleGetCustomersStatitistics = (filters: any) => {
  return getRequest("customers/stats", filters).then((response) => {
    return response.data;
  });
};
