import { DotsSixVertical, PencilSimple, Trash } from "@phosphor-icons/react";
import styles from "./MissionItem.module.scss";
import { useState } from "react";
import Popup from "ui/components/popup/Popup";
import AddMissionPopup from "../add-mission-popup/AddMissionPopup";
import { Mission } from "clients/interfaces/mission";
import DeleteMissionPopup from "../delete-mission-popup/DeleteMissionPopup";

type Props = {
  data: Mission;
  setEditMissions: (value: boolean) => void;
};

const MissionItem = ({ data, setEditMissions }: Props) => {
  const [editMission, setEditMission] = useState(false);
  const [deleteMission, setDeleteMission] = useState(false);

  const handleOpenEditMissionPopup = () => {
    setEditMission(true);
  };

  const handleOpenDeleteMissionPopup = () => {
    setDeleteMission(true);
  };

  const handleCloseMissionPopup = () => {
    setEditMission(false);
  };

  const handleCloseDeleteMissionPopup = () => {
    setDeleteMission(false);
  };

  return (
    <div className={styles.missionItem}>
      <div className={`${styles.drag} handle`}>
        <DotsSixVertical />
      </div>
      <div
        className={styles.missionName}
        style={{ backgroundColor: data.color }}
      >
        {data.name}
      </div>
      <button className={styles.edit} onClick={handleOpenEditMissionPopup}>
        <PencilSimple weight="fill" />
        <span className="sr-only">Modifier la mission</span>
      </button>
      {editMission && (
        <Popup setIsOpen={setEditMission} width="472px">
          <AddMissionPopup
            setEditMissions={setEditMissions}
            handleCloseMissionPopup={handleCloseMissionPopup}
            edit
            data={data}
          />
        </Popup>
      )}
      <button className={styles.delete} onClick={handleOpenDeleteMissionPopup}>
        <Trash weight="fill" />
        <span className="sr-only">Supprimer la mission</span>
      </button>
      {deleteMission && (
        <Popup setIsOpen={setDeleteMission} width="472px">
          <DeleteMissionPopup
            setEditMissions={setEditMissions}
            handleCloseDeleteMissionPopup={handleCloseDeleteMissionPopup}
            data={data}
          />
        </Popup>
      )}
    </div>
  );
};

export default MissionItem;
