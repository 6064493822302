import { handleDeleteMission } from "clients/controllers/missions";
import { Mission } from "clients/interfaces/mission";
import { Trash, X } from "@phosphor-icons/react";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  data: Mission;
  handleCloseDeleteMissionPopup: () => void;
  setEditMissions: (value: boolean) => void;
};

const DeleteMissionPopup = ({
  data,
  handleCloseDeleteMissionPopup,
  setEditMissions,
}: Props) => {
  const dispatch = useDispatch();

  const deleteMission = () => {
    handleDeleteMission(data.id)
      .then(() => {
        setEditMissions(true);
        handleCloseDeleteMissionPopup();
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  };

  return (
    <>
      <div className="m-popup__header">
        <p>Supprimer la mission</p>
        <button
          className="m-popup__header__close"
          onClick={handleCloseDeleteMissionPopup}
        >
          <X weight="bold" />
        </button>
      </div>
      <div className="m-popup__content">
        <p className="m-popup__content__text-grey">
          Confirmez-vous la suppression de la mission de ce projet ? Cette
          action est irréversible.
        </p>
        <button onClick={deleteMission} className="m-button m-button--black">
          <Trash weight="fill" />
          Supprimer la mission
        </button>
      </div>
    </>
  );
};

export default DeleteMissionPopup;
