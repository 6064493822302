/* eslint-disable no-undef */
import { useState, useEffect } from "react";

interface Size {
  width?: number;
  height?: number;
}

const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({});

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
