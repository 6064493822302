import { formatSecondsToHours } from "utils/formatDate";
import CustomerProjectTimer from "../customer-project-timer/CustomerProjectTimer";
import MissionsTimer from "../missions-timer/MissionsTimer";
import styles from "./TimerContent.module.scss";
import { CaretLeft, Play, Stop } from "@phosphor-icons/react";
import { handleGetCurrentTimer } from "timer/controllers/timer";
import { useEffect, useState, useContext, useCallback } from "react";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

const TimerContent = () => {
  const dispatch = useDispatch();
  const {
    currentTimer,
    setCurrentTimer,
    timerOpen,
    setTimerOpen,
    triggerTimer,
  } = useContext(CurrentTimerContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState<number | null>(null);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      handleGetCurrentTimer()
        .then((res) => {
          if (res.data.start_at) {
            setCurrentTimer(res.data);
            const startDate = new Date(res.data.start_at);
            const endDate = new Date();
            const diff = endDate.getTime() - startDate.getTime();
            const seconds = Math.floor(diff / 1000);
            setCurrentTime(seconds);
          }
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [setCurrentTimer, dispatch]);

  useEffect(() => {
    handleGetCurrentTimer()
      .then((res) => {
        if (res.data.start_at) {
          setCurrentTimer(res.data);
        }
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange, setCurrentTimer, dispatch]);

  useEffect(() => {
    if (currentTimer?.start_at) {
      const startDate = new Date(currentTimer.start_at);
      const endDate = new Date();
      const diff = endDate.getTime() - startDate.getTime();
      const seconds = Math.floor(diff / 1000);
      setCurrentTime(seconds);
    } else {
      setCurrentTime(null);
    }
  }, [currentTimer?.start_at]);

  useEffect(() => {
    if (typeof currentTime !== "number") return;
    const interval = setInterval(() => {
      setCurrentTime(currentTime + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentTime]);

  const handleClickPlay = async () => {
    if (!currentTimer) return;
    triggerTimer(currentTimer);
  };

  return (
    <div
      className={`${styles.container} ${
        !timerOpen && currentTimer?.id ? styles.timerHalfOpen : styles.timerOpen
      } ${isMenuOpen ? styles.menuOpen : ""}`}
    >
      {!timerOpen && currentTimer?.id && (
        <button
          className={styles.expendTimer}
          onClick={() => setTimerOpen(true)}
        >
          <span className="sr-only">Etendre le timer</span>
          <CaretLeft />
        </button>
      )}
      <div className={styles.item}>
        <input
          className={`${styles.input} ${
            currentTimer?.name ? styles.inputFill : ""
          }`}
          type="text"
          placeholder="Tapez le nom de la story"
          value={currentTimer?.name || currentTimer?.name || ""}
          onChange={(e) =>
            setCurrentTimer({ ...currentTimer, name: e.target.value })
          }
          style={
            currentTimer?.name
              ? {
                  width: `calc(${currentTimer.name.toString().length} * 0.8ch)`,
                }
              : {}
          }
        />
        <input
          className={styles.subinput}
          type="text"
          placeholder="Nom de la sous-tâche"
          value={currentTimer?.sub_task || currentTimer?.sub_task || ""}
          onChange={(e) =>
            setCurrentTimer({ ...currentTimer, sub_task: e.target.value })
          }
        />
      </div>
      <div className={styles.item + " " + styles.infos}>
        <CustomerProjectTimer setIsMenuOpen={setIsMenuOpen} />
        <MissionsTimer setIsMenuOpen={setIsMenuOpen} />
      </div>
      <div className={styles.item}>
        <p className={styles.dayTotalTime}>
          {formatSecondsToHours(currentTime || 0)}
        </p>
        <button className={styles.play} onClick={handleClickPlay}>
          <span className="sr-only">Lancer le timer</span>
          {currentTimer?.end_at === null ? (
            <Stop weight="fill" />
          ) : (
            <Play weight="fill" />
          )}
        </button>
      </div>
    </div>
  );
};

export default TimerContent;
