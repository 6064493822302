/* eslint-disable jsx-a11y/no-redundant-roles */
import styles from "./AddClient.module.scss";
import { colors } from "app/constants/colors";
import { Check, X } from "@phosphor-icons/react";
import { useState } from "react";
import {
  handleCreateCustomer,
  handleUpdateCustomer,
} from "clients/controllers/customers";
import { Customer, CustomersWithNbProjects } from "clients/interfaces/customer";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { ContactInterface } from "clients/interfaces/contact";
import { AccountInterface } from "clients/interfaces/account";
import { useSelector } from "react-redux";
import { RootState } from "app/redux/store";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { errorsAPI } from "app/constants/errors";

type Props = {
  edit?: boolean;
  setCreatingClient?: (value: boolean) => void;
  setShowClient?: (value: boolean) => void;
  data?: Customer | CustomersWithNbProjects;
};

const CreateClient = ({
  edit,
  setCreatingClient,
  setShowClient,
  data,
}: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const [selectedColor, setSelectedColor] = useState(data?.color || colors[0]);
  const id = data?.id ? data.id : 0;

  const onChangeColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColor(e.target.value);
  };

  const handleCreateClient = (e: any) => {
    e.preventDefault();
    let contacts: ContactInterface[];
    let accounts: AccountInterface[];

    if (data?.contacts) {
      contacts = [...data.contacts];
    } else {
      contacts = [];
    }

    if (data?.accounts) {
      accounts = [...data.accounts];
    } else {
      accounts = [];
    }

    const customer = {
      name: e.target.name.value as string,
      color: selectedColor,
      team_id: user.team_selected,
      accounts: accounts,
      contacts: contacts,
    };
    if (edit) {
      handleUpdateCustomer(id, customer)
        .then((res) => {
          if (setCreatingClient) {
            setCreatingClient(true);
          }
          const toastData = {
            status: true,
            message: "Le client a bien été modifié",
            error: false,
          };
          dispatch(showModal(toastData));
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    } else {
      handleCreateCustomer(customer)
        .then((res) => {
          if (setCreatingClient) {
            setCreatingClient(true);
          }
          const toastData = {
            status: true,
            message: "Le client a bien été créé",
            error: false,
          };
          dispatch(showModal(toastData));
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  };

  const handleClose = () => {
    if (setShowClient) {
      setShowClient(false);
    }
  };

  return (
    <ProfilLayout>
      <section
        className={styles.wrapper}
        style={{ backgroundColor: selectedColor }}
        role="region"
      >
        <form onSubmit={handleCreateClient}>
          <div className={styles.header}>
            <p className={styles.title}>
              {edit ? "Modifier le client" : "Créer un client"}
            </p>
            <div className={styles.buttons}>
              <button
                data-cy="submit-create-customer"
                type="submit"
                className="m-button m-button--black"
              >
                Enregistrer
              </button>
              <button
                className="m-button m-button--grey m-button--ratio"
                type="button"
                onClick={handleClose}
              >
                <span className="sr-only">Fermer l'ajout de mission</span>
                <X weight="bold" />
              </button>
            </div>
          </div>
          <input
            type="text"
            placeholder="Entrez le nom du client"
            name="name"
            required
            maxLength={80}
            data-cy="name-customer"
            className={styles.name}
            defaultValue={data?.name}
          />
          <ul className={styles.inputs}>
            {colors.map((color) => (
              <li key={color} className="m-radio">
                <label className="sr-only" htmlFor={color}>
                  Couleur hexadécimale {color}
                </label>
                <input
                  type="radio"
                  value={color}
                  checked={color === selectedColor}
                  onChange={onChangeColor}
                  id={color}
                />
                <div
                  className="checkmark checkmark--black checkmark--border checkmark--big"
                  style={{ backgroundColor: color }}
                >
                  <Check weight="bold" />
                </div>
              </li>
            ))}
          </ul>
        </form>
      </section>
    </ProfilLayout>
  );
};

export default CreateClient;
