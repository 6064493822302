import CtaIcon from "ui/components/cta-icon/CtaIcon";
import PlanificationIcon from "ui/icons/PlanificationIcon";
import styles from "./Backlog.module.scss";
import { useMemo, useState } from "react";
import { StoryType } from "story/types";
import CardStory from "story/components/card-story/CardStory";
import { ArrowUpRight, Plus } from "@phosphor-icons/react";
import SidePopup from "ui/components/side-popup/SidePopup";
import CreateStory from "story/components/create-story/CreateStory";
import { StrictModeDroppable } from "app/components/strict-mode-droppable/StrictModeDroppable";
import { Draggable } from "react-beautiful-dnd";
import Popup from "ui/components/popup/Popup";
import CancelStoryPopup from "story/components/cancel-story-popup/CancelStoryPopup";
import { useNavigate } from "react-router-dom";

type Props = {
  backlogStories: StoryType[];
  typeCard: string | null;
  single?: boolean;
};

const Backlog = ({ backlogStories, typeCard, single }: Props) => {
  const [createStoryPopup, setCreateStoryPopup] = useState<boolean>(false);
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const navigate = useNavigate();

  const showCreateStoryPopup = () => {
    setCreateStoryPopup(true);
  };

  const hideCreateStoryPopup = () => {
    setCreateStoryPopup(false);
    setCancelPopup(false);
  };

  const renderStories = () => {
    return backlogStories.map((story: StoryType, index: number) => {
      return (
        <Draggable
          draggableId={story.id.toString()}
          index={index}
          key={story.id}
        >
          {(provided) => {
            return (
              <CardStory
                key={story.id}
                story={story}
                showPlay={false}
                sprint_id={0}
                provided={provided}
                typeCard={typeCard}
              />
            );
          }}
        </Draggable>
      );
    });
  };

  const backlogPoints = useMemo(() => {
    let points = 0;

    backlogStories.forEach((story: StoryType) => {
      if (story.points) points += story.points;
      if (story.storyTasks) {
        story.storyTasks.forEach((task) => {
          if (task.points) points += task.points;
        });
      }
    });

    return points;
  }, [backlogStories]);

  const redirectSingle = () => {
    navigate("/planification/backlog", { replace: true });
  };

  return (
    <>
      <div className={`${styles.header} ${single ? styles.noHeader : ""}`}>
        <div className="m-top-points">
          <p className="m-top-points__text">Backlog</p>
          <span className="m-points m-points--fill">{backlogPoints}</span>
        </div>
        <button type="button" onClick={redirectSingle}>
          <ArrowUpRight size={16} color="#5B5B6D" />
        </button>
      </div>
      {backlogStories.length > 0 ? (
        <div
          className={`${styles.storiesContainer} ${
            single ? styles.singleStoriesContainer : ""
          }`}
        >
          <StrictModeDroppable droppableId="backlog">
            {(provided) => {
              return (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`${styles.stories} ${
                    single ? styles.singleStories : ""
                  }`}
                >
                  {renderStories()}
                  {provided.placeholder}
                </ul>
              );
            }}
          </StrictModeDroppable>
          <button
            className={`${styles.addNew} ${single ? styles.single : ""}`}
            onClick={showCreateStoryPopup}
          >
            <Plus weight="bold" />
            Ajouter une story
          </button>
          {createStoryPopup && (
            <SidePopup
              setIsOpen={setCreateStoryPopup}
              dataLoaded={true}
              onClickOutside={() => setCancelPopup(true)}
              modal={true}
            >
              <CreateStory
                sprint_id={0}
                type="todo"
                closePopup={hideCreateStoryPopup}
                modal={true}
              />
              {cancelPopup && (
                <Popup setIsOpen={setCancelPopup} width="496px">
                  <CancelStoryPopup
                    closePopup={hideCreateStoryPopup}
                    setIsOpen={setCancelPopup}
                  />
                </Popup>
              )}
            </SidePopup>
          )}
        </div>
      ) : (
        <>
          <div className={styles.add}>
            <CtaIcon
              icon={<PlanificationIcon />}
              title="Ajoutez vos premières stories
          dans votre backlog"
              subtitle="Configurez vos stories en définissant la complexité, les responsables et les tâches à effectuer"
              buttonLabel="Ajouter une story"
              onClickButton={showCreateStoryPopup}
            />
          </div>
          {createStoryPopup && (
            <SidePopup
              setIsOpen={setCreateStoryPopup}
              dataLoaded={true}
              onClickOutside={() => setCancelPopup(true)}
              modal={true}
            >
              <CreateStory
                sprint_id={0}
                type="todo"
                closePopup={hideCreateStoryPopup}
                modal={true}
              />
              {cancelPopup && (
                <Popup setIsOpen={setCancelPopup} width="496px">
                  <CancelStoryPopup
                    closePopup={hideCreateStoryPopup}
                    setIsOpen={setCancelPopup}
                  />
                </Popup>
              )}
            </SidePopup>
          )}
        </>
      )}
    </>
  );
};

export default Backlog;
