import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import styles from "./Sprint.module.scss";
import { useEffect, useState } from "react";
import { StoryType } from "story/types";
import { useDispatch, useSelector } from "react-redux";
import { updateFormatCard } from "sprint/actions/sprints";
import useLocalStorage from "hooks/useLocalStorage";
import { handleGetStories, handleUpdateStory } from "story/controllers/story";
import { showModal } from "app/actions/modal";
import Backlog from "sprint/components/backlog/Backlog";
import { DragDropContext, DragUpdate, DropResult } from "react-beautiful-dnd";
import {
  ArrowLeft,
  FadersHorizontal,
  Gear,
  SlidersHorizontal,
} from "phosphor-react";
import Dropdown from "app/components/dropdown/Dropdown";
import Filter from "sprint/components/filter/Filter";
import SprintSettingsPopup from "sprint/components/sprint-settings-popup/SprintSettingsPopup";
import Popup from "ui/components/popup/Popup";
import { XCircle } from "@phosphor-icons/react";
import { errorsAPI } from "app/constants/errors";
import socket from "utils/socket";
import { RootState } from "app/redux/store";
import { useNavigate } from "react-router-dom";
import { handleGetSprints } from "sprint/controllers";
import { SprintType } from "sprint/types";
import LoadingIcon from "ui/icons/LoadingIcon";
import SprintsList from "sprint/components/sprints-list/SprintsList";

const SprintSingle = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const [typeCard] = useLocalStorage("typeCard");
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sprints, setSprints] = useState<SprintType[]>([]);
  const [createSprint, setCreateSprint] = useState(false);
  const [loadingSprint, setLoadingSprint] = useState(true);
  const [hovered, setHovered] = useState<string | null>(null);
  const [filter, setFilter] = useState<{
    type: "user" | "customer";
    value: string;
    id: number;
  } | null>(null);
  const [isSingle] = useState<boolean>(true);

  const showCreateSprint = () => {
    setCreateSprint(true);
  };

  useEffect(() => {
    dispatch(updateFormatCard(typeCard));
  }, [typeCard, dispatch]);

  useEffect(() => {
    if (filter) {
      console.log(filter);
      let filterData: { [key: string]: string | number } = {};
      if (filter.type === "customer") {
        filterData.customerId = filter.id;
      } else {
        filterData.userId = filter.id;
      }
      handleGetSprints({ forPlanif: true })
        .then((res) => {
          setSprints(res.data);
          setLoadingSprint(false);
        })
        .catch((err) => {
          setLoadingSprint(false);
          const toastData = {
            status: true,
            message: "Les sprints n'ont pas pu être récupérés",
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    } else {
      handleGetSprints({ forPlanif: true })
        .then((res) => {
          setSprints(res.data);
          setLoadingSprint(false);
        })
        .catch((err) => {
          setLoadingSprint(false);
          const toastData = {
            status: true,
            message: "Les sprints n'ont pas pu être récupérés",
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [dispatch, filter]);

  // useEffect(() => {
  //   socket.on("updateSprint", async (data) => {
  //     if (user?.team_selected === data) {
  //       handleGetStories()
  //         .then((res) => {
  //           setBacklogStories(res.data.stories);
  //         })
  //         .catch((err) => {
  //           const toastData = {
  //             status: true,
  //             message:
  //               errorsAPI[err.response.data.message as keyof typeof errorsAPI],
  //             error: true,
  //           };
  //           dispatch(showModal(toastData));
  //           console.log(err);
  //         });
  //     }
  //   });

  //   return () => {
  //     socket.off("updateSprint");
  //   };
  // }, [user?.team_selected, dispatch]);

  const onDragEnd = async (result: DropResult) => {
    setHovered(null);
    // const { destination, source, draggableId } = result;
    // if (!destination) return;
    // if (
    //   destination.droppableId === source.droppableId &&
    //   destination.index === source.index
    // )
    //   return;

    // let type = "todo";

    // if (
    //   destination.droppableId === "backlog" &&
    //   source.droppableId === "backlog"
    // ) {
    //   const copyBacklogStories = [...backlogStories];

    //   const draggedStoryIndex = copyBacklogStories.findIndex(
    //     (story) => story.id === Number(draggableId)
    //   );

    //   if (draggedStoryIndex !== -1) {
    //     const [draggedStory] = copyBacklogStories.splice(draggedStoryIndex, 1);
    //     copyBacklogStories.splice(destination.index, 0, draggedStory);

    //     setBacklogStories(copyBacklogStories);
    //   }
    // } else {
    //   return null;
    // }

    // try {
    //   await handleUpdateStory(Number(draggableId), {
    //     sprint_id:
    //       destination.droppableId === "backlog"
    //         ? 0
    //         : Number(destination.droppableId),
    //     planif_order: destination.index,
    //     type: type as "todo" | "doing" | "review" | "done",
    //     order: 0,
    //   });
    // } catch (err: any) {
    //   const toastData = {
    //     status: true,
    //     message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
    //     error: true,
    //   };
    //   dispatch(showModal(toastData));
    //   console.log(err);
    // }
  };

  const onDragUpdate = (update: DragUpdate) => {
    setHovered(update.destination?.droppableId || null);
  };

  const backButton = () => {
    navigate("/planification", { replace: true });
  };

  const handleDropdownOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const resetFilter = () => {
    setFilter(null);
    localStorage.removeItem("user");
    localStorage.removeItem("customer");
  };

  return (
    <ProfilLayout>
      <div className="layout-container">
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.top}>
              <button
                onClick={backButton}
                type="button"
                className="m-button m-button--black"
              >
                <ArrowLeft size={18} color="#fff" />
              </button>
              <h1>Backlog</h1>
            </div>
            <div className={styles.buttons}>
              <div className={styles.dropdownContainer}>
                <div className={styles.filterButton}>
                  <button
                    onClick={handleDropdownOpen}
                    className={`m-button m-button--grey ${
                      styles.dropdownButton
                    } ${filter ? styles.filterActive : ""}`}
                  >
                    <SlidersHorizontal weight="bold" />
                    {filter ? filter.value : "Filtrer"}
                  </button>
                  {filter && (
                    <button
                      className={styles.resetFilter}
                      onClick={resetFilter}
                    >
                      <XCircle weight="fill" />
                    </button>
                  )}
                </div>
                {dropdownOpen && (
                  <div className={styles.dropdown}>
                    <Dropdown setIsOpen={setDropdownOpen}>
                      {/* <Filter
                        setFilter={setFilter}
                        setIsOpen={setDropdownOpen}
                        currentSprint={backlogData}
                      /> */}
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.storiesWrapper}>
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              {loadingSprint ? (
                <div className={styles.right}>
                  <div className={styles.loader}>
                    <LoadingIcon />
                  </div>
                </div>
              ) : (
                <SprintsList
                  hovered={hovered}
                  sprints={sprints}
                  single
                  showCreateSprint={showCreateSprint}
                />
              )}
            </DragDropContext>
          </div>
        </div>
      </div>
    </ProfilLayout>
  );
};

export default SprintSingle;
