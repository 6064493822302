import { CreateReport, UpdateReport } from "clients/interfaces/report";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/useApi";

export const handleGetReport = (id: string) => {
  return getRequest(`report/${id}`).then((response) => {
    return response.data;
  });
};

export const handleCreateReport = (report: CreateReport) => {
  return postRequest("report", report).then((response) => {
    return response.data;
  });
};

export const handleUpdateReport = (id: number, report: UpdateReport) => {
  return putRequest(`report/${id}`, report).then((response) => {
    return response.data;
  });
};

export const handleGetHistoryReport = (projectId: string) => {
  return getRequest(`/report?projectId=${projectId}`).then((response) => {
    return response.data;
  });
};
export const handleDeleteHistoryReport = (id: string) => {
  return deleteRequest(`/report/${id}`).then((response) => {
    return response.data;
  });
};