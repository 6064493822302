const DeconnexionIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 4.8a1.6 1.6 0 0 0-1.6 1.6v19.2a1.6 1.6 0 1 0 3.2 0V6.4a1.6 1.6 0 0 0-1.6-1.6Zm16.469 14.869a1.6 1.6 0 0 0 2.262 2.262l4.8-4.8a1.6 1.6 0 0 0 0-2.262l-4.8-4.8a1.6 1.6 0 0 0-2.262 2.262l2.068 2.069H11.2a1.6 1.6 0 1 0 0 3.2h12.137l-2.068 2.069Z"
      />
    </svg>
  );
};

export default DeconnexionIcon;
