import { useContext } from "react";
import styles from "./MainTimer.module.scss";
import { Timer } from "@phosphor-icons/react";
import TimerContent from "../timer-content/TimerContent";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import Dropdown from "app/components/dropdown/Dropdown";
import { useLocation } from "react-router-dom";

const MainTimer = () => {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const currentPath = paths[1];

  const { timerOpen, setTimerOpen, currentTimer } =
    useContext(CurrentTimerContext);

  if (
    currentPath === "connexion" ||
    currentPath === "rapport" ||
    currentPath === ""
  )
    return null;

  return (
    <>
      {!timerOpen && !currentTimer?.id && (
        <button className={styles.timer} onClick={() => setTimerOpen(true)}>
          <span className="sr-only">Ouvir timer</span>
          <Timer weight="fill" />
        </button>
      )}
      <div
        className={`${styles.timerContainer}
        ${currentTimer?.id ? styles.timerOpenCurrent : ""}
        ${!timerOpen && currentTimer?.id ? styles.timerCloseCurrent : ""}
        ${timerOpen && !currentTimer?.id ? styles.timerOpen : ""}
        `}
      >
        <Dropdown setIsOpen={setTimerOpen} padding={false} color={false}>
          <TimerContent />
        </Dropdown>
      </div>
    </>
  );
};

export default MainTimer;
