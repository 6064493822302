import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import styles from "./HtmlTextarea.module.scss";
import { Pencil } from "@phosphor-icons/react";

type Props = {
  text?: string;
  setText?: (text: string) => void;
};

const HTMLTextArea = ({ text, setText }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(text || "");
  const ref = useRef<HTMLTextAreaElement>(null);

  const editorRef = useRef<any>(null);

  const resizeNoteTextarea = () => {
    const textArea = ref.current;
    if (!textArea) return;
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  };

  const handleNoteTextareaChange = (e: any) => {
    setNewText(e);
    setText && setText(e);
  };

  const handleClickButton = () => {
    setIsEditing(true);
    setNewText(text || "");
  };

  useEffect(() => {
    if (isEditing && ref.current) {
      ref.current.focus();
      ref.current.setSelectionRange(
        ref.current.value.length,
        ref.current.value.length
      );
    }
  }, [isEditing, ref]);

  useEffect(() => {
    resizeNoteTextarea();
  }, [newText, isEditing]);

  console.log(newText);
  return (
    <div className={styles.editorStyle}>
      {isEditing ? (
        <Editor
          apiKey="lm2kbt9ryiyzmua7844mtbzgzipgcm7o4dpzyjcvxsxmx5di"
          onBlur={() => setIsEditing(false)}
          value={newText}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onEditorChange={handleNoteTextareaChange}
          init={{
            height: 500,
            menubar: false,
            plugins: ["advlist", "autolink", "lists", "link"],
            toolbar:
              "bold italic forecolor alignleft aligncenter link bullist numlist removeformat",
            content_style:
              "body { font-family:DM Sans,sans-serif; font-size:14px }",
          }}
        />
      ) : (
        <>
          {newText !== "" && (
            <div dangerouslySetInnerHTML={{ __html: newText }} />
          )}

          <button onClick={handleClickButton} className={styles.notes}>
            <Pencil weight={"fill"} />
            Ajouter ou modifier la note
          </button>
        </>
      )}
    </div>
  );
};

export default HTMLTextArea;
