import { MagnifyingGlass, X } from "@phosphor-icons/react";
import styles from "./UserStory.module.scss";
import { StoryType } from "story/types";
import { useState, useEffect, useMemo, useCallback } from "react";
import useDebounce from "hooks/useDebounce";
import { handleGetUsers } from "users/controllers/users";
import { UserType } from "users/types";
import UserStorySingle from "./user-story-single/UserStorySingle";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  setStory: (story: StoryType) => void;
  story: StoryType;
  close: () => void;
};

const UserStory = ({ setStory, story, close }: Props) => {
  const dispatch = useDispatch();
  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const [users, setUsers] = useState<UserType[]>([]);

  const debouncedSearchValue = useDebounce(searchUserValue, 300);

  const searchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUserValue(e.target.value);
  };

  
  const handleSelectUser = useCallback((user: UserType) => {
    setStory({ ...story, teamUserLink: user.team_user_link });
    close();
  }, [close, setStory, story]);


  useEffect(() => {
    const filter = {
      search: debouncedSearchValue,
    };
    handleGetUsers(filter)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [debouncedSearchValue, dispatch]);

  const renderUsers = useMemo(() => {
    if (!users) return null;
    return users.map((user) => {
      if (!user.team_user_link) return null;
      return (
        <li key={user.id}>
          <button type="button" onClick={() => handleSelectUser(user)}>
            <UserStorySingle teamUserLink={user.team_user_link} />
          </button>
        </li>
      );
    })
  }, [users, handleSelectUser]);

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Sélectionnez le responsable</p>
        <button
          className={`m-button m-button--grey m-button--ratio ${styles.close}`}
          onClick={close}
          type="button"
        >
          <span className="sr-only">Fermer l'ajout de mission</span>
          <X weight="bold" />
        </button>
      </div>
      <div className={styles.box}>
        <div className="m-search m-search--white">
          <input
            type={"text"}
            placeholder="Rechercher un responsable"
            onChange={searchUser}
            value={searchUserValue}
          />
          <MagnifyingGlass weight="bold" />
        </div>
        <ul className={styles.users}>
          {renderUsers}
        </ul>
      </div>
    </>
  );
};

export default UserStory;
