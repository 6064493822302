import { actionTypesSprintStories } from "sprint/actions/sprints";

const initialState: any =  {
    card : 'window'
}

const TypeCardReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypesSprintStories.UPDATE_FORMAT_CARD:
        return {
            ...state,
            card: action.payload,
          };
    
    default:
      return state;
  }
};

export default TypeCardReducer;