import styles from "./PanelStory.module.scss";
import { Plus } from "@phosphor-icons/react";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import OptionsIcon from "ui/icons/OptionsIcon";

const PanelStory = () => {
  return (
    <div className={styles.wrapper}>
      <button className={styles.params}>
        <OptionsIcon />
      </button>
      <span className={styles.number}>#1001</span>
      <h3 className={styles.title}>
        Entretiens utilisateurs & tests utilisateurs
      </h3>
      <div className={styles.tags}>
        <ul>
          <li>
            <ProjectTag
              size="large"
              content="Winandco"
              contentColor="#F65555"
              project="Refonte du site web"
            />
          </li>
          <li>
            <ProjectTag size="large" content="UX" contentColor="#25E197" />
          </li>
        </ul>
        <button className={styles.plusButton}>
          <Plus weight="bold" />
        </button>
      </div>
      <div className={styles.persons}>
        <div className="m-person-tag">
          <div className="m-person-tag__img">
            <img src="/placeholder.jpg" alt="" />
          </div>
          <p>Romain</p>
        </div>
        <div className={styles.points}>13</div>
      </div>
      <p className={styles.subtitle}>Tâches</p>
      <ul className={styles.tasks}>
        <li></li>
      </ul>
      <button className={styles.addTask}>
        <Plus weight="bold" />
        Ajouter une tâche
      </button>
      <p className={styles.subtitle}>Notes</p>
      <p className={styles.notes}>
        Nous sommes en train de faire les photos avec une photographe. Cela
        prend du temps car nous avons fait des prise de vue dans la vigne en
        juillet et nous voulons faire aussi des photos dans les chais et dans le
        laboratoire en période d’activité.
      </p>
      <p className={styles.subtitle}>Fichiers</p>
    </div>
  );
};

export default PanelStory;
