import { useEffect, useState } from "react";
import styles from "./UsersDropdown.module.scss";
import { UserType } from "users/types";
import { handleGetUsers } from "users/controllers/users";
import { User } from "@phosphor-icons/react";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  onClick: (user: UserType) => void;
};

const UsersDropdown = ({ onClick }: Props) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState<UserType[]>([]);

  useEffect(() => {
    handleGetUsers()
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [dispatch]);

  const renderPicture = (user: UserType) => {
    if (user?.team_user_link?.media) {
      return (
        <img
          src={`${process.env.REACT_APP_API}${user?.team_user_link?.media.url}`}
          alt=""
        />
      );
    } else {
      return <User />;
    }
  };

  return (
    <ul>
      {users.map((user) => (
        <li>
          <button
            onClick={() => onClick(user)}
            className={styles.userDropdownItem}
          >
            <div className={styles.picture}>{renderPicture(user)}</div>
            <p>
              {user.team_user_link?.username || user.team_user_link?.user_email}
            </p>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default UsersDropdown;
