import { ArrowLineDown, User } from "@phosphor-icons/react";
import { CreateMediaInterface } from "profil/interfaces/media";
import { useMemo, useState } from "react";
import styles from "./MediaProfil.module.scss";
import { PencilSimple } from "phosphor-react";

type Props = {
  setMedia: (value: CreateMediaInterface) => void;
  oldMedia: string | null;
  media: CreateMediaInterface | null;
};

const MediaProfil = ({ setMedia, oldMedia, media }: Props) => {
  const [mediaError, setMediaError] = useState<string | null>(null);

  const uploadMedia = async (event: any) => {
    setMediaError(null);
    const file = event.target.files[0];
    if (file.size > 2000000) {
      event.target.value = null;
      setMediaError("Le fichier est trop lourd");
      return;
    }
    const file_type = file.name.split(".").pop();
    if (file_type !== "png" && file_type !== "jpg" && file_type !== "jpeg") {
      event.target.value = null;
      setMediaError("Le format du fichier n'est pas autorisé");
      return;
    }
    const type = "profil";
    const name = file.name.split(".").shift();
    const subtype = file.type.split("/").shift() + "s";
    const format = [
      {
        size: "96x96",
      },
    ];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result;
      const newMedia: CreateMediaInterface = {
        name: name,
        type: type,
        subtype: subtype,
        fileBase64: base64,
        file_type: file_type,
        format: format,
      };
      setMedia(newMedia);
    };
  };

  const renderProfilPictureForm = useMemo(() => {
    if (media) {
      return (
        <div className={styles.pictureImg}>
          <img src={media.fileBase64} alt="" />
        </div>
      );
    } else if (oldMedia) {
      return (
        <div className={styles.pictureImg}>
          <img src={`${process.env.REACT_APP_API}${oldMedia}`} alt="" />
        </div>
      );
    } else {
      return (
        <div className={styles.pictureSvg}>
          <User />
        </div>
      );
    }
  }, [oldMedia, media]);

  return (
    <div className={styles.picture}>
      {renderProfilPictureForm}
      <div className={styles.importFile}>
        <div className={styles.file}>
          <ArrowLineDown />
          <p>Charger une photo</p>
          <input type="file" onChange={uploadMedia} />
        </div>
        <div className={styles.blackPen}>
          <PencilSimple weight="fill" />
          <input type="file" onChange={uploadMedia} />
        </div>
        <p className={styles.infoFormat}>Formats autorisés : PNG et JPEG</p>
        <p className={styles.infoWeight}>Poids max : 2mo</p>
        {mediaError && <p className="m-error">{mediaError}</p>}
      </div>
    </div>
  );
};

export default MediaProfil;
