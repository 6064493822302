import { ArrowArcLeft, Trash, X } from "@phosphor-icons/react";
import styles from "./ActionsStory.module.scss";
import { useState } from "react";
import Popup from "ui/components/popup/Popup";

const ActionsStory = () => {
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);

  const changePopupOpened = () => {
    setDeletePopupOpened(!deletePopupOpened);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <button className={styles.item}>
          <ArrowArcLeft weight="fill" />
          <p>Déplacer la story dans le backlog</p>
        </button>
        <button onClick={changePopupOpened} className={styles.item}>
          <Trash weight="fill" />
          <p>Supprimer la story</p>
        </button>
      </div>
      {deletePopupOpened && (
        <Popup width="496px" setIsOpen={setDeletePopupOpened}>
          <div className="m-popup__header">
            <p>Supprimer la story</p>
            <button
              className="m-popup__header__close"
              onClick={changePopupOpened}
            >
              <X weight="bold" />
            </button>
          </div>
          <div className="m-popup__content">
            <p className="m-popup__content__text-grey">
              Confirmez-vous la suppression de la story ?<br />
              Cette action est irréversible.
            </p>
            <button className="m-button m-button--black">
              <Trash weight="fill" />
              Supprimer la story
            </button>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ActionsStory;
