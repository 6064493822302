import React, { SVGProps } from "react";

const PlanningIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path d="M24 5.333h-1.333V4A1.333 1.333 0 0 0 20 4v1.333h-8V4a1.333 1.333 0 0 0-2.667 0v1.333H8a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4v-16a4 4 0 0 0-4-4Zm1.333 9.334H6.667V9.333A1.333 1.333 0 0 1 8 8h1.333v1.333a1.333 1.333 0 1 0 2.667 0V8h8v1.333a1.333 1.333 0 0 0 2.667 0V8H24a1.333 1.333 0 0 1 1.333 1.333v5.334Z" />
    </svg>
  );
};

export default PlanningIcon;
