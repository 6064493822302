import { TimerType } from "timer/types";
import { useState } from "react";
import styles from "./StackTimers.module.scss";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import { formatSecondsToHours } from "utils/formatDate";
import SingleTimerStacked from "../single-timer/SingleTimerStacked";

type Props = {
  timers: TimerType[];
  setAllTimers: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const StackTimers = ({ timers, setAllTimers }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const totalTimer = timers.reduce((total, timer) => total + timer.duration, 0);

  return (
    <>
      <div className={styles.stack}>
        <div className={styles.container} onClick={() => setIsOpen(!isOpen)}>
          <button
            className={styles.stackCount}
            // onClick={() => setIsOpen(!isOpen)}
          >
            <span className="sr-only">Etendre les timers</span>
            {timers.length}
          </button>
          <p className={styles.name}>{timers[0]?.name}</p>
          {timers[0]?.sub_task && (
            <p className={styles.subTask}>{timers[0]?.sub_task}</p>
          )}
          <div className={styles.tags}>
            {timers[0].customer && (
              <ProjectTag
                content={timers[0].customer.name}
                contentColor={timers[0].customer.color}
                project={timers[0].project?.name}
                size="small"
              />
            )}
            {timers[0].missions?.map((mission) => (
              <ProjectTag
                key={mission.id}
                content={mission.name}
                contentColor={mission.color}
                size="small"
              />
            ))}
          </div>
          <div className={styles.rightContainer}>
            <p className={styles.duration}>
              {formatSecondsToHours(totalTimer)}
            </p>
            <div className={styles.options}></div>
          </div>
        </div>
        {isOpen && (
          <>
            {timers.map((timer) => (
              <div className={styles.singleTimer} key={timer.id}>
                <SingleTimerStacked
                  timer={timer}
                  setAllTimers={setAllTimers}
                  color="grey"
                />
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default StackTimers;
