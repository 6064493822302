import CtaIcon from "ui/components/cta-icon/CtaIcon";
import styles from "./SprintsList.module.scss";
import SprintIcon from "ui/icons/SprintIcon";
import { useMemo, useState } from "react";
import SingleSprint from "../single-sprint/SingleSprint";
import { SprintType } from "sprint/types";

type Props = {
  sprints: SprintType[];
  showCreateSprint: () => void;
  hovered: string | null;
  single?: boolean;
};

const Sprint = ({ sprints, showCreateSprint, hovered, single }: Props) => {
  const [sprintOpen, setSprintOpen] = useState<number | null>(
    sprints.find((sprint) => sprint.active === 1)?.id || null
  );

  const renderSprints = useMemo(() => {
    return sprints.map((sprint) => (
      <SingleSprint
        hovered={Number(hovered) === sprint.id}
        key={sprint.id}
        sprint={sprint}
        sprintOpen={sprintOpen === sprint.id}
        setSprintOpen={setSprintOpen}
      />
    ));
  }, [hovered, sprintOpen, sprints]);

  return (
    <>
      {sprints.length > 0 ? (
        <ul className={`${styles.list} ${single ? styles.single : ""}`}>
          {renderSprints}
        </ul>
      ) : (
        <div className={styles.right}>
          <div className="m-top-points">
            <p className="m-top-points__text">Sprint</p>
            <span className="m-points m-points--fill">0</span>
          </div>
          <div className={styles.add}>
            <CtaIcon
              icon={<SprintIcon />}
              title="Créez un nouveau sprint
        pour commencer"
              subtitle="Configurez votre prochain sprint de travail en indiquant une période et un nom pour commencer !"
              buttonLabel="Créer un sprint"
              onClickButton={showCreateSprint}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Sprint;
