const ContentCopyIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g clipPath="url(#ContentCopyIcon)">
        <path
          d="M10.6654.66666H2.66536c-.73333 0-1.33333.6-1.33333 1.33334v9.3333h1.33333V2h8.00004V.66666Zm2 2.66667H5.33203c-.73333 0-1.33333.6-1.33333 1.33333V14c0 .7333.6 1.3333 1.33333 1.3333h7.33337c.7333 0 1.3333-.6 1.3333-1.3333V4.66666c0-.73333-.6-1.33333-1.3333-1.33333Zm0 10.66667H5.33203V4.66666h7.33337V14Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="ContentCopyIcon">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContentCopyIcon;
