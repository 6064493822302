import { useRef, useEffect, useCallback } from "react";

import styles from "./Dropdown.module.scss";

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  padding?: boolean;
  color?: boolean;
  onClose?: Function;
};

const Dropdown = ({
  setIsOpen,
  children,
  padding = true,
  color = true,
  onClose,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
        if (onClose) onClose();
      }
    },
    [onClose, setIsOpen]
  );

  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  const handleTabKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Tab" && ref.current) {
        const focusableElements = ref.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );

        const lastFocusableElement = focusableElements[
          focusableElements.length - 1
        ] as HTMLElement;

        if (lastFocusableElement && lastFocusableElement === e.target) {
          setIsOpen(false);
        }
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const dropdown = ref.current;

    if (dropdown) {
      dropdown.addEventListener("keydown", handleEscapeKey);
      dropdown.addEventListener("keydown", handleTabKey);

      return () => {
        dropdown.removeEventListener("keydown", handleEscapeKey);
        dropdown.removeEventListener("keydown", handleTabKey);
      };
    }
  }, [handleEscapeKey, handleTabKey, setIsOpen]);

  return (
    <>
      <div
        className={`${styles.wrapper} ${!padding ? styles.noPadding : ""} ${
          !color ? styles.noColor : ""
        }`}
        ref={ref}
      >
        {children}
      </div>
    </>
  );
};

export default Dropdown;
