export const formatDayTime = (date: string) => {
  const dateObj = new Date(date);
  const hour = dateObj.getHours();
  const minute = dateObj.getMinutes();
  const second = dateObj.getSeconds();
  return `${hour}:${minute}:${second}`;
};

export const formatDayHours = (date: string) => {
  const dateObj = new Date(date);
  const hour = dateObj.getHours();
  const minute = dateObj.getMinutes();
  if (minute < 10) {
    return `${hour}:0${minute}`;
  }
  return `${hour}:${minute}`;
};

export const getHours = (date: string) => {
  const dateObj = new Date(date);
  const hour = dateObj.getHours();
  return hour.toString();
};

export const getMinutes = (date: string) => {
  const dateObj = new Date(date);
  const minute = dateObj.getMinutes();
  if (minute < 10) {
    return `0${minute}`;
  }
  return minute.toString();
};

export const formatDay = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

export const formatSecondsToHours = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secondsLeft).padStart(2, "0");

  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  return formattedTime;
};

export const formatDate = (date: string | Date) => {
  if (typeof date === "string") {
    const newDate = new Date(date).toLocaleString("fr-FR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return newDate;
  } else {
    const newDate = date.toLocaleString("fr-FR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return newDate;
  }
};

export const formatDateToDay = (date: string | Date) => {
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  const year = parsedDate.getFullYear();
  const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = parsedDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatFullDate = (date: string) => {
  const newDate = new Date(date).toLocaleString("fr-FR", {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const firstLetter = newDate[0].toUpperCase();
  const restOfTheString = newDate.slice(1);

  return firstLetter + restOfTheString;
};

export const formatToFullDate = (date: string | Date) => {
  // return 14/01/2002
  if (typeof date === "string") {
    const newDate = new Date(date).toLocaleString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return newDate;
  } else {
    const newDate = date.toLocaleString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return newDate;
  }
};
