import { actionTypesMedias } from "app/actions/media";

type Nullable<T> = T | null;

export type State = Readonly<{
  mediasToDelete?: Nullable<any>;
}>;

const initialState: State = {
  mediasToDelete: [],
};

const mediasState = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypesMedias.ADD_MEDIA_TO_DELETE:
      return {
        ...state,
        mediasToDelete: [...state.mediasToDelete, action.payload],
      };
    case actionTypesMedias.RESET_MEDIA:
      return {
        ...state,
        mediasToDelete: [],
      };
    default:
      return state;
  }
};

export default mediasState;
