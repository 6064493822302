export const actionTypesMedias = {
  ADD_MEDIA_TO_DELETE: "ADD_MEDIA_TO_DELETE",
  RESET_MEDIA: "RESET_MEDIA",
};
type Nullable<T> = T | null;

export const addMediaToDelete = (medias: Nullable<any>) => ({
  type: actionTypesMedias.ADD_MEDIA_TO_DELETE,
  payload: medias,
});

export const resetMedia = () => ({
  type: actionTypesMedias.RESET_MEDIA,
});
