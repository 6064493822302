import { getTokenFromCookie } from "middlewares/authorizations";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }: any) => {
  const token = getTokenFromCookie();

  if (!token) {
    return <Navigate to={"/connexion"} />;
  }
  return children;
};
