import useWindowSize from "hooks/useWindowSize";
import styles from "./StepSidePopup.module.scss";
import { AnimatePresence, motion } from "framer-motion"
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectTypeCardState } from "sprint/selector";


type Props = {
  children: React.ReactNode;
};

const StepSidePopup = ({ children }: Props) => {
  const { width } = useWindowSize();
  const card = useSelector(selectTypeCardState)
  const renderContent = useMemo(() => {
    let animation :any;
    if(!width) return null;
    if (width < 1024) {
      animation = {
        hidden: { opacity: 0, translateY: 40 },
        show: {
          opacity: 1,
          translateY: 0
        }
      }
    } else {
      if(card === 'modal') {
        animation = {
          hidden: {  translateX: -16 },
          show: {
      
            translateX: 0
          }
        }
      } else {
        animation = {
          hidden: { opacity: 0, translateX: 40 },
          show: {
            opacity: 1,
            translateX: 0
          }
        }
      }
     
    }
    const classmain = ( card === 'modal') ? styles.modal : styles.main;

    return (
      <div className={classmain}>
        <motion.div
          variants={animation}
          transition={{ type: "easeInOut" }}
          initial="hidden"
          animate="show"
          className={styles.wrapper}>
          {children}
        </motion.div></div>
    );
  }, [width, card, children]);

  return <AnimatePresence>
    {renderContent}
  </AnimatePresence>;
};

export default StepSidePopup;
