import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./InformationsPersonnelles.module.scss";
import { handleEditProfil } from "account/controllers/users";
import { destroyCookie } from "nookies";
import { onUpdateUser } from "users/actions";
import { handleAccountMe } from "account/controllers";
import { CreateMediaInterface } from "profil/interfaces/media";
import { handleCreateMedia } from "app/controllers/media";
import MediaProfil from "app/components/media-profil/MediaProfil";
import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";

const InformationsPersonnelles = () => {
  const user = useSelector((state: any) => state.user.user);
  const [media, setMedia] = useState<CreateMediaInterface | null>(null);

  const dispatch = useDispatch();

  const handleCreateMediaProfil = async (media: CreateMediaInterface) => {
    if (media) {
      return handleCreateMedia(media)
        .then((res: any) => {
          return res.data;
        })
        .catch((err: any) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let mediaData = null;
    if (media) {
      mediaData = await handleCreateMediaProfil(media);
    }
    const profil: any = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      username: e.target.username.value,
      job: e.target.job.value,
      email: e.target.email.value,
    };

    if (mediaData) {
      profil.media_id = mediaData.id;
    }

    handleEditProfil(profil)
      .then((res) => {
        handleAccountMe()
          .then(function (response: any) {
            dispatch(onUpdateUser(response.data.data));
            const toastData = {
              status: true,
              message: "Le profil a bien été modifié",
              error: false,
            };
            dispatch(showModal(toastData));
          })
          .catch((err: any) => {
            destroyCookie(null, "token");
            const errorMessage = err.response.data.message;
            const toastData = {
              status: true,
              message: errorsAPI[errorMessage as keyof typeof errorsAPI],
              error: true,
            };
            dispatch(showModal(toastData));
          });
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  };

  return (
    <ProfilLayout>
      <h2 className={styles.subtitle}>Informations personnelles</h2>
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit} className={styles.profilForm}>
          <MediaProfil
            setMedia={setMedia}
            oldMedia={user?.team_user_link?.media?.url}
            media={media}
          />
          <div className={styles.inputFlex}>
            <div>
              <label htmlFor="firstname" className="m-label">
                Prénom<span>*</span>
              </label>
              <input
                type="text"
                className="m-input m-input--grey"
                id="firstname"
                required
                defaultValue={user?.firstname}
              />
            </div>
            <div>
              <label htmlFor="lastname" className="m-label">
                Nom<span>*</span>
              </label>
              <input
                type="text"
                className="m-input m-input--grey"
                id="lastname"
                defaultValue={user?.lastname}
                required
              />
            </div>
          </div>
          <div className={styles.input}>
            <label htmlFor="username" className="m-label">
              Nom d’affichage
            </label>
            <input
              type="text"
              className="m-input m-input--grey"
              id="username"
              defaultValue={user?.team_user_link?.username}
            />
          </div>
          <div className={styles.input}>
            <label htmlFor="job" className="m-label">
              Métier
            </label>
            <input
              type="text"
              className="m-input m-input--grey"
              id="job"
              defaultValue={user?.team_user_link?.job}
            />
          </div>
          <div className={styles.input}>
            <label htmlFor="email" className="m-label">
              Adresse email<span>*</span>
            </label>
            <input
              type="text"
              className="m-input m-input--grey"
              id="email"
              defaultValue={user?.email}
              required
            />
          </div>
          <div className={styles.btnSave}>
            <button className="m-button m-button--black">Enregister</button>
          </div>
        </form>
      </div>
    </ProfilLayout>
  );
};

export default InformationsPersonnelles;
