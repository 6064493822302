import { Link } from "react-router-dom";
import styles from "./CtaIcon.module.scss";

type Props = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  buttonLabel?: string;
  onClickButton?: () => void;
  link?: string;
};

const CtaIcon = ({
  icon,
  title,
  subtitle,
  buttonLabel,
  onClickButton,
  link,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>{icon}</div>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
      {onClickButton && (
        <button onClick={onClickButton} className="m-button m-button--black">
          {buttonLabel}
        </button>
      )}
      {link && (
        <Link to={link} className="m-button m-button--black">
          {buttonLabel}
        </Link>
      )}
    </div>
  );
};

export default CtaIcon;
