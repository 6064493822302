/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "./AllClients.module.scss";
import CtaIcon from "ui/components/cta-icon/CtaIcon";
import ClientIcon from "ui/icons/ClientIcon";
import AddClient from "clients/components/add-client/AddClient";
import { useState, useEffect } from "react";
import SidePopup from "ui/components/side-popup/SidePopup";
import {
  Archive,
  CaretDown,
  CircleWavyCheck,
  MagnifyingGlass,
  Plus,
  SlidersHorizontal,
} from "@phosphor-icons/react";
import ProjectCard from "app/components/project-card/ProjectCard";
import { Link } from "react-router-dom";
import { handleGetAllCustomers } from "clients/controllers/customers";
import { CustomersWithNbProjects } from "clients/interfaces/customer";
import useDebounce from "hooks/useDebounce";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { useSelector } from "react-redux";
import LoadingIcon from "ui/icons/LoadingIcon";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { errorsAPI } from "app/constants/errors";
import Dropdown from "app/components/dropdown/Dropdown";
import useWindowSize from "hooks/useWindowSize";

const AllClients = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const user = useSelector((state: any) => state.user.user);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [customers, setCustomers] = useState<CustomersWithNbProjects[]>([]);
  const [creatingClient, setCreatingClient] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [projectsVisibility, setProjectsVisibility] = useState("actives");

  const [loading, setLoading] = useState(true);

  const debouncedSearchValue = useDebounce(searchValue, 300);

  const openDropdown = () => {
    setDropdown(true);
  };

  const handleDropdownOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const changeProjectsVisibility = (newActive: string) => {
    setProjectsVisibility(newActive);
    setDropdown(false);
  };

  const searchClient = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleShowCreateClient = () => {
    setShowCreateClient(true);
  };

  useEffect(() => {
    handleGetAllCustomers()
      .then((res) => {
        console.log(res);
        setCustomers(res.data);
        setShowCreateClient(false);
        if (creatingClient) {
          setCreatingClient(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [creatingClient, dispatch]);

  useEffect(() => {
    handleGetAllCustomers()
      .then((res) => {
        console.log(res);

        let sortedCustomers = res.data;

        setCustomers(sortedCustomers);
        setShowCreateClient(false);
        if (creatingClient) {
          setCreatingClient(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [creatingClient, dispatch]);

  useEffect(() => {
    setLoading(true);
    if (debouncedSearchValue) {
      const filter = {
        search: debouncedSearchValue,
      };
      handleGetAllCustomers(filter)
        .then((res) => {
          setLoading(false);
          setCustomers(res.data);
        })
        .catch((err) => {
          setLoading(false);
          const toastData = {
            status: true,
            message: "Les clients n'ont pas pu être récupérés",
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    } else {
      handleGetAllCustomers()
        .then((res) => {
          setLoading(false);
          setCustomers(res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  }, [debouncedSearchValue, dispatch]);

  function oderClients(name: string) {
    let sortCustomers = [...customers];
    let custumorsFiltered: CustomersWithNbProjects[] = [];
    if (name === `names`) {
      custumorsFiltered = sortCustomers.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );
    } else if (name === `projects`) {
      custumorsFiltered = sortCustomers.sort(
        (a: any, b: any) => b.nbProjects - a.nbProjects
      );
    }
    setCustomers(sortCustomers);
  }

  return (
    <ProfilLayout>
      <div className="layout-container">
        <div className={styles.wrapper}>
          <div className="container">
            <div className={styles.header}>
              <div className={styles.leftHeader}>
                <h1 className={styles.title}>Clients et projets</h1>
                {width && width < 1023 ? (
                  <div className="m-search m-search--white">
                    <input
                      type={"text"}
                      placeholder={
                        width && width > 768
                          ? "Rechercher un client"
                          : "Rechercher"
                      }
                      onChange={searchClient}
                    />
                    <MagnifyingGlass weight="bold" />
                  </div>
                ) : null}
              </div>
              <div className={styles.buttonsRight}>
                {width && width > 1023 ? (
                  <>
                    <div className="m-search m-search--white">
                      <input
                        type={"text"}
                        placeholder={
                          width && width > 768
                            ? "Rechercher un client"
                            : "Rechercher"
                        }
                        onChange={searchClient}
                      />
                      <MagnifyingGlass weight="bold" />
                    </div>
                    <div className={styles.dropdownContainer}>
                      <div className={styles.filterButton}>
                        <button
                          onClick={handleDropdownOpen}
                          className={`m-button m-button--grey ${styles.dropdownButton} `}
                        >
                          <SlidersHorizontal weight="bold" />
                        </button>
                      </div>
                      {dropdownOpen && (
                        <div className={styles.dropdown}>
                          <Dropdown setIsOpen={setDropdownOpen}>
                            <ul>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => oderClients("names")}
                                  className={styles.dropdownItem}
                                >
                                  Trier par nom
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => oderClients("projects")}
                                  className={styles.dropdownItem}
                                >
                                  Trier par projets
                                </button>
                              </li>
                            </ul>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}

                <div className={styles.dropdownContainer}>
                  <button
                    data-cy="open-dropdown-projects-visibility"
                    onClick={openDropdown}
                    className="m-button m-button--white"
                  >
                    {projectsVisibility === "actives"
                      ? "Clients actifs"
                      : "Clients archivés"}
                    <CaretDown />
                  </button>
                  {dropdown && (
                    <div className={styles.dropdown}>
                      <Dropdown setIsOpen={setDropdown}>
                        <ul className={styles.dropdownList}>
                          <li>
                            <button
                              onClick={() =>
                                changeProjectsVisibility("actives")
                              }
                              className={styles.dropdownItem}
                            >
                              <CircleWavyCheck weight="fill" />
                              Clients actifs
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() =>
                                changeProjectsVisibility("inactives")
                              }
                              className={styles.dropdownItem}
                              data-cy="dropdown-projects-visibility-inactives"
                            >
                              <Archive weight="fill" />
                              Clients archivés
                            </button>
                          </li>
                        </ul>
                      </Dropdown>
                    </div>
                  )}
                </div>
                {user?.team_user_link?.role_name === "SUPER_ADMIN" && (
                  <button
                    data-cy="create-client"
                    onClick={handleShowCreateClient}
                    className="m-button m-button--black"
                  >
                    <Plus />
                    {width && width > 768 ? "Ajouter un client" : "Ajouter"}
                  </button>
                )}
              </div>
              {showCreateClient && (
                <SidePopup setIsOpen={setShowCreateClient} dataLoaded={true}>
                  <AddClient
                    setCreatingClient={setCreatingClient}
                    setShowClient={setShowCreateClient}
                  />
                </SidePopup>
              )}
            </div>
            {loading ? (
              <div className={styles.loading}>
                <LoadingIcon />
              </div>
            ) : (
              <>
                {customers.length > 0 ? (
                  <div className={styles.content}>
                    <ul className={styles.list}>
                      {customers.map((customer: CustomersWithNbProjects) => (
                        <li key={customer.id}>
                          <Link to={`/clients/${customer.id}`}>
                            <ProjectCard
                              color={customer.color}
                              title={customer.name}
                              projectsNb={customer.nbProjects}
                            />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className={styles.voidContent}>
                    <CtaIcon
                      icon={<ClientIcon />}
                      title="Créez vos premiers clients, projets
                  et missions pour commencer"
                      subtitle="Gérez ici tous vos clients et les projets et missions associés à chacuns !"
                      buttonLabel="Créer mon premier client"
                      onClickButton={handleShowCreateClient}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </ProfilLayout>
  );
};

export default AllClients;
