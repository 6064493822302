import { MagnifyingGlass, X } from "@phosphor-icons/react";
import styles from "./AllCustomerStory.module.scss";
import { Customer } from "clients/interfaces/customer";
import { useState, useEffect } from "react";
import useDebounce from "hooks/useDebounce";
import { handleGetAllCustomers } from "clients/controllers/customers";
import LoadingIcon from "ui/icons/LoadingIcon";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  close: () => void;
  setSelectedCustomer: (customer: Customer) => void;
};

const AllCustomerStory = ({ close, setSelectedCustomer }: Props) => {
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [searchCustomerValue, setSearchCustomerValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSearchCustomerValue = useDebounce(searchCustomerValue, 300);

  const searchClient = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCustomerValue(e.target.value);
  };

  const handleSelectCustomer = (customer: Customer) => {
    setSelectedCustomer(customer);
  };

  useEffect(() => {
    setLoading(true);
    if (debouncedSearchCustomerValue) {
      const filter = {
        search: debouncedSearchCustomerValue,
      };
      handleGetAllCustomers(filter)
        .then((res) => {
          setCustomers(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          setLoading(false);
        });
    } else {
      handleGetAllCustomers()
        .then((res) => {
          setCustomers(res.data);
          setLoading(false);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
          setLoading(false);
        });
    }
  }, [debouncedSearchCustomerValue, dispatch]);

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Clients</p>
        <button
          className={`m-button m-button--grey m-button--ratio ${styles.close}`}
          onClick={close}
          type="button"
        >
          <span className="sr-only">Fermer l'ajout de client</span>
          <X weight="bold" />
        </button>
      </div>
      <div className={styles.box}>


        <div className="m-search m-search--white">
          <input
            type={"text"}
            placeholder="Rechercher un client"
            onChange={searchClient}
            value={searchCustomerValue}
          />
          <MagnifyingGlass weight="bold" />
        </div>
        {loading ? (
          <div className={styles.loading}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {customers.length > 0 ? (
              <ul className={styles.customers}>
                {customers.map((customer: Customer) => (
                  <li key={customer.id}>
                    <button
                      type="button"
                      onClick={() => handleSelectCustomer(customer)}
                      style={{ backgroundColor: customer.color }}
                      key={customer.id}
                    >
                      <p>{customer.name}</p>
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className={styles.noCustomer}>Aucun client</p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AllCustomerStory;
