import styles from "./MissionCard.module.scss";
import { ArrowRight, PencilSimple, Infinity } from "@phosphor-icons/react";
import ProgressBar from "ui/components/progress-bar/ProgressBar";
import { useState } from "react";
import Popup from "ui/components/popup/Popup";
import { Mission } from "clients/interfaces/mission";
import AddMissionProjectPopup from "../add-mission-project-popup/AddMissionProjectPopup";
import { useSelector } from "react-redux";
import { formatDate } from "utils/formatDate";
import { secondsToHours } from "utils/formatTime";
import { RootState } from "app/redux/store";
import useWindowSize from "hooks/useWindowSize";

type Props = {
  mission: Mission;
  editMissions?: boolean;
  setEditMissions?: (value: boolean) => void;
  setModifyingProject?: React.Dispatch<React.SetStateAction<boolean>>;
};

const MissionCard = ({
  mission,
  setEditMissions,
  setModifyingProject,
}: Props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const [modifiedPopupOpen, setModifiedPopupOpen] = useState(false);
  const { width } = useWindowSize();

  const handlePopupOpen = () => {
    setModifiedPopupOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <div
        style={{ backgroundColor: mission?.color }}
        data-testid="color-container"
        className={styles.header}
      >
        <p className={styles.title}>{mission?.name}</p>
        {setEditMissions && setModifyingProject && (
          <>
            {user?.team_user_link?.role_name === "SUPER_ADMIN" && (
              <button className={styles.modifButton} onClick={handlePopupOpen}>
                <PencilSimple weight="fill" />
                <span className="sr-only">Modifier la mission</span>
              </button>
            )}
            {modifiedPopupOpen && mission && (
              <Popup setIsOpen={setModifiedPopupOpen} width="496px">
                <AddMissionProjectPopup
                  setPopupOpen={setModifiedPopupOpen}
                  mission={mission}
                  edit
                  data={mission.missionProjectLink}
                  setEditMissions={setEditMissions}
                  setModifyingProject={setModifyingProject}
                />
              </Popup>
            )}
          </>
        )}
      </div>
      <div className={styles.content}>
        {mission.missionProjectLink &&
          typeof mission.missionProjectLink.time === "number" && (
            <>
              <div className={styles.progress}>
                <ProgressBar
                  color={mission?.color}
                  completedPercent={
                    (mission.missionProjectLink.time &&
                      (mission.missionProjectLink.time * 100) /
                        (mission.missionProjectLink.hours * 3600)) ||
                    0
                  }
                />
              </div>
              <div className={styles.hours}>
                <p>
                  {secondsToHours(
                    (mission.missionProjectLink.time &&
                      mission.missionProjectLink.time) ||
                      0
                  )}{" "}
                  consommées
                  {mission.missionProjectLink.hours !== 0 &&
                  mission.missionProjectLink.hours * 3600 -
                    mission.missionProjectLink.time <
                    0 ? (
                    <span className={styles.red}>
                      +{" "}
                      {secondsToHours(
                        Math.abs(
                          mission.missionProjectLink.hours * 3600 -
                            mission.missionProjectLink.time
                        )
                      )}
                    </span>
                  ) : null}
                </p>
                {width && width > 768 ? (
                  mission.missionProjectLink.hours !== 0 ? (
                    <p>sur {mission.missionProjectLink.hours}h00</p>
                  ) : (
                    <Infinity size={20} color="#030507" weight="bold" />
                  )
                ) : null}
              </div>
              {mission.missionProjectLink.start_date &&
                mission.missionProjectLink.end_date && (
                  <div className={styles.date}>
                    <p>{formatDate(mission.missionProjectLink.start_date)}</p>
                    <ArrowRight />
                    <p>{formatDate(mission.missionProjectLink.end_date)}</p>
                  </div>
                )}
            </>
          )}
      </div>
    </div>
  );
};

export default MissionCard;
