import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/useApi";
import { CreateMissionProjectLink } from "clients/interfaces/missionProjectLink";

export const handleGetAllMissionProjectLink = (filters?: any) => {
  return getRequest("missions-projects-link", filters).then((response) => {
    return response.data;
  });
};

export const handleCreateMissionProjectLink = (
  missionProjectLink: CreateMissionProjectLink
) => {
  return postRequest("missions-projects-link", missionProjectLink).then(
    (response) => {
      return response.data;
    }
  );
};

export const handleUpdateMissionProjectLink = (
  id: number,
  missionProjectLink: CreateMissionProjectLink
) => {
  return putRequest(`missions-projects-link/${id}`, missionProjectLink).then(
    (response) => {
      return response.data;
    }
  );
};

export const handleDeleteMissionProjectLink = (id: number) => {
  return deleteRequest(`missions-projects-link/${id}`).then((response) => {
    return response.data;
  });
};
