import Switch from "ui/components/switch/Switch";
import CardTime from "app/components/card-time/CardTime";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import ProjectCard from "app/components/project-card/ProjectCard";
import Menu from "ui/components/menu/Menu";
import CtaIcon from "ui/components/cta-icon/CtaIcon";

import { useForm, FormProvider } from "react-hook-form";
import styles from "./Components.module.scss";
import { Check, MagnifyingGlass, Timer } from "@phosphor-icons/react";
import PanelStory from "story/components/panel-story/PanelStory";
import ActionsStory from "story/components/actions-story/ActionsStory";
import EndSprint from "sprint/components/end-sprint/EndSprint";
// import MissionCard from "clients/components/mission-card/MissionCard";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import CreateClient from "clients/components/add-client/AddClient";
import { useState } from "react";

const Components = () => {
  const [checked, setChecked] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const methods = useForm();
  const { register } = methods;

  return (
    <>
      <div className="layout-container">
        <FormProvider {...methods}>
          <Menu />
          <div className="container">
            <Switch
              id="qs"
              name="dqds"
              checked={checked}
              setChecked={setChecked}
            />
            <div className={styles.input}>
              <label htmlFor="" className="m-label">
                lkdqsjk <span>*</span>
              </label>
              <input
                className="m-input m-input--dark"
                placeholder="lkfqdjlkqd"
              />
            </div>
            <div className={styles.input}>
              <label htmlFor="" className="m-label">
                lkdqsjk <span>*</span>
              </label>
              <input
                className="m-input m-input--grey"
                placeholder="lkfqdjlkqd"
              />
            </div>
            <div className={styles.input}>
              <label htmlFor="" className="m-label">
                lkdqsjk <span>*</span>
              </label>
              <input
                className="m-input m-input--grey"
                disabled={true}
                placeholder="lkfqdjlkqd"
              />
            </div>
            <div className={styles.input}>
              <div className="m-checkbox">
                <input
                  {...register("caracteristics.restaurant_snack")}
                  type={"checkbox"}
                />
                <div className="checkmark checkmark--black">
                  <Check weight="bold" />
                </div>
              </div>
            </div>
            <div className={styles.input}>
              <div className="m-checkbox">
                <input
                  {...register("caracteristics.restaurant_snack")}
                  type={"checkbox"}
                />
                <div className="checkmark checkmark--white">
                  <Check weight="bold" />
                </div>
              </div>
            </div>
            <div className={styles.input}>
              <div className="m-radio">
                <input
                  {...register("caracteristics.restaurant_snack")}
                  type={"radio"}
                />
                <div className="checkmark">
                  <Check weight="bold" />
                </div>
              </div>
            </div>
            <div className={styles.input}>
              <div className="m-search">
                <input type={"text"} placeholder="rechercher" />
                <MagnifyingGlass weight="bold" />
              </div>
            </div>
            <div className={styles.input}>
              <CardTime
                project={{
                  id: 1,
                  name: "dqsdqsd",
                  description: "dqsdqsd",
                  customer_id: 1,
                }}
              />
            </div>
            <div className={styles.input}>
              {/* <ProjectCard color="#5568F6" title="ETIC" projectsNb={3} /> */}
            </div>
            <div className={styles.input}>
              <ProjectTag
                content="Winandco"
                contentColor="#F65555"
                project="Refonte du site web"
              />
            </div>
            <div className={styles.input}>
              <ProjectTag content="UX" contentColor="#25E197" />
            </div>
            {/* <div className={styles.input}>
              <CardStory />
            </div> */}
            <div className={styles.input}>
              <button className="m-button m-button--black">dqsddqs</button>
            </div>
            <div className={styles.input}>
              <button className="m-button m-button--grey">dqsddqs</button>
            </div>
            <div className={styles.input}>
              <button className="m-button m-button--border">dqsddqs</button>
            </div>
            <div className={styles.input}>
              <CtaIcon
                icon={<Timer weight="fill" />}
                title="Ajoutez vos premières stories
                dans votre backlog"
                subtitle="Configurez vos stories en définissant la complexité, les responsables et les tâches à effectuer"
                buttonLabel="Ajouter une story"
              />
            </div>
            <div className={styles.input}>
              <PanelStory />
            </div>
            <div className={styles.input}>
              <ActionsStory />
            </div>
            <div className={styles.input}>{/* <CalendarSingle /> */}</div>
            <div className={styles.input}>
              <CalendarDouble
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </div>
            <div className={styles.input}>
              <EndSprint points={152} totalPoints={200} stories={62} />
            </div>
            <div className={styles.input}>
              {/* <MissionCard
                missionProjectLink={{
                  id: 1,
                  project_id: 1,
                  mission_id: 1,
                  start_date: "2022-01-01T00:00:00.000Z",
                  end_date: "2022-01-01T00:00:00.000Z",
                  hours: 430504,
                }}
                editMissions={true}
                setEditMissions={setChecked}
                setModifyingProject={setChecked}
              /> */}
            </div>
            <div className={styles.input}>{/* <History /> */}</div>
            <div className={styles.input}>
              <CreateClient />
            </div>
          </div>
        </FormProvider>
      </div>
    </>
  );
};

export default Components;
