import { TimerType } from "timer/types";
import styles from "./SingleTimerStacked.module.scss";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import DateTimer from "../date-timer/DateTimer";
import { formatSecondsToHours } from "utils/formatDate";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { Play, Plus, PlusCircle, Trash, X } from "@phosphor-icons/react";
import { useContext, useState } from "react";
import Popup from "ui/components/popup/Popup";
import Dropdown from "app/components/dropdown/Dropdown";
import { handleDeleteTimer, handleUpdateTimer } from "timer/controllers/timer";
import { EditingTimerContext } from "timer/context/EditingTimerProvider";
import TextInputTrunc from "app/components/text-input-trunc/TextInputTrunc";
import SelectMissions from "../select-missions/SelectMissions";
import SelectCustomer from "../select-customer/SelectCustomer";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import OptionsIcon from "ui/icons/OptionsIcon";

type Props = {
  timer: TimerType;
  color?: "grey" | "white";
  setAllTimers?: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const SingleTimerStacked = ({
  timer,
  color = "white",
  setAllTimers,
}: Props) => {
  const dispatch = useDispatch();
  const { setEditingTimer } = useContext(EditingTimerContext);
  const { currentTimer, setCurrentTimer, triggerTimer, setTimerOpen } =
    useContext(CurrentTimerContext);
  const [openOption, setOpenOption] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [addCustomerPopup, setAddCustomerPopup] = useState(false);
  const [addMissionPopup, setAddMissionPopup] = useState(false);
  const [showSubtaskPlaceholder, setShowSubtaskPlaceholder] = useState(false);
  const [timerCustomer, setTimerCustomer] = useState(timer.customer);
  const [timerProject, setTimerProject] = useState(timer.project);
  const [timerMissions, setTimerMissions] = useState(timer.missions);

  const duration = formatSecondsToHours(timer.duration);
  console.log(timer);
  const openAddCustomerPopup = () => {
    setAddCustomerPopup(true);
  };

  const openAddMissionPopup = () => {
    setAddMissionPopup(true);
  };

  const deleteTimer = async () => {
    try {
      await handleDeleteTimer(timer.id);
      setDeletePopupOpen(false);
      setEditingTimer(true);
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const launchTimer = async () => {
    if (currentTimer?.end_at === null && currentTimer?.id) {
      setTimerOpen(true);
      const modalData = {
        status: true,
        message: `Vous avez déjà un timer en cours`,
        error: true,
      };
      dispatch(showModal(modalData));
    } else {
      const currentTimerData = {
        customer: timer.customer,
        customer_id: timer.customer?.id,
        project_id: timer.project?.id,
        project: timer.project,
        missions: timer.missions,
        mission_ids: timer.missions?.map((mission) => mission.id) || [],
        name: timer.name,
        start_at: "",
        story_id: timer.id,
      };

      setCurrentTimer(currentTimerData);
      await triggerTimer(currentTimerData);
    }
  };

  const handleBlurName = async (e: React.ChangeEvent<HTMLSpanElement>) => {
    const name = e.currentTarget.textContent;
    if (name === timer.name || typeof name !== "string") return;
    console.log(name);
    try {
      await handleUpdateTimer(timer.id, { name: name });
      if (setAllTimers) {
        setAllTimers((allTimers) =>
          allTimers.map((t) => {
            return t.id === timer.id ? { ...t, name: name } : t;
          })
        );
      }
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const handleBlurSubtask = async (e: React.ChangeEvent<HTMLSpanElement>) => {
    const sub_task = e.currentTarget.textContent;
    if (sub_task === timer.sub_task || typeof sub_task !== "string") return;
    try {
      await handleUpdateTimer(timer.id, {
        sub_task: sub_task,
      });
      if (setAllTimers) {
        setAllTimers((allTimers) =>
          allTimers.map((t) =>
            t.id === timer.id ? { ...t, sub_task: sub_task } : t
          )
        );
      }
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  return (
    <>
      <div
        className={`${styles.container} ${
          color === "grey" ? styles.greyContainer : ""
        }`}
      >
        <div className={styles.nameContainer}>
          <TextInputTrunc
            color="#000"
            value={timer.name}
            fontSize={14}
            placeholder="Tapez le nom de la story"
            onBlur={handleBlurName}
          />
          {(timer?.sub_task || showSubtaskPlaceholder) && (
            <div className={styles.subtaskContainer} data-text={timer.sub_task}>
              <TextInputTrunc
                color="#8C8FA6"
                value={timer.sub_task}
                fontSize={13}
                placeholder="Tapez le nom de la sous-tâche"
                onBlur={handleBlurSubtask}
              />
            </div>
          )}
        </div>
        <div className={styles.tags}>
          <div className={styles.addMissionContainer}>
            <button
              className={styles.addMissionButton}
              onClick={openAddCustomerPopup}
            >
              {timerCustomer?.id ? (
                <ProjectTag
                  content={timerCustomer.name}
                  contentColor={timerCustomer.color}
                  project={timerProject?.name}
                  size="small"
                />
              ) : (
                <span className={styles.addCustomer}>
                  <Plus weight="bold" />
                  <p>Ajouter un client/projet</p>
                </span>
              )}
            </button>
            {addCustomerPopup && (
              <div className={styles.addMissionDropdown}>
                <Dropdown setIsOpen={setAddCustomerPopup}>
                  <SelectCustomer
                    timer={timer}
                    timerCustomer={timerCustomer}
                    setTimerCustomer={setTimerCustomer}
                    timerProject={timerProject}
                    setTimerProject={setTimerProject}
                    setAllTimers={setAllTimers}
                  />
                </Dropdown>
              </div>
            )}
          </div>
          <div className={styles.addMissionContainer}>
            <button
              className={styles.addMissionButton}
              onClick={openAddMissionPopup}
            >
              {timerMissions && timerMissions.length > 0 ? (
                timerMissions.map((mission) => (
                  <ProjectTag
                    key={mission.id}
                    content={mission.name}
                    contentColor={mission.color}
                    size="small"
                  />
                ))
              ) : (
                <span className={styles.addMission}>
                  <Plus weight="bold" />
                  <p>Ajouter une mission</p>
                </span>
              )}
            </button>
            {addMissionPopup && (
              <div className={styles.addMissionDropdown}>
                <Dropdown setIsOpen={setAddMissionPopup}>
                  <SelectMissions
                    timer={timer}
                    timerMissions={timerMissions}
                    setTimerMissions={setTimerMissions}
                    setAllTimers={setAllTimers}
                  />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.time}>
            <div
              className={`${styles.dateTimer} ${
                color === "grey" ? styles.dateTimerGrey : ""
              }`}
            >
              <DateTimer timer={timer} setAllTimers={setAllTimers} />
            </div>
            <p
              className={`${styles.duration} ${
                color === "grey" ? styles.durationGrey : ""
              }`}
            >
              {duration}
            </p>
          </div>

          <div className={styles.options}>
            <div className={styles.options__align}>
              <button className={styles.play} onClick={launchTimer}>
                <span className="sr-only">Lancer le timer</span>
                <Play weight="fill" />
              </button>
              <button
                onClick={() => setOpenOption(true)}
                className={styles.optionsButton}
              >
                <span className="sr-only">Ouvrir les options</span>
                <OptionsIcon />
              </button>
              {openOption && (
                <div className={styles.optionsDropdown}>
                  <Dropdown setIsOpen={setOpenOption}>
                    {!timer.sub_task && (
                      <button
                        className={styles.actionItem}
                        onClick={() => setShowSubtaskPlaceholder(true)}
                      >
                        <PlusCircle weight="fill" />
                        Ajouter une sous-tâche
                      </button>
                    )}
                    <button
                      className={styles.actionItem}
                      onClick={() => setDeletePopupOpen(true)}
                    >
                      <Trash weight="fill" />
                      Supprimer l’entrée
                    </button>
                  </Dropdown>
                </div>
              )}
            </div>

            {deletePopupOpen && (
              <Popup setIsOpen={setDeletePopupOpen} width="496px">
                <div className="m-popup__header">
                  <p>Supprimer la mission</p>
                  <button
                    className="m-popup__header__close"
                    onClick={() => setDeletePopupOpen(false)}
                  >
                    <X weight="bold" />
                  </button>
                </div>
                <div className="m-popup__content">
                  <p className="m-popup__content__text-grey">
                    Confirmez-vous la suppression de la mission de ce projet ?
                    Cette action est irréversible.
                  </p>
                  <button
                    onClick={deleteTimer}
                    className="m-button m-button--black"
                  >
                    <Trash weight="fill" />
                    Supprimer l'entrée
                  </button>
                </div>
              </Popup>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTimerStacked;
