import { useState } from "react";
import styles from "./FinishSprint.module.scss";
import Popup from "ui/components/popup/Popup";
import { X } from "@phosphor-icons/react";
import { SprintType } from "sprint/types";
import FinishSprintModal from "./finish-sprint-modal/FinishSprintModal";

type Props = {
  sprint: SprintType;
};

const FinishSprint = ({ sprint }: Props) => {
  const [finishPopup, setFinishPopup] = useState(false);
  const [endingSprintPopup, setEndingSprintPopup] = useState(false);

  return (
    <>
      <div className={styles.btnFinish}>
        <button
          className="m-button m-button--black"
          onClick={() => setFinishPopup(true)}
        >
          Terminer le sprint {sprint.number}
        </button>
      </div>
      {finishPopup && (
        <Popup setIsOpen={setFinishPopup} width="496px">
          <div className="m-popup__header">
            <p>Terminer le sprint</p>
            <button
              className="m-popup__header__close"
              onClick={() => setFinishPopup(false)}
            >
              <X weight="bold" />
            </button>
          </div>
          <div className="m-popup__content">
            <p className="m-popup__content__text-grey">
              Souhaitez-vous vraiment terminer le{" "}
              <b>sprint n°{sprint.number}</b> ?
            </p>
            <div className={styles.buttons}>
              <button
                className="m-button m-button--black"
                onClick={() => setEndingSprintPopup(true)}
              >
                Terminer le sprint
              </button>
            </div>
          </div>
        </Popup>
      )}
      {endingSprintPopup && (
        <Popup setIsOpen={setEndingSprintPopup} width="496px">
          <FinishSprintModal setIsOpen={setEndingSprintPopup} sprint={sprint} />
        </Popup>
      )}
    </>
  );
};

export default FinishSprint;
