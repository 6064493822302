import { CreateSprintType, UpdateSprintType } from "sprint/types";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/useApi";

export const handleGetSprints = async (formData?: any) => {
  return getRequest("sprint", formData).then((response) => {
    return response.data;
  });
};

export const handleCreateSprint = async (sprint: CreateSprintType) => {
  return postRequest("sprint", sprint).then((response) => {
    return response.data;
  });
};

export const handleDeleteSprint = async (id: number) => {
  return deleteRequest(`sprint/${id}`).then((response) => {
    return response.data;
  });
};

export const handleUpdateSprint = async (
  id: number,
  sprint: Partial<UpdateSprintType>
) => {
  return putRequest(`sprint/${id}`, sprint).then((response) => {
    return response.data;
  });
};

export const handleGetCurrentSprint = async (query?: object) => {
  return getRequest(`sprint/current`, query).then((response) => {
    return response.data;
  });
};

export const handleGetSprintState = async () => {
  return getRequest(`sprint/check`).then((response) => {
    return response.data;
  });
};
