import styles from "./TimerFilters.module.scss";
import PlanningIcon from "ui/icons/PlanningIcon";
import { useState, useMemo } from "react";
import Dropdown from "app/components/dropdown/Dropdown";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import { formatDate } from "utils/formatDate";
import { ArrowRight, X } from "@phosphor-icons/react";
import useWindowSize from "hooks/useWindowSize";

type Props = {
  startDateFilter: Date | null;
  setStartDateFilter: (value: Date | null) => void;
  endDateFilter: Date | null;
  setEndDateFilter: (value: Date | null) => void;
};

const TimerFilters = ({
  startDateFilter,
  setStartDateFilter,
  endDateFilter,
  setEndDateFilter,
}: Props) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const { width } = useWindowSize();

  const renderDates = useMemo(() => {
    if (startDateFilter && endDateFilter) {
      const startDate = formatDate(startDateFilter);
      const endDate = formatDate(endDateFilter);
      return (
        <>
          <p className={styles.startDate}>{startDate || "Choisir une date"}</p>
          <ArrowRight className={styles.arrow} />
          <p className={styles.endDate}>{endDate || "Choisir une date"}</p>
        </>
      );
    } else if (startDateFilter && !endDateFilter) {
      const startDate = formatDate(startDateFilter);
      return (
        <>
          <p className={styles.startDate}>{startDate || "Choisir une date"}</p>
        </>
      );
    } else {
      return <p className={styles.noDates}>Depuis le début</p>;
    }
  }, [endDateFilter, startDateFilter]);

  const resetFilters = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button
          className={startDateFilter ? styles.button : styles.noDate}
          onClick={() => setCalendarOpen(true)}
        >
          <PlanningIcon className={styles.planningIcon} />
          {renderDates}
        </button>
        {width && width > 768 ? (
          <>
            {startDateFilter && (
              <button className={styles.resetFilters} onClick={resetFilters}>
                <X weight="bold" />
              </button>
            )}
          </>
        ) : null}
      </div>
      {calendarOpen && (
        <div className={styles.calendar}>
          <Dropdown padding={false} setIsOpen={setCalendarOpen}>
            <CalendarDouble
              startDate={startDateFilter}
              setStartDate={setStartDateFilter}
              endDate={endDateFilter}
              setEndDate={setEndDateFilter}
              setIsOpen={setCalendarOpen}
            />
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default TimerFilters;
