import { handleAccountMe } from "account/controllers";
import { onUpdateUser } from "users/actions";
import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { destroyCookie } from "nookies";
import { useNavigate } from "react-router-dom";
import { RootState } from "app/redux/store";
import { getTokenFromCookie } from "middlewares/authorizations";

type Props = {
  children: ReactNode;
};

const ProfilLayout: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const token = getTokenFromCookie();

  useEffect(() => {
    if (!user && token) {
      handleAccountMe()
        .then(async function (response: any) {
          dispatch(onUpdateUser(response.data.data));
        })
        .catch((err: any) => {
          destroyCookie(null, "token");
          navigate("/connexion");
        });
    }
  }, [dispatch, navigate, token, user]);

  return <>{children}</>;
};
export default ProfilLayout;
