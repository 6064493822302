const ReglagesIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.383 5.074c-.607-2.498-4.16-2.498-4.766 0A2.452 2.452 0 0 1 9.96 6.589C7.763 5.25 5.25 7.763 6.589 9.959a2.452 2.452 0 0 1-1.515 3.658c-2.498.607-2.498 4.16 0 4.766a2.452 2.452 0 0 1 1.515 3.658c-1.338 2.195 1.174 4.708 3.37 3.37a2.452 2.452 0 0 1 3.658 1.516c.607 2.497 4.16 2.497 4.766 0a2.452 2.452 0 0 1 3.658-1.516c2.195 1.338 4.708-1.175 3.37-3.37a2.452 2.452 0 0 1 1.516-3.658c2.497-.607 2.497-4.16 0-4.766A2.452 2.452 0 0 1 25.41 9.96c1.338-2.196-1.175-4.708-3.37-3.37a2.452 2.452 0 0 1-3.658-1.515ZM16 20.8a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6Z"
      />
    </svg>
  );
};
export default ReglagesIcon;
