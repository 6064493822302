export const errorsAPI = {
  TOKEN_FALSE: "Mauvais token",
  TOKEN_NOT_FOUND:
    "Oups, il semble que vous n'ayez pas cliquer sur le dernier lien de réinitialisation",
  NO_TOKEN: "Le token est manquant",
  AUTHENTIFICATION_FAILED: "Erreur d'authetification",
  EMPTY_DATA: "Des données sont manquantes",
  EMAIL_ALREADY_EXIST: "Cette email existe déjà",
  EMAIL_NOT_FOUND: "Cette email n'existe pas dans notre base de données",
  WRONG_PASSWORD: "Mauvais mot de passe",
  ACCOUNT_NOT_ACTIVE: "Ce compte n'est pas actif",
  PASSWORD_NOT_MATCH: "les mots de passes ne correspondent pas",
  USER_NOT_FOUND: "Cette utilisateur n'existe pas",
  MEDIA_NOT_FOUND: "Un média est lié mais n'existe plus",
  EMAIL_ALREADY_EXISTS: "Un compte est déjà associé à cette adresse email",
  CUSTOMER_NOT_FOUND: "Ce client n'existe pas",
  STORY_NOT_FOUND: "Cette story n'existe pas",
  MEDIA_STORY_LINK_NOT_FOUND: "Ce media n'est pas lié à cette story",
  MISSION_NOT_FOUND: "Cette mission n'existe pas",
  PROJECT_NOT_FOUND: "Ce projet n'existe pas",
  NOT_AUTHORIZED: "Vous n'êtes pas autorisé à effectuer cette action",
  END_DATE_BEFORE_START_DATE: "La date de fin est avant la date de début",
  REPORT_NOT_FOUND: "Ce rapport n'existe pas",
  SPRINT_NOT_FOUND: "Ce sprint n'existe pas",
  CANT_HAVE_TWO_ACTIVE_SPRINTS: "Vous ne pouvez pas avoir 2 sprints actifs",
  CANT_DEACTIVATE_SPRINT_WITHOUT_MOVETO:
    "Vous ne pouvez pas désactiver un sprint sans déplacer les stories dans un autre sprint",
  NOT_ALLOWED_TO_DELETE_SPRINT:
    "Vous n'êtes pas autorisé à supprimer ce sprint",
  NOT_ALLOWED_TO_GET_STORY: "Vous n'êtes pas autorisé à voir cette story",
  NO_STORIES_PROVIDED: "Aucune story n'a été fournie",
  NOT_ALLOWED_TO_UPDATE_STORY_ORDER:
    "Vous n'êtes pas autorisé à modifier l'ordre des stories",
  NOT_ALLOWED_TO_UPDATE_STORY:
    "Vous n'êtes pas autorisé à modifier cette story",
  NOT_ALLOWED_TO_DELETE_TASK:
    "Vous n'êtes pas autorisé à supprimer cette tâche",
  NOT_ALLOWED_TO_DELETE_STORY:
    "Vous n'êtes pas autorisé à supprimer cette story",
  NOT_ALLOWED_TO_CREATE_STORY_TASK:
    "Vous n'êtes pas autorisé à créer une tâche pour cette story",
  NOT_ALLOWED_TO_UPDATE_STORY_TASK:
    "Vous n'êtes pas autorisé à modifier cette tâche",
  NO_STORY_TASKS_PROVIDED: "Aucune tâche n'a été fournie",
  NOT_ALLOWED_TO_UPDATE_STORY_TASKS_ORDER:
    "Vous n'êtes pas autorisé à modifier l'ordre des tâches",
  NOT_ALLOWED_TO_DELETE_STORY_TASK:
    "Vous n'êtes pas autorisé à supprimer cette tâche",
  TEAM_NOT_FOUND: "Cette équipe n'existe pas",
  TEAM_USER_LINK_NOT_FOUND:
    "Ce lien entre l'utilisateur et l'équipe n'existe pas",
  USER_ALREADY_IN_TEAM: "Cet utilisateur est déjà dans cette équipe",
  TIMER_NOT_FOUND: "Ce timer n'existe pas",
  CUSTOMER_ALREADY_EXIST: "Ce client existe déjà",
};
