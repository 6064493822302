import { ArrowArcLeft, Trash, X } from "@phosphor-icons/react";
import styles from "./CreateStoryOptions.module.scss";
import Dropdown from "app/components/dropdown/Dropdown";
import Popup from "ui/components/popup/Popup";
import { StoryType } from "story/types";
import { useState, useContext, useMemo, useCallback } from "react";
import { handleDeleteStory, handleUpdateStory } from "story/controllers/story";
import { useDispatch } from "react-redux";
import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import OptionsIcon from "ui/icons/OptionsIcon";
import { FrameCorners, Play, Sidebar, Stop } from "phosphor-react";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { areArraysEqual } from "utils/checkArrays";
import useLocalStorage from "hooks/useLocalStorage";
import { updateFormatCard } from "sprint/actions/sprints";


type Props = {
  storyData: StoryType;
  formRef: React.RefObject<HTMLFormElement>;
  closePopup: () => void;
  card?: string | null;
};

const CreateStoryOptions = ({ storyData, formRef, closePopup, card }: Props) => {

  const [typeCard, setTypeCard] = useLocalStorage("typeCard", null);

  const dispatch = useDispatch();
  const { currentTimer, setCurrentTimer, triggerTimer, setTimerOpen } =
    useContext(CurrentTimerContext);
  const [openEditOptions, setOpenEditOptions] = useState<boolean>(false);
  const [openDeleteStoryPopup, setOpenDeleteStoryPopup] =
    useState<boolean>(false);

  const deleteStory = async () => {
    await handleDeleteStory(storyData.id);
  };

  const moveStoryToBacklog = async () => {
    try {
      await handleUpdateStory(storyData.id, {
        sprint_id: 0,
        order: 0,
        planif_order: 0,
        type: "todo",
      });
      const toastData = {
        status: true,
        message: "La story a bien été déplacée dans le backlog",
        error: false,
      };
      dispatch(showModal(toastData));
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
    }
  };

  const createTimer = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!formRef.current) return;
      e.stopPropagation();
      e.preventDefault();
      setTimerOpen(true);
      if (currentTimer?.end_at === null && currentTimer?.id) {
        setTimerOpen(true);
        const modalData = {
          status: true,
          message: `Vous avez déjà un timer en cours`,
          error: true,
        };
        dispatch(showModal(modalData));
      } else {
        const currentTimerData = {
          customer: storyData.customer,
          customer_id: storyData.customer?.id,
          project_id: storyData.project?.id,
          project: storyData.project,
          missions: storyData.missions,
          mission_ids: storyData.missions?.map((mission) => mission.id) || [],
          name: `#${storyData.id}: ${storyData.name}`,
          start_at: "",
          storyData_id: storyData.id,
        };

        setCurrentTimer(currentTimerData);
        await triggerTimer(currentTimerData);
        if (typeof formRef.current.requestSubmit === "function") {
          formRef.current.requestSubmit();
        } else {
          formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        }
      }
    },
    [
      setTimerOpen,
      currentTimer?.end_at,
      currentTimer?.id,
      dispatch,
      storyData.customer,
      storyData.project,
      storyData.missions,
      storyData.name,
      setCurrentTimer,
      triggerTimer,
      storyData.id,
      formRef,
    ]
  );

  const HandleClose = () => {
    closePopup();
  };

  const finishTimer = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!formRef.current) return;
      e.preventDefault();
      e.stopPropagation();
      if (!currentTimer?.id) return;
      await triggerTimer(currentTimer);
      if (typeof formRef.current.requestSubmit === "function") {
        formRef.current.requestSubmit();
      } else {
        formRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    },
    [currentTimer, triggerTimer, formRef]
  );

  const renderPlay = useMemo(() => {
    let checkMissionsArray = true;
    if (currentTimer?.mission_ids) {
      if (!storyData.mission_ids && currentTimer.mission_ids.length > 0) {
        checkMissionsArray = false;
      } else {
        checkMissionsArray = areArraysEqual(
          currentTimer.mission_ids,
          storyData.mission_ids || []
        );
      }
    }

    if (
      currentTimer &&
      (currentTimer.name === storyData.name ||
        currentTimer.name === `#${storyData.id}: ${storyData.name}`) &&
      checkMissionsArray
    ) {
      if (
        (currentTimer.project_id === null && storyData.project_id === null) ||
        currentTimer.project_id === Number(storyData.project_id)
      ) {
        if (
          (currentTimer.customer_id === null &&
            storyData.customer_id === null) ||
          currentTimer.customer_id === Number(storyData.customer_id)
        ) {
          return (
            <button
              className="m-button m-button--black m-button--ratio"
              type="button"
              onClick={finishTimer}
            >
              <span className="sr-only">Arrêter le timer</span>
              <Stop weight="fill" />
            </button>
          );
        }
      }
    }
    if (!currentTimer?.id) {
      return (
        <button
          className="m-button m-button--black m-button--ratio"
          type="button"
          onClick={createTimer}
        >
          <span className="sr-only">Démarrer le timer</span>
          <Play weight="fill" />
        </button>
      );
    }
  }, [createTimer, currentTimer, finishTimer, storyData]);

  const changeTypeShow = useCallback(() => {

    if (typeCard === 'window' || !typeCard) {
      setTypeCard("modal");
      dispatch(updateFormatCard('modal'))
    } else {
      setTypeCard("window");
      dispatch(updateFormatCard('window'))
    }
    setOpenEditOptions(false)
  }, [typeCard, setTypeCard, dispatch]);

  const renderTypeShowText = useMemo(() => {

    if (typeCard === 'modal') {
      return (
        <li>
          <button
            type="button"
            className={styles.dropdownItem}
            onClick={changeTypeShow}
          >
            <Sidebar weight="fill" />
            Afficher en barre latérale
          </button>
        </li>

      )

    } else {
      return (

        <li>
          <button
            type="button"
            className={styles.dropdownItem}
            onClick={changeTypeShow}
          >
            <FrameCorners weight="fill" />
            Afficher en fenêtre
          </button>
        </li>
      )
    }

  }, [typeCard, changeTypeShow]);





  return (
    <div className={( card && card === 'modal') ? styles.optionsRight : styles.options}>
      <p className={styles.id}>#{storyData.id}</p>
      <div className={styles.optionsActions}>
        <button
          className="m-button m-button--grey m-button--ratio"
          type="button"
          onClick={HandleClose}
        >
          <span className="sr-only">Fermer l'ajout de mission</span>
          <X weight="bold" />
        </button>
        {renderPlay}
        <div className={styles.optionsWrapper}>

          <button
            type="button"
            className={`m-button m-button--grey ${styles.editButton}`}
            onClick={() => setOpenEditOptions(true)}
          >
            <OptionsIcon />
          </button>
          {openEditOptions && (
            <div className={styles.editOptions}>
              <Dropdown setIsOpen={setOpenEditOptions}>
                <ul>
                  {storyData?.sprint_id !== 0 && (
                    <li>
                      <button
                        type="button"
                        className={styles.dropdownItem}
                        onClick={moveStoryToBacklog}
                      >
                        <ArrowArcLeft weight="fill" />
                        Déplacer la story dans le backlog
                      </button>
                    </li>
                  )}

                  {renderTypeShowText}
                  <li>
                    <button
                      type="button"
                      className={styles.dropdownItem}
                      onClick={() => setOpenDeleteStoryPopup(true)}
                    >
                      <Trash weight="fill" />
                      Supprimer la story
                    </button>
                    {openDeleteStoryPopup && (
                      <Popup setIsOpen={setOpenDeleteStoryPopup} width="496px">
                        <div className="m-popup__header">
                          <p>Supprimer la story</p>
                          <button
                            type="button"
                            className="m-popup__header__close"
                            onClick={() => setOpenDeleteStoryPopup(false)}
                          >
                            <X weight="bold" />
                          </button>
                        </div>
                        <div className="m-popup__content">
                          <p className="m-popup__content__text-grey">
                            Confirmez-vous la suppression de la story ?<br />
                            Cette action est irréversible.
                          </p>
                          <button
                            type="button"
                            onClick={deleteStory}
                            className="m-button m-button--black"
                          >
                            <Trash weight="fill" />
                            Supprimer la story
                          </button>
                        </div>
                      </Popup>
                    )}
                  </li>
                </ul>
              </Dropdown>
            </div>
          )}
        </div>


        <div className={styles.submit}>

          <button
            className="m-button m-button--black m-button--full"
            type="submit"
          >
            {storyData.id ? "Mettre à jour la story" : "Créer la story"}
          </button>
        </div>
      </div>

    </div>
  );
};

export default CreateStoryOptions;
