import styles from "./HeaderColor.module.scss";

type Props = {
  color: string;
  children: React.ReactNode;
};

const HeaderColor = ({ color, children }: Props) => {
  return (
    <div className={styles.container} style={{ backgroundColor: color }}>
      {children}
    </div>
  );
};

export default HeaderColor;
