import { useEffect, useState } from "react";
import styles from "./MissionsDropdown.module.scss";
import { Mission } from "clients/interfaces/mission";
import { handleGetAllMissions } from "clients/controllers/missions";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  onClick: (mission: Mission) => void;
};

const MissionsDropdown = ({ onClick }: Props) => {
  const dispatch = useDispatch();
  const [missions, setMissions] = useState<Mission[]>([]);

  useEffect(() => {
    handleGetAllMissions()
      .then((res) => {
        setMissions(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [dispatch]);

  return (
    <ul className={styles.container}>
      {missions.map((mission) => (
        <li key={mission.id}>
          <button
            className={styles.missionDropdownItem}
            onClick={() => onClick(mission)}
          >
            <span style={{ backgroundColor: mission.color }}></span>
            <p>{mission.name}</p>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default MissionsDropdown;
