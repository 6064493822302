export const colors = [
  "#5568F6",
  "#55E3F6",
  "#25E197",
  "#A5E125",
  "#F6A255",
  "#F7E543",
  "#F65555",
  "#F669AD",
  "#A769F6",
];
