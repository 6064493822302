import { SprintType } from "sprint/types";
import { StoryType } from "story/types";

export const actionTypesSprintStories = {
  UPDATE_SPRINTS: "UPDATE_SPRINTS",
  UPDATE_SPRINT_STORIES: "UPDATE_SPRINT_STORIES",
  UPDATE_FORMAT_CARD : "UPDATE_FORMAT_CARD"
};
type Nullable<T> = T | null;

export const updateSprints = (sprints: Nullable<SprintType[]>) => ({
  type: actionTypesSprintStories.UPDATE_SPRINTS,
  payload: sprints,
});

export const updateFormatCard = (type: string) => ({
  type: actionTypesSprintStories.UPDATE_FORMAT_CARD,
  payload: type,
});

export const updateSprintStories = (
  sprintId: number,
  stories: Nullable<StoryType[]>
) => {
  return {
    type: actionTypesSprintStories.UPDATE_SPRINT_STORIES,
    payload: { sprintId, stories },
  };
};
