const OptionsIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <g clipPath="url(#OptionsIcon)">
        <path d="M10.3594 8.5a1.85948 1.85948 0 0 1-1.14785 1.7178 1.85834 1.85834 0 0 1-1.0743.1058 1.85912 1.85912 0 0 1-.95203-.50882 1.85942 1.85942 0 0 1 .28177-2.86079A1.85937 1.85937 0 0 1 8.5 6.64062a1.86602 1.86602 0 0 1 1.31285.54654A1.86611 1.86611 0 0 1 10.3594 8.5ZM3.1875 6.64062a1.85937 1.85937 0 0 0-1.82365 2.22213 1.8595 1.8595 0 0 0 .50887.95203 1.85912 1.85912 0 0 0 .95203.50882c.36069.0718.73455.035 1.0743-.1058A1.8593 1.8593 0 0 0 5.04688 8.5a1.86598 1.86598 0 0 0-.54654-1.31284 1.86598 1.86598 0 0 0-1.31284-.54654Zm10.625 0c-.3677 0-.7272.10906-1.033.31337a1.85891 1.85891 0 0 0-.6848.83446 1.85834 1.85834 0 0 0-.1058 1.0743c.0717.36068.2488.69199.5088.95203.2601.26002.5914.43712.9521.50882.3606.0718.7345.035 1.0743-.1058A1.85948 1.85948 0 0 0 15.6719 8.5a1.86595 1.86595 0 0 0-.5466-1.31284 1.86585 1.86585 0 0 0-1.3128-.54654Z" />
      </g>
      <defs>
        <clipPath id="OptionsIcon">
          <path fill="#fff" d="M0 0h17v17H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OptionsIcon;
