import { Project } from "clients/interfaces/project";
import { Infinity } from "@phosphor-icons/react";
import styles from "./CardTime.module.scss";
import { secondsToHours } from "utils/formatTime";
import useWindowSize from "hooks/useWindowSize";

type Props = {
  project: Project;
};

const CardTime = ({ project }: Props) => {
  const used = secondsToHours(project.used_time || 0);
  const progressMax = project?.hours && project.hours * 3600;
  console.log(project);

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.title}>{project.name}</p>
        {project.description && (
          <p className={styles.description}>{project.description}</p>
        )}
      </div>
      <div className={styles.time}>
        <progress
          value={project.used_time}
          max={progressMax}
          className={styles.progress}
        />
        <div className={styles.textsTime}>
          <p>
            {used}
            {typeof project.hours === "number" &&
            typeof project.used_time === "number" &&
            project.hours * 3600 - project.used_time < 0 ? (
              <span className={styles.red}>
                +{" "}
                {secondsToHours(
                  Math.abs(project.hours * 3600 - project.used_time)
                )}
              </span>
            ) : (
              ""
            )}
          </p>
          {project.hours === 0 || project.hours === null ? (
            <Infinity size={20} color="#030507" weight="bold" />
          ) : (
            <p>{project.hours}h00</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardTime;
