import { getRequest, postRequest, putRequest } from "utils/useApi";
import {
  Customer,
  ContactCustomer,
  AccountCustomer,
  CreateCustomer,
} from "clients/interfaces/customer";

export const handleGetAllCustomers = (filters?: any) => {
  return getRequest("customers", filters).then((response) => {
    return response.data;
  });
};

export const handleGetCustomer = (id: any) => {
  return getRequest(`customers/${id}`).then((response) => {
    return response.data;
  });
};

export const handleCreateCustomer = (customer: CreateCustomer) => {
  return postRequest("customers", customer).then((response) => {
    return response.data;
  });
};

export const handleUpdateCustomer = (
  id: number,
  customer: Customer | ContactCustomer | AccountCustomer
) => {
  return putRequest(`customers/${id}`, customer).then((response) => {
    return response.data;
  });
};
