import Popup from "ui/components/popup/Popup";
import { useState } from "react";
import { Mission } from "clients/interfaces/mission";
import AddMissionProjectPopup from "clients/components/add-mission-project-popup/AddMissionProjectPopup";

type Props = {
  mission: Mission;
  setEditMissions: (value: boolean) => void;
  setModifyingProject: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddMissionProject = ({
  mission,
  setEditMissions,
  setModifyingProject,
}: Props) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  return (
    <>
      <button
        className="m-button m-button--fit-content m-button--tronc"
        style={{ backgroundColor: mission.color }}
        onClick={handlePopupOpen}
      >
        <span>{mission.name}</span>
      </button>
      {popupOpen && (
        <Popup setIsOpen={setPopupOpen} width="496px">
          <AddMissionProjectPopup
            mission={mission}
            setPopupOpen={setPopupOpen}
            setEditMissions={setEditMissions}
            setModifyingProject={setModifyingProject}
          />
        </Popup>
      )}
    </>
  );
};

export default AddMissionProject;
