import "./App.scss";
import Layout from "app/components/Layout";
import React from "react";

function App() {
  if (typeof window !== "undefined") {
    window.React = React;
  }
  return <Layout />;
}

export default App;
