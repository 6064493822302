import styles from "./Switch.module.scss";
import { ChangeEvent } from "react";

type SwitchProps = {
  readonly id: string;
  readonly name: string;
  checked: boolean;
  readonly setChecked: (value: boolean) => void;
  readonly className?: string;
};

const Switch = ({ id, name, setChecked, checked }: SwitchProps) => {
  const checkActive = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: inputValue },
    } = event;
    if (inputValue) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  return (
    <div className={styles.container}>
      <input
        checked={checked}
        className={styles.input}
        type="checkbox"
        id={id}
        onChange={checkActive}
      />
      <div
        className={`${styles.circle} ${
          checked ? styles.checked : styles.unchecked
        }`}
      />
    </div>
  );
};

export default Switch;
