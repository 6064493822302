import { errorsAPI } from "app/constants/errors";
import styles from "./AddProject.module.scss";
import {
  handleAddProject,
  handleUpdateProject,
} from "clients/controllers/projects";
import { Project } from "clients/interfaces/project";
import { useState } from "react";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import { X } from "@phosphor-icons/react";

type Props = {
  data?: Project;
  edit?: boolean;
  customerId: string;
  setCreatingProject: (value: boolean) => void;
  setShowCreateProject: (value: boolean) => void;
};

const AddProject = ({
  data,
  edit,
  customerId,
  setCreatingProject,
  setShowCreateProject,
}: Props) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    name: "",
    planning_link: "",
  });

  console.log("here", data?.hours);

  const addProject = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let needToStop = false;
    const form = e.currentTarget; // Get the form element

    const nameInput = form.elements.namedItem("name") as HTMLInputElement;
    const descriptionInput = form.elements.namedItem(
      "description"
    ) as HTMLInputElement;
    const hoursInput = form.elements.namedItem("hours") as HTMLInputElement;
    const quotationRefInput = form.elements.namedItem(
      "reference"
    ) as HTMLInputElement;
    const planningLinkInput = form.elements.namedItem(
      "link"
    ) as HTMLInputElement;

    if (nameInput.value === "") {
      setErrors({
        ...errors,
        name: "Le nom du projet est requis",
      });
      needToStop = true;
    }

    if (planningLinkInput.value !== "") {
      // check if it is a valid url
      const urlRegex = new RegExp(
        "^(http(s)://.)[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}([-a-zA-Z0-9@:%_+.~#?&//=]*)$"
      );
      if (!urlRegex.test(planningLinkInput.value)) {
        setErrors({
          ...errors,
          planning_link: "Le lien du planning n'est pas valide",
        });
        needToStop = true;
      }
    }

    if (needToStop) return;

    const project: Project = {
      name: nameInput.value,
      description:
        descriptionInput.value !== "" ? descriptionInput.value : undefined,
      hours: hoursInput.value ? parseFloat(hoursInput.value) : undefined,
      quotation_ref:
        quotationRefInput.value !== "" ? quotationRefInput.value : undefined,
      planning_link:
        planningLinkInput.value !== "" ? planningLinkInput.value : undefined,
      active: true,
      customer_id: Number(customerId) as number,
    };
    if (edit && data?.id) {
      handleUpdateProject(data?.id, project)
        .then(() => {
          setCreatingProject(true);
          setShowCreateProject(false);
          const toastData = {
            status: true,
            message: "Le projet a bien été modifié",
            error: false,
          };
          dispatch(showModal(toastData));
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err.response.data.message;
          const toastData = {
            status: true,
            message: errorsAPI[errorMessage as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    } else {
      handleAddProject(project)
        .then((res) => {
          setCreatingProject(true);
          setShowCreateProject(false);
          const toastData = {
            status: true,
            message: "Le projet a bien été créé",
            error: false,
          };
          dispatch(showModal(toastData));
        })
        .catch((err) => {
          const errorMessage = err.response.data.message;
          const toastData = {
            status: true,
            message: errorsAPI[errorMessage as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  };

  const handleClose = () => {
    setShowCreateProject(false);
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={addProject}>
        <div className={styles.header}>
          <p>{edit ? "Modifier le projet" : "Ajouter un projet"}</p>
          <div className={styles.header__buttons}>
            <button type="submit" className="m-button m-button--black">
              Enregistrer
            </button>
            <button
              className="m-button m-button--grey m-button--ratio"
              type="button"
              onClick={handleClose}
            >
              <span className="sr-only">Fermer l'ajout de mission</span>
              <X weight="bold" />
            </button>
          </div>
        </div>
        <div className={styles.input}>
          <label htmlFor="name" className="m-label">
            Nom du projet<span>*</span>
          </label>
          <input
            type="text"
            id="name"
            className="m-input m-input--grey"
            placeholder="Projet"
            name="name"
            maxLength={84}
            required
            defaultValue={data?.name}
          />
          {errors.name !== "" && <p className="m-error">{errors.name}</p>}
        </div>
        <div className={styles.input}>
          <label htmlFor="description" className="m-label">
            Description
          </label>
          <textarea
            id="description"
            className="m-textarea m-textarea--grey"
            placeholder="Description"
            name="description"
            defaultValue={data?.description}
          ></textarea>
        </div>
        <div className={styles.input}>
          <label htmlFor="ref" className="m-label">
            Nombres d'heures vendues
          </label>
          <input
            type="number"
            id="hours"
            className="m-input m-input--grey"
            placeholder="Laisser vide si 0 (Claire ne mets rien ici)"
            name="hours"
            defaultValue={data?.hours}
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="ref" className="m-label">
            Référence du devis
          </label>
          <input
            type="text"
            id="ref"
            className="m-input m-input--grey"
            placeholder="Référence"
            name="reference"
            defaultValue={data?.quotation_ref}
          />
        </div>
        <div className={styles.input}>
          <label htmlFor="link" className="m-label">
            Lien du planning détaillé
          </label>
          <input
            type="url"
            id="link"
            className="m-input m-input--grey"
            placeholder="Lien du planning"
            name="link"
            defaultValue={data?.planning_link}
          />
          {errors.planning_link !== "" && (
            <p className="m-error">{errors.planning_link}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddProject;
