import { useEffect } from "react";

const useFocusTrap = (
  wrapperRef: React.RefObject<HTMLElement>,
  trapKeyCode: string = "Tab",
  dataLoaded: boolean = false
) => {
  useEffect(() => {
    if (!dataLoaded) return;
    if (!wrapperRef.current) return;

    const focusableElements = wrapperRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusableElement = focusableElements[0] as HTMLElement;
    const lastFocusableElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    const trapFocus = (e: KeyboardEvent) => {
      if (e.key === trapKeyCode) {
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      }
    };

    const popupElement = wrapperRef.current;
    popupElement.addEventListener("keydown", trapFocus);

    return () => {
      popupElement.removeEventListener("keydown", trapFocus);
    };
  }, [wrapperRef, trapKeyCode, dataLoaded]);
};

export default useFocusTrap;
