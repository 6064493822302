import Switch from "ui/components/switch/Switch";
import styles from "./ReportSettings.module.scss";
import { useState } from "react";
import { handleUpdateReport } from "clients/controllers/reports";
import ContentCopyIcon from "ui/icons/ContentCopyIcon";
import { useDispatch } from "react-redux";
import { showModal } from "app/actions/modal";
import { ReportType } from "clients/interfaces/report";
import { errorsAPI } from "app/constants/errors";

type Props = {
  isPublicData: number;
  reportId: number;
  setReport: React.Dispatch<React.SetStateAction<ReportType>>;
};

const ReportSettings = ({ isPublicData, reportId, setReport }: Props) => {
  const dispatch = useDispatch();
  const [isPublic, setIsPublic] = useState<boolean>(
    isPublicData === 1 ? true : false
  );

  const handleChangeChecked = async (newState: boolean) => {
    try {
      await handleUpdateReport(reportId, { is_public: newState });
      setIsPublic(newState);
      setReport((prevState) => ({
        ...prevState,
        is_public: newState ? 1 : 0,
      }));
    } catch (err: any) {
      console.log(err);
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
    }
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    const toastData = {
      status: true,
      message: "Lien copié dans le presse-papier",
      error: false,
    };
    dispatch(showModal(toastData));
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Partager le rapport</p>
      <div className={styles.button}>
        <div className={styles.infos}>
          <label htmlFor="public">Rendre le lien public</label>
          <p>Quiconque avec ce lien pourra avoir accès au reporting</p>
        </div>
        <Switch
          id="public"
          name="public"
          checked={isPublic}
          setChecked={handleChangeChecked}
        />
      </div>
      <button
        className={styles.copy}
        disabled={!isPublic}
        onClick={copyClipboard}
      >
        <span className="sr-only">Copier le lien</span>
        <p className={styles.linkUrl}>{window.location.href}</p>
        <ContentCopyIcon />
      </button>
    </div>
  );
};

export default ReportSettings;
