import { createContext, useState } from "react";

type EditingSprintsContextType = {
  editingSprints: boolean;
  setEditingSprints: (value: boolean) => void;
};

export const EditingSprintsContext = createContext<EditingSprintsContextType>({
  editingSprints: false,
  setEditingSprints: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const EditingSprintsProvider = ({ children }: Props) => {
  const [editingSprints, setEditingSprints] = useState(false);

  return (
    <EditingSprintsContext.Provider
      value={{ editingSprints, setEditingSprints }}
    >
      {children}
    </EditingSprintsContext.Provider>
  );
};
