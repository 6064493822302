import { actionTypesSprintStories } from "sprint/actions/sprints";
import { SprintType } from "sprint/types";

export type State = Readonly<SprintType[]>;

const initialState: State = [];

const sprintsState = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypesSprintStories.UPDATE_SPRINTS:
      return [...action.payload];
    case actionTypesSprintStories.UPDATE_SPRINT_STORIES:
      const { sprintId, stories } = action.payload;
      return state.map((sprint) => {
        return sprint.id === sprintId ? { ...sprint, stories } : sprint;
      });
    default:
      return state;
  }
};

export default sprintsState;
