import { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import styles from "./SidePopup.module.scss";
import useFocusTrap from "hooks/useFocusTrap";
import { useSelector } from "react-redux";
import { selectTypeCardState } from "sprint/selector";
import { useWindowSize } from "@uidotdev/usehooks";

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  readonly children: React.ReactNode;
  dataLoaded?: boolean;
  onClickOutside?: () => void;
  modal?: boolean | null;
};

const SidePopup = ({
  setIsOpen,
  children,
  modal = false,
  dataLoaded = false,
}: Props) => {
  const { width } = useWindowSize();
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [animationReady, setAnimationReady] = useState(false);
  const [animation, setAnimation] = useState({
    hidden: { opacity: 0, translateX: 40 },
    show: {
      opacity: 1,
      translateX: 0,
    },
  });
  const card = useSelector(selectTypeCardState);
  useFocusTrap(popupRef, "Tab", dataLoaded);

  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    if (!dataLoaded) return;
    const popupElement = popupRef.current;
    if (!popupElement) return;

    const focusableElements = popupElement.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    const firstFocusableElement = focusableElements[0] as HTMLElement;
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }

    popupElement.addEventListener("keydown", handleEscapeKey);
    return () => {
      popupElement.removeEventListener("keydown", handleEscapeKey);
    };
  }, [handleEscapeKey, dataLoaded]);

  useEffect(() => {
    let dataAnimation: any;
    if ((modal && card === "modal") || (width && width < 1024)) {
      dataAnimation = {
        hidden: { opacity: 0, translateY: 40 },
        show: {
          opacity: 1,
          translateY: 0,
        },
      };
    } else {
      dataAnimation = {
        hidden: { opacity: 0, translateX: 40 },
        show: {
          opacity: 1,
          translateX: 0,
        },
      };
    }

    setAnimationReady(true);
    setAnimation(dataAnimation);
  }, [modal, card, width]);

  return (
    <div className={modal && card === "modal" ? styles.modal : styles.wrapper}>
      {animationReady && (
        <motion.div
          variants={animation}
          transition={{ type: "easeInOut" }}
          initial="hidden"
          className={styles.popup}
          ref={popupRef}
          animate="show"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
};

export default SidePopup;
