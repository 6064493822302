import { actionTypesModal } from "app/actions/modal";
import { ModalType } from "app/types/modal";
type Nullable<T> = T | null;

export type State = Readonly<{
  modal?: Nullable<ModalType>;
}>;

const initialState: State = {
  modal: null,
};

const modalState = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypesModal.SHOW_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    default:
      return state;
  }
};

export default modalState;
