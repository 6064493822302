import {
  CustomerStats,
  MissionStats,
  ProjectStats,
} from "statistics/types/statistics";
import styles from "./StatisticItem.module.scss";
import ProgressBar from "ui/components/progress-bar/ProgressBar";
import { secondsToHours } from "utils/formatTime";

type Props = {
  statistic: MissionStats | ProjectStats | CustomerStats;
};

const StatisticItem = ({ statistic }: Props) => {
  const progressPercent =
    statistic.time > 0
      ? (statistic.time * 100) / (statistic.hoursSell * 3600)
      : 0;

  const sanitizedProgressPercent =
    isNaN(progressPercent) || !isFinite(progressPercent)
      ? progressPercent === Infinity
        ? 100
        : 0
      : progressPercent;

  return (
    <div
      className={styles.wrapper}
      style={{ borderLeft: `6px solid ${statistic.color}` }}
    >
      <div className={styles.name}>{statistic.name}</div>
      {"customer_name" in statistic ? (
        <div className={styles.customer}>{statistic.customer_name}</div>
      ) : null}
      <div className={styles.time}>
        <ProgressBar
          color={statistic.color}
          completedPercent={sanitizedProgressPercent}
        />
        <div className={styles.timeText}>
          <p>{secondsToHours(statistic.time || 0)} effectuées</p>
          {statistic.hoursSell > 0 && <p>sur {statistic.hoursSell}h vendues</p>}
        </div>
      </div>
    </div>
  );
};

export default StatisticItem;
