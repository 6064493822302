import { MagnifyingGlass } from "@phosphor-icons/react";
import styles from "./Filter.module.scss";
import { useCallback, useMemo, useState } from "react";
import FilterUsers from "./filterUsers/FilterUsers";
import { SprintType } from "sprint/types";

type Props = {
  setFilter: (
    filter: {
      type: "user" | "customer";
      value: string;
      id: number;
    } | null
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
  currentSprint: SprintType | null;
};

const Filter = ({ setFilter, setIsOpen, currentSprint }: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const test = currentSprint?.user;

  console.log(test);

  const searchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSelect = useCallback(
    (type: "user" | "customer", value: string, id: number) => {
      setFilter({ type, value, id });

      setIsOpen(false);
      const filter = {
        type,
        value,
        id,
      };
      localStorage.setItem(type, JSON.stringify(filter));
    },
    [setFilter, setIsOpen]
  );

  const renderCustomers = useMemo(() => {
    if (!currentSprint?.customers) return null;
    if (searchValue && currentSprint?.customers) {
      const customersFiltered = currentSprint?.customers.filter((x) =>
        x.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      return customersFiltered.map((customer) => {
        return (
          <li key={customer.id}>
            <button
              className={styles.actionItem}
              onClick={() =>
                handleSelect("customer", customer.name, customer.id)
              }
            >
              <span
                className={styles.customerDot}
                style={{ backgroundColor: customer.color }}
              ></span>
              <span className={styles.customerText}>{customer.name}</span>
            </button>
          </li>
        );
      });
    } else {
      return currentSprint?.customers.map((customer) => {
        return (
          <li key={customer.id}>
            <button
              className={styles.actionItem}
              onClick={() =>
                handleSelect("customer", customer.name, customer.id)
              }
            >
              <span
                className={styles.customerDot}
                style={{ backgroundColor: customer.color }}
              ></span>
              <span className={styles.customerText}>{customer.name}</span>
            </button>
          </li>
        );
      });
    }
  }, [searchValue, currentSprint, handleSelect]);

  const renderUsers = useMemo(() => {
    if (!currentSprint?.user) return null;
    if (searchValue && currentSprint?.user) {
      const usersFiltered = currentSprint?.user.filter((x) =>
        x.username.toLowerCase().includes(searchValue.toLowerCase())
      );

      return <FilterUsers handleSelect={handleSelect} users={usersFiltered} />;
    } else {
      return (
        <FilterUsers handleSelect={handleSelect} users={currentSprint?.user} />
      );
    }
  }, [searchValue, currentSprint, handleSelect]);

  return (
    <div className={styles.container}>
      <div className="m-search">
        <input
          type={"text"}
          placeholder="Rechercher"
          onChange={searchFilter}
          value={searchValue}
        />
        <MagnifyingGlass weight="bold" />
      </div>
      <ul className={styles.list}>
        {renderUsers}
        {renderCustomers}
      </ul>
    </div>
  );
};

export default Filter;
