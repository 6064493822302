import { ArrowRight, Link, Trash } from "@phosphor-icons/react";
import styles from "./ReportHistoryItem.module.scss";
import { useState } from "react";
import Dropdown from "app/components/dropdown/Dropdown";

type Props = {
  item: any;
  index: any;
  deleteProps: Function;
  link: any;
};

const ReportHistoryItem = ({ item, index, deleteProps, link }: Props) => {
  const [copiedLink, setCopiedLink] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleDeleteReport = () => {
    deleteProps(item.id);
    setOpenConfirmation(false);
  };

  const handleOpenConfirmation = (e: any) => {
    setOpenConfirmation(true);
    e.preventDefault();
  };

  const handleCopyLink = (item: any) => {
    navigator.clipboard.writeText(`${window.location.origin}/rapport/${link}`);
    setCopiedLink(true);

    setTimeout(() => {
      return setCopiedLink(false);
    }, 2000);
  };

  const handleSecondClick = (item: any) => {
    setCopiedLink(false);
  };

  return (
    <div className={styles.report} key={index}>
      <div className={styles.report__information}>
        <div className={styles.report__information__tags}>
          {item.missions.map((mission: any, missionIndex: any) => (
            <div
              className={styles.report__information__tags__single}
              key={missionIndex}
              style={{ backgroundColor: mission.color }}
            >
              {mission.name}
            </div>
          ))}
        </div>

        <div className={styles.report__information__dates}>
          <div>{item.startDate}</div>
          <ArrowRight size={20} color="#5B5B6D" />
          <div>{item.endDate}</div>
        </div>
      </div>
      <div className={styles.report__links}>
        <button
          type="button"
          onClick={() => handleCopyLink(item)}
          className={styles.report__links__buttons}
        >
          <Link size={20} weight="bold" />
        </button>
        {copiedLink ? (
          <>
            {handleSecondClick}
            <div className={styles.report__links__copied}>
              <div className={styles.report__links__copied__text}>
                Lien copié
              </div>
            </div>
          </>
        ) : null}

        <Dropdown setIsOpen={setOpenConfirmation} padding={false} color={false}>
          <button
            type="button"
            onClick={handleOpenConfirmation}
            className={styles.report__links__buttons}
          >
            <Trash size={20} weight="bold" />
          </button>
          {openConfirmation ? (
            <div className={styles.confirmation}>
              <button
                type="button"
                onClick={handleDeleteReport}
                className={styles.confirmation__button}
              >
                <Trash size={20} weight="fill" />
                Supprimer le lien
              </button>
            </div>
          ) : null}
        </Dropdown>
      </div>
    </div>
  );
};

export default ReportHistoryItem;
