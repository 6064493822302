import styles from "./SelectMissions.module.scss";
import { handleGetAllMissions } from "clients/controllers/missions";
import { Mission } from "clients/interfaces/mission";
import useDebounce from "hooks/useDebounce";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { handleUpdateTimer } from "timer/controllers/timer";
import { TimerType } from "timer/types";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  timer: TimerType;
  timerMissions?: Mission[];
  setTimerMissions: (missions: Mission[]) => void;
  setAllTimers?: React.Dispatch<React.SetStateAction<TimerType[]>>;
};

const SelectMissions = ({
  timer,
  timerMissions,
  setTimerMissions,
  setAllTimers,
}: Props) => {
  const dispatch = useDispatch();
  const [missions, setMissions] = useState<Mission[]>([]);
  const [search, setSearch] = useState<string>("");

  const debouncedSearchValue = useDebounce(search, 300);

  const searchMission = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSelectMission = async (mission: Mission) => {
    const findMission = timerMissions?.find((m) => m.id === mission.id);
    let newMissions: Mission[];
    if (findMission) {
      newMissions = timerMissions?.filter((m) => m.id !== findMission.id) || [];
    } else {
      newMissions = [...(timerMissions || []), mission];
    }
    setTimerMissions(newMissions);

    try {
      await handleUpdateTimer(timer.id, {
        mission_ids: newMissions.map((m) => m.id),
      });

      if (setAllTimers) {
        setAllTimers((allTimers) =>
          allTimers.map((t) =>
            t.id === timer.id
              ? {
                  ...t,
                  missions: newMissions,
                  mission_ids: newMissions.map((mission) => mission.id),
                }
              : t
          )
        );
      }
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  useEffect(() => {
    const filter = {
      search: debouncedSearchValue,
    };
    handleGetAllMissions(filter)
      .then((res) => {
        setMissions(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [debouncedSearchValue, dispatch]);

  const selectedMissionIds = new Set(
    timerMissions?.map((mission) => mission.id)
  );

  return (
    <div className={styles.container}>
      <div className="m-search m-search--grey">
        <input
          type={"text"}
          placeholder="Rechercher une mission"
          onChange={searchMission}
          value={search}
        />
        <MagnifyingGlass weight="bold" />
      </div>
      <ul className={styles.missionsList}>
        {timerMissions &&
          timerMissions.map((mission) => (
            <li key={mission.id}>
              <button
                type="button"
                className={`${styles.mission} ${styles.selectedMission}`}
                style={{ backgroundColor: mission.color }}
                onClick={() => handleSelectMission(mission)}
              >
                <span>{mission.name}</span>
              </button>
            </li>
          ))}
        {missions.map(
          (mission) =>
            !selectedMissionIds.has(mission.id) && (
              <li key={mission.id}>
                <button
                  type="button"
                  className={styles.mission}
                  style={{ backgroundColor: mission.color }}
                  onClick={() => handleSelectMission(mission)}
                >
                  <span>{mission.name}</span>
                </button>
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default SelectMissions;
