import styles from "./History.module.scss";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import { ArrowRight, User } from "@phosphor-icons/react";
import { TimerType } from "timer/types";
import {
  formatDayHours,
  formatSecondsToHours,
  formatToFullDate,
} from "utils/formatDate";
import TextTrunc from "app/components/text-trunc/TextTrunc";

type Props = {
  timer: TimerType;
};

const History = ({ timer }: Props) => {
  const renderPicture = () => {
    if (timer.team_user_link?.media) {
      return (
        <img
          src={`${process.env.REACT_APP_API}${timer.team_user_link?.media.url}`}
          alt=""
        />
      );
    } else {
      return <User />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.person}>
          <div className={styles.personImage}>{renderPicture()}</div>
          <p>{timer.team_user_link?.username}</p>
        </div>
        <div className={styles.info}>
          {timer.name && (
            <TextTrunc color="#000000" maxWidth={200} text={timer.name} />
          )}
          {timer.sub_task && (
            <TextTrunc color="#8C8FA6" maxWidth={100} text={timer.sub_task} />
          )}
          <div className={styles.tags}>
            {timer.missions?.map((mission) => (
              <ProjectTag
                key={mission.id}
                content={mission.name}
                contentColor={mission.color}
                size="small"
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <p className={styles.date}>{formatToFullDate(timer.start_at)}</p>
        <div className={styles.hours}>
          {formatDayHours(timer.start_at)} <ArrowRight />{" "}
          {formatDayHours(timer.end_at)}
        </div>
        <p className={styles.total}>{formatSecondsToHours(timer.duration)}</p>
      </div>
    </div>
  );
};

export default History;
