import { Link } from "react-router-dom";
import styles from "./Historique.module.scss";
import { ArrowLeft, ArrowRight, CaretDown } from "@phosphor-icons/react";
import { SprintType } from "sprint/types";
import { useEffect, useState } from "react";
import { handleGetSprints } from "sprint/controllers";
import Dropdown from "app/components/dropdown/Dropdown";
import CardStory from "story/components/card-story/CardStory";
import { StoryType } from "story/types";
import { formatDate } from "utils/formatDate";

const Historique = () => {
  const [sprintSelected, setSprintSelected] = useState<SprintType | null>(null);
  const [sprints, setSprints] = useState<SprintType[] | null>(null);
  const [selectSprintDropdownOpen, setSelectSprintDropdownOpen] =
    useState(false);

  const handleDropdownOpen = () => {
    setSelectSprintDropdownOpen(true);
  };

  const startDate = formatDate(sprintSelected?.start_date || "");
  const endDate = formatDate(sprintSelected?.end_date || "");

  useEffect(() => {
    handleGetSprints({ active: 0 }).then((sprints) => {
      setSprints(sprints.data);
      setSprintSelected(sprints.data[0]);
    });
  }, []);

  return (
    <div className="layout-container">
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.header}>
            <Link to="/sprint" className={`m-button m-button--black`}>
              <span className="sr-only">Revenir au sprint</span>
              <ArrowLeft weight="bold" />
            </Link>
            <h1 className={styles.title}>Historique des sprints</h1>
          </div>
          <div className={styles.container}>
            <div className={styles.sprintHead}>
              <div className={styles.dropdownContainer}>
                <button
                  onClick={handleDropdownOpen}
                  className="m-button m-button--white"
                >
                  Sprint {sprintSelected?.number}
                  <CaretDown />
                </button>
                {selectSprintDropdownOpen && (
                  <div className={styles.dropdown}>
                    <Dropdown setIsOpen={setSelectSprintDropdownOpen}>
                      <ul className={styles.sprintsList}>
                        {sprints?.map((sprint) => {
                          return (
                            <li key={sprint.id}>
                              <button
                                className={styles.actionItem}
                                onClick={() => {
                                  setSprintSelected(sprint);
                                  setSelectSprintDropdownOpen(false);
                                }}
                              >
                                Sprint {sprint.number}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </Dropdown>
                  </div>
                )}
              </div>
              <p className={styles.date}>
                {startDate} <ArrowRight weight="bold" /> {endDate}
              </p>
            </div>
            <ul className={styles.statsList}>
              <li className={`${styles.statsItem} ${styles.statsItem__double}`}>
                <p className={styles.statsData}>
                  {sprintSelected?.total_points || 0}
                </p>
                <p className={styles.statsInfo}>points planifiés</p>
              </li>
              <li className={`${styles.statsItem} ${styles.statsItem__double}`}>
                <p className={styles.statsData}>
                  {sprintSelected?.total_points_finish || 0}
                </p>
                <p className={styles.statsInfo}>points réalisés</p>
              </li>
              <li className={styles.statsItem}>
                <p className={styles.statsData}>
                  {sprintSelected?.story_finish || 0}
                </p>
                <p className={styles.statsInfo}>stories terminées</p>
              </li>
            </ul>
            <ul className={styles.stories}>
              {sprintSelected?.stories.map((story: StoryType) => (
                <CardStory key={story.id} story={story} showPlay={false} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historique;
