import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import ComponentPage from "pages/components/Components";
import Login from "pages/user/login/Login";
import Menu from "ui/components/menu/Menu";

import { ProtectedRoute } from "app/components/protectedRoutes/ProtectedRoutes";
import { RedirectedRoutes } from "./redirectedRoutes/RedirectedRoutes";
import Planification from "pages/planification/Planification";
import Sprint from "pages/sprint/Sprint";
import Planning from "pages/planning/Planning";
import AllClients from "pages/clients/all-clients/AllClients";
import TimerPage from "pages/timer/Timer";
import Statistiques from "pages/statistiques/Statistiques";
import Profil from "pages/profil/Profil";
import Settings from "pages/settings/Settings";
import SingleClient from "pages/clients/single-client/SingleClient";
import SingleProject from "pages/clients/single-project/SingleProject";
import Dnd from "pages/dnd/Dnd";
import Historique from "pages/historique/Historique";
import { EditingSprintsProvider } from "planification/context/EditingSprintsProvider";
import { EditingCurrentSprintProvider } from "sprint/contexts/SprintContext";
import { EditingTimerProvider } from "timer/context/EditingTimerProvider";
import { CurrentTimerProvider } from "timer/context/CurrentTimerProvider";
import MainTimer from "timer/components/main-timer/MainTimer";
import Report from "pages/report/Report";
import Backlog from "pages/planification/backlog/Backlog";
import SprintSingle from "pages/planification/sprint/Sprint";

const Layout = () => {
  return (
    <Router>
      <Menu />
      <CurrentTimerProvider>
        <Routes>
          <Route
            path="/dnd"
            element={
              <ProtectedRoute>
                <Dnd />
              </ProtectedRoute>
            }
          />

          <Route
            path="/components"
            element={
              <ProtectedRoute>
                <ComponentPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/planification"
            element={
              <ProtectedRoute>
                <EditingSprintsProvider>
                  <Planification />
                </EditingSprintsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planification/backlog"
            element={
              <ProtectedRoute>
                <EditingSprintsProvider>
                  <Backlog />
                </EditingSprintsProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planification/sprint"
            element={
              <ProtectedRoute>
                <EditingSprintsProvider>
                  <SprintSingle />
                </EditingSprintsProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path="/sprint"
            element={
              <ProtectedRoute>
                <EditingCurrentSprintProvider>
                  <Sprint />
                </EditingCurrentSprintProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path="/sprint/historique"
            element={
              <ProtectedRoute>
                <Historique />
              </ProtectedRoute>
            }
          />

          <Route
            path="/planning"
            element={
              <ProtectedRoute>
                <Planning />
              </ProtectedRoute>
            }
          />

          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <AllClients />
              </ProtectedRoute>
            }
          />

          <Route
            path="/clients/:id"
            element={
              <ProtectedRoute>
                <SingleClient />
              </ProtectedRoute>
            }
          />

          <Route
            path="/clients/:customerId/projects/:id"
            element={
              <ProtectedRoute>
                <SingleProject />
              </ProtectedRoute>
            }
          />

          <Route
            path="/timer"
            element={
              <ProtectedRoute>
                <EditingTimerProvider>
                  <TimerPage />
                </EditingTimerProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path="/statistiques"
            element={
              <ProtectedRoute>
                <Statistiques />
              </ProtectedRoute>
            }
          />

          <Route
            path="/reglages"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path="/profil"
            element={
              <ProtectedRoute>
                <Profil />
              </ProtectedRoute>
            }
          />

          <Route
            path="/connexion"
            element={
              <RedirectedRoutes>
                <Login />
              </RedirectedRoutes>
            }
          />

          <Route path="/rapport/:id" element={<Report />} />

          <Route path="/" element={<Navigate to="/connexion" replace />} />
        </Routes>
        <MainTimer />
      </CurrentTimerProvider>
    </Router>
  );
};

export default Layout;
