import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "utils/useApi";
import { Project, ProjectActive } from "clients/interfaces/project";

export const handleGetAllProjects = (filters?: any) => {
  return getRequest("projects", filters).then((response) => {
    return response.data;
  });
};

export const handleGetProject = (id: any) => {
  return getRequest(`projects/${id}`).then((response) => {
    return response.data;
  });
};

export const handleAddProject = (project: Project) => {
  return postRequest("projects", project).then((response) => {
    return response.data;
  });
};

export const handleUpdateProject = (id: number, project: Project) => {
  return putRequest(`projects/${id}`, project).then((response) => {
    return response.data;
  });
};

export const handleUpdateProjectActive = (
  id: number,
  project: ProjectActive
) => {
  return putRequest(`projects/${id}`, project).then((response) => {
    return response.data;
  });
};

export const handleDeleteProject = (id: number) => {
  return deleteRequest(`projects/${id}`).then((response) => {
    return response.data;
  });
};
