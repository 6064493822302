import styles from "./ProjectTimers.module.scss";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import ProfilIcon from "ui/icons/ProfilIcon";
import PlanningIcon from "ui/icons/PlanningIcon";
import History from "clients/components/history/History";
import Dropdown from "app/components/dropdown/Dropdown";
import { useState, useEffect, useCallback, useMemo } from "react";
import { SlidersHorizontal, XCircle } from "@phosphor-icons/react";
import { useParams } from "react-router-dom";
import { handleGetTimersTeam } from "timer/controllers/timer";
import { TimerType } from "timer/types";
import { Mission } from "clients/interfaces/mission";
import MissionsDropdown from "./missions-dropdown/MissionsDropdown";
import { UserType } from "users/types";
import UsersDropdown from "./users-dropdown/UsersDropdown";
import { formatDateToDay, formatToFullDate } from "utils/formatDate";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import { secondsToHours } from "utils/formatTime";

const ProjectTimers = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { width } = useWindowSize();
  const [missionsDropdownOpen, setMissionsDropdownOpen] = useState(false);
  const [missionSelected, setMissionsSelected] = useState<Mission | null>(null);
  const [personsDropdownOpen, setPersonsDropdownOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<UserType | null>(null);
  const [calendarDropdownOpen, setCalendarDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [timers, setTimers] = useState<TimerType[]>([]);

  const handleMissionDropdownOpen = () => {
    setMissionsDropdownOpen(true);
  };

  const handlePersonsDropdownOpen = () => {
    setPersonsDropdownOpen(true);
  };

  const handleCalendarDropdownOpen = () => {
    setCalendarDropdownOpen(true);
  };

  const onMissionClick = (mission: Mission) => {
    setMissionsSelected(mission);
    setMissionsDropdownOpen(false);
  };

  const resetMissionFilter = () => {
    setMissionsSelected(null);
  };

  const onUserClick = (user: UserType) => {
    setUserSelected(user);
    setPersonsDropdownOpen(false);
  };

  const resetUserFilter = () => {
    setUserSelected(null);
  };

  const resetDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const renderDateButton = useCallback(() => {
    if (startDate && endDate) {
      return formatToFullDate(startDate) + " au " + formatToFullDate(endDate);
    } else if (startDate && !endDate) {
      return formatToFullDate(startDate);
    } else {
      return "Depuis le début";
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const formData = {
      projectId: id,
      missionId: missionSelected && missionSelected.id,
      userId: userSelected && userSelected.id,
      startDate: startDate && formatDateToDay(startDate),
      endDate: endDate && formatDateToDay(endDate),
    };
    handleGetTimersTeam(formData)
      .then((res) => {
        setTimers(res.data);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [endDate, id, missionSelected, startDate, userSelected, dispatch]);

  const renderTotalTime = useMemo(() => {
    const totalDuration = timers.reduce((accumulator, timer) => {
      return accumulator + (timer.duration || 0);
    }, 0);
    return (
      <div className={styles.totalTime}>
        Total : {totalDuration && secondsToHours(totalDuration)}
      </div>
    );
  }, [timers]);

  return (
    <div className={styles.history}>
      <div className={styles.headerWrapper}>
        <h2>Historique du projet</h2>
        <div className={styles.historyHeader}>
          <div className={styles.missionsDropdown}>
            <button
              onClick={handleMissionDropdownOpen}
              className={`m-button ${
                missionSelected?.name
                  ? "m-button--white " + styles.filterSelected
                  : "m-button--grey"
              }`}
            >
              <SlidersHorizontal weight="bold" />
              {missionSelected?.name || "Missions"}
            </button>
            {missionSelected?.name && (
              <button
                onClick={resetMissionFilter}
                className={styles.resetMissionFilter}
              >
                <span className="sr-only">Enlever le filtre de mission</span>
                <XCircle weight="fill" />
              </button>
            )}
            {missionsDropdownOpen && (
              <div className={styles.missionsDropdownContent}>
                <Dropdown setIsOpen={setMissionsDropdownOpen}>
                  <MissionsDropdown onClick={onMissionClick} />
                </Dropdown>
              </div>
            )}
          </div>
          <div className={styles.personsDropdown}>
            <button
              onClick={handlePersonsDropdownOpen}
              className={`m-button ${
                userSelected?.email
                  ? "m-button--white " + styles.filterSelected
                  : "m-button--grey"
              }`}
            >
              <ProfilIcon />
              {userSelected?.team_user_link?.username ||
                userSelected?.team_user_link?.user_email ||
                "Tout le monde"}
            </button>
            {userSelected?.email && (
              <button
                onClick={resetUserFilter}
                className={styles.resetMissionFilter}
              >
                <span className="sr-only">Enlever le filtre d'utilisateur</span>
                <XCircle weight="fill" />
              </button>
            )}
            {personsDropdownOpen && (
              <div className={styles.missionsDropdownContent}>
                <Dropdown setIsOpen={setPersonsDropdownOpen}>
                  <UsersDropdown onClick={onUserClick} />
                </Dropdown>
              </div>
            )}
          </div>
          <div className={styles.calendarDropdown}>
            <button
              onClick={handleCalendarDropdownOpen}
              className={`m-button ${
                startDate
                  ? "m-button--white " + styles.filterSelected
                  : "m-button--grey"
              }`}
            >
              <PlanningIcon />
              <p className={styles.dateText}>{renderDateButton()}</p>
            </button>
            {startDate && (
              <button
                onClick={resetDateFilter}
                className={styles.resetMissionFilter}
              >
                <span className="sr-only">Enlever le filtre de date</span>
                <XCircle weight="fill" />
              </button>
            )}
            {calendarDropdownOpen && (
              <div className={styles.calendarDropdownContent}>
                <Dropdown setIsOpen={setCalendarDropdownOpen}>
                  <CalendarDouble
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setIsOpen={setCalendarDropdownOpen}
                  />
                </Dropdown>
              </div>
            )}
          </div>
          {renderTotalTime}
        </div>
        {renderTotalTime}
      </div>

      {timers.length > 0 ? (
        <ul className={styles.historyList}>
          {timers.map((timer) => (
            <li key={timer.id}>
              <History timer={timer} />
            </li>
          ))}
        </ul>
      ) : (
        <p className={styles.noTimer}>
          Aucun timer n'est disponible pour ces filtres
        </p>
      )}
    </div>
  );
};

export default ProjectTimers;
