import { CaretDown, PencilSimple, User, X } from "@phosphor-icons/react";
import styles from "./Member.module.scss";
import { useMemo, useState, useEffect } from "react";
import SidePopup from "ui/components/side-popup/SidePopup";
import { useSelector } from "react-redux";
import { handleUpdateTeamUserLink } from "users/controllers/users";
import Dropdown from "app/components/dropdown/Dropdown";
import { CreateMediaInterface } from "profil/interfaces/media";
import { handleCreateMedia } from "app/controllers/media";
import MediaProfil from "app/components/media-profil/MediaProfil";
import { useDispatch } from "react-redux";
import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import { Archive } from "phosphor-react";

type Props = {
  userProps: any;
  setModifyUsers: (value: boolean) => void;
};

const Member = ({ userProps, setModifyUsers }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const [media, setMedia] = useState<CreateMediaInterface | null>(null);

  const handleOpenEditPopup = () => {
    setEditPopupOpen(true);
  };

  const openRoleDropdown = () => {
    setRoleDropdownOpen(true);
  };

  const handleRoleChange = (e: any) => {
    const data = {
      role_name: e.target.id,
    };
    handleUpdateTeamUserLink(userProps.team_user_link.id, data)
      .then((res) => {
        setModifyUsers(true);
        setRoleDropdownOpen(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  };

  const handleCreateMediaProfil = async (media: CreateMediaInterface) => {
    if (media) {
      return handleCreateMedia(media)
        .then((res: any) => {
          return res.data;
        })
        .catch((err: any) => {
          console.log(err);
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
        });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let mediaData = null;
    if (media) {
      mediaData = await handleCreateMediaProfil(media);
      console.log(mediaData);
    }
    const data: any = {
      username: e.target.username.value,
      job: e.target.job.value,
    };
    if (mediaData) {
      data.media_id = mediaData.id;
    }
    handleUpdateTeamUserLink(userProps.team_user_link.id, data)
      .then((res) => {
        setEditPopupOpen(false);
        setModifyUsers(true);
        const toastData = {
          status: true,
          message: "Le profil a bien été modifié",
          error: false,
        };
        dispatch(showModal(toastData));
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err.response.data.message;
        const toastData = {
          status: true,
          message: errorsAPI[errorMessage as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  };

  const renderProfilPicture = useMemo(() => {
    if (userProps?.team_user_link?.media?.url) {
      return (
        <div className={styles.picture}>
          <img
            src={`${process.env.REACT_APP_API}${userProps.team_user_link.media.url}`}
            alt=""
          />
        </div>
      );
    } else {
      return (
        <div className={styles.profilSvg}>
          <User />
        </div>
      );
    }
  }, [userProps?.team_user_link?.media?.url]);

  useEffect(() => {
    if (!editPopupOpen) {
      setMedia(null);
    }
  }, [editPopupOpen]);

  const handleClose = () => {
    setEditPopupOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        {renderProfilPicture}
        <div className={styles.userName}>
          <p className={styles.name}>
            {userProps.firstname} {userProps.lastname}
          </p>
          <p className={styles.item}>
            {userProps.team_user_link?.username || "-"}
          </p>
        </div>
      </div>

      <div className={styles.userInfos}>
        <div className={styles.info}>
          <label className={styles.label}>Métier</label>
          <p className={styles.item}>{userProps.team_user_link?.job || "-"}</p>
        </div>
        <div className={styles.info}>
          <label className={styles.label}>Adresse mail</label>
          <p className={styles.item}>{userProps.email}</p>
        </div>
        <div className={styles.info}>
          {user?.team_user_link?.role_name === "SUPER_ADMIN" && (
            <>
              <label className={styles.label}>Rôle</label>
              <div className={styles.role}>
                <button
                  className={styles.roleButton}
                  onClick={openRoleDropdown}
                >
                  {userProps.team_user_link?.role_name === "USER"
                    ? "Membre"
                    : "Administrateur"}
                  <CaretDown weight="bold" />
                </button>
                {roleDropdownOpen && (
                  <div className={styles.roleDropdown}>
                    <Dropdown setIsOpen={setRoleDropdownOpen}>
                      <form onChange={handleRoleChange}>
                        <div>
                          <input
                            type="radio"
                            name="role"
                            id="USER"
                            defaultChecked={
                              userProps.team_user_link.role_name === "USER"
                            }
                          />
                          <label htmlFor="USER">Membre</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="role"
                            id="SUPER_ADMIN"
                            defaultChecked={
                              userProps.team_user_link.role_name ===
                              "SUPER_ADMIN"
                            }
                          />
                          <label htmlFor="SUPER_ADMIN">Administrateur</label>
                        </div>
                      </form>
                    </Dropdown>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {user?.team_user_link?.role_name === "SUPER_ADMIN" && (
        <button className={styles.edit} onClick={handleOpenEditPopup}>
          <span className="sr-only">Modifier l'utilisateur</span>
          <PencilSimple weight="fill" />
        </button>
      )}
      {editPopupOpen && (
        <SidePopup setIsOpen={setEditPopupOpen} dataLoaded={true}>
          <div className={styles.wrapperPopup}>
            <form onSubmit={handleSubmit}>
              <div className={styles.headPopup}>
                <h2 className={styles.popupTitle}>Modifier les informations</h2>
                <div className={styles.buttons}>
                  <button
                    data-cy="submit-create-customer"
                    type="submit"
                    className="m-button m-button--black"
                  >
                    Enregistrer
                  </button>
                  <button
                    className="m-button m-button--grey"
                    type="button"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Fermer l'ajout de mission</span>
                    <span className={styles.cancel}>Annuler</span>
                    <X weight="bold" />
                  </button>
                </div>
              </div>
              <MediaProfil
                setMedia={setMedia}
                oldMedia={userProps?.team_user_link?.media?.url}
                media={media}
              />
              <div className={styles.inputFlex}>
                <div>
                  <label htmlFor="firstname" className="m-label">
                    Prénom<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="m-input m-input--grey"
                    id="firstname"
                    defaultValue={userProps.firstname}
                    disabled
                  />
                </div>
                <div>
                  <label htmlFor="lastname" className="m-label">
                    Nom<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="m-input m-input--grey"
                    id="lastname"
                    defaultValue={userProps.lastname}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.input}>
                <label htmlFor="username" className="m-label">
                  Nom d’affichage
                </label>
                <input
                  type="text"
                  className="m-input m-input--grey"
                  id="username"
                  defaultValue={userProps.team_user_link?.username || ""}
                />
              </div>
              <div className={styles.input}>
                <label htmlFor="job" className="m-label">
                  Métier
                </label>
                <input
                  type="text"
                  className="m-input m-input--grey"
                  id="job"
                  defaultValue={userProps.team_user_link?.job || ""}
                />
              </div>
              <div className={styles.input}>
                <label htmlFor="email" className="m-label">
                  Adresse email<span>*</span>
                </label>
                <input
                  type="text"
                  className="m-input m-input--grey"
                  id="email"
                  defaultValue={userProps.email}
                  disabled
                />
              </div>
              {user?.team_user_link?.role_name === "SUPER_ADMIN" && (
                <div className={styles.input}>
                  <label htmlFor="role" className="m-label">
                    Rôle
                  </label>
                  <div className={"m-select"}>
                    <select id="role">
                      <option
                        selected={
                          userProps.team_user_link?.role_name === "USER"
                        }
                        value={"USER"}
                      >
                        Membre
                      </option>
                      <option
                        selected={
                          userProps.team_user_link?.role_name === "SUPER_ADMIN"
                        }
                        value={"SUPER_ADMIN"}
                      >
                        Administrateur
                      </option>
                    </select>
                    <span className={"m-select__arrow"}>
                      <CaretDown />
                    </span>
                  </div>
                </div>
              )}
              <div className={styles.archive}>
                <button type="button">
                  <Archive weight="fill" />
                  Archiver l'utilisateur
                </button>
              </div>
            </form>
          </div>
        </SidePopup>
      )}
    </div>
  );
};

export default Member;
