import styles from "./MotDePasse.module.scss";
import { handleEditProfil } from "account/controllers/users";
import { useDispatch } from "react-redux";
import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import { useForm } from "react-hook-form";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import { useState } from "react";

const MotDePasse = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const [passwordShownActual, setPasswordShownActual] = useState(false);
  const [passwordShownNew, setPasswordShownNew] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);

  const { register, handleSubmit, watch, setValue } = methods;

  const handleChangePassword = (data: any) => {
    handleEditProfil(data)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Mot de passe modifié avec succès",
          error: false,
        };
        dispatch(showModal(data));
        setValue("old_password", "");
        setValue("password", "");
        setValue("password_confirm", "");
      })
      .catch((err) => {
        const data = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(data));
      });
  };

  const togglePasswordActual = () => {
    setPasswordShownActual(!passwordShownActual);
  };
  const togglePasswordNew = () => {
    setPasswordShownNew(!passwordShownNew);
  };
  const togglePasswordConfirm = () => {
    setPasswordShownConfirm(!passwordShownConfirm);
  };

  return (
    <>
      <h2 className={styles.subtitle}>Modifier votre mot de passe</h2>
      <div className={styles.wrapper}>
        <form
          onSubmit={handleSubmit(handleChangePassword)}
          className={styles.passwordForm}
        >
          <div className={styles.input}>
            <label className="m-label">
              Mot de passe actuel<span>*</span>
            </label>
            <div className={styles.passwordInput}>
              <input
                placeholder="Mot de passe actuel"
                type={passwordShownActual ? "text" : "password"}
                className="m-input m-input--grey"
                {...register("old_password", {
                  required: true,
                })}
              />
              <button type="button" onClick={togglePasswordActual}>
                {!passwordShownActual ? (
                  <Eye size={20} weight="fill" />
                ) : (
                  <EyeSlash size={20} weight="fill" />
                )}
              </button>
            </div>
          </div>
          <div className={styles.input}>
            <label className="m-label">
              Nouveau mot de passe<span>*</span>
            </label>
            <div className={styles.passwordInput}>
              <input
                placeholder="Nouveau mot de passe"
                type={passwordShownNew ? "text" : "password"}
                className="m-input m-input--grey"
                {...register("password", {
                  required: true,
                })}
              />
              <button type="button" onClick={togglePasswordNew}>
                {!passwordShownNew ? (
                  <Eye size={20} weight="fill" />
                ) : (
                  <EyeSlash size={20} weight="fill" />
                )}
              </button>
            </div>
          </div>
          <div className={styles.input}>
            <label className="m-label">
              Confirmer le nouveau mot de passe<span>*</span>
            </label>
            <div className={styles.passwordInput}>
              <input
                placeholder="Nouveau mot de passe"
                type={passwordShownConfirm ? "text" : "password"}
                className="m-input m-input--grey"
                {...register("password_confirm", {
                  required: true,
                  validate: (val: string) => {
                    if (watch("password") !== val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
              />
              <button type="button" onClick={togglePasswordConfirm}>
                {!passwordShownConfirm ? (
                  <Eye size={20} weight="fill" />
                ) : (
                  <EyeSlash size={20} weight="fill" />
                )}
              </button>
            </div>
          </div>
          <div className={styles.btnSave}>
            <button type="submit" className="m-button m-button--black">
              Enregister
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default MotDePasse;
