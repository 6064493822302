import PartyIcon from "ui/icons/PartyIcon";
import SprintIcon from "ui/icons/SprintIcon";
import PlanificationIcon from "ui/icons/PlanificationIcon";
import styles from "./EndSprint.module.scss";
import { X } from "@phosphor-icons/react";

type Props = {
  points: number;
  totalPoints: number;
  stories: number;
};

const EndSprint = ({ points, totalPoints, stories }: Props) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={`m-button m-button--grey m-button--ratio ${styles.close}`}
      >
        <X weight="bold" />
      </button>
      <div className={styles.icon}>
        <PartyIcon />
      </div>
      <p className={styles.title}>
        Wouhouuu !<br /> Le sprint est terminé
      </p>
      <div className={styles.stats}>
        <div className={styles.points}>
          <div className={styles.iconWrapper}>
            <SprintIcon />
          </div>
          <p className={styles.number}>{points}</p>
          <p className={styles.done}>points réalisés</p>
          <p className={styles.total}>(sur {totalPoints} planifiés)</p>
        </div>
        <div className={styles.points}>
          <div className={styles.iconWrapper}>
            <PlanificationIcon />
          </div>
          <p className={styles.number}>{stories}</p>
          <p className={styles.done}>stories terminées</p>
        </div>
      </div>
      <div className={styles.button}>
        <button className="m-button m-button--black">
          Lancer un nouveau sprint
        </button>
      </div>
    </div>
  );
};

export default EndSprint;
