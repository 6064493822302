import Dropdown from "app/components/dropdown/Dropdown";
import styles from "./MissionsTimer.module.scss";
import { useState, useEffect } from "react";
import { Mission } from "clients/interfaces/mission";
import useDebounce from "hooks/useDebounce";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import { handleGetMissionSuggestions } from "timer/controllers/timer";
import { Plus } from "@phosphor-icons/react";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import { useContext } from "react";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

const MissionsTimer = ({ setIsMenuOpen }: Props) => {
  const dispatch = useDispatch();
  const { currentTimer, setCurrentTimer } = useContext(CurrentTimerContext);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [canSearch, setCanSearch] = useState(true);
  const [suggestions, setSuggestions] = useState<Mission[]>();

  const debouncedSearchValue = useDebounce(inputValue, 300);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const selectSuggestion = (suggestion: Mission) => {
    setCurrentTimer({
      ...currentTimer,
      mission_ids: currentTimer?.missions
        ? [...currentTimer?.missions.map((sm) => sm.id), suggestion.id]
        : [suggestion.id],
      missions: currentTimer?.missions
        ? [...currentTimer?.missions, suggestion]
        : [suggestion],
    });
    setIsOpen(false);
    setCanSearch(false);
    setInputValue("");
  };

  const handleRemoveMission = (suggestionId: number) => {
    if (currentTimer?.missions) {
      setCurrentTimer({
        ...currentTimer,
        mission_ids: currentTimer.missions
          .filter((mission) => mission.id !== suggestionId)
          .map((mission) => mission.id),
        missions: currentTimer.missions.filter(
          (mission) => mission.id !== suggestionId
        ),
      });
      if (currentTimer?.missions?.length === 1) {
        setCanSearch(true);
      }
    }
  };

  const renderSuggestion = (suggestion: Mission, canDelete: boolean) => {
    return (
      <ProjectTag
        content={suggestion.name}
        contentColor={suggestion.color}
        mainTimer={true}
        size="small"
        key={`${suggestion.name}-${suggestion.id}`}
        onDelete={
          canDelete ? () => handleRemoveMission(suggestion.id) : undefined
        }
      />
    );
  };

  const addMission = () => {
    setCanSearch(true);
  };

  useEffect(() => {
    if (isOpen) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [isOpen, setIsMenuOpen]);

  useEffect(() => {
    if (!isOpen) return;
    const selectedMissionsIds = currentTimer?.missions?.map(
      (mission) => mission.id
    ) as number[];

    const filters = {
      search: debouncedSearchValue,
      exclude: selectedMissionsIds?.join(","),
      projectId: currentTimer?.project_id,
    };

    console.log(filters);

    handleGetMissionSuggestions(filters)
      .then((res) => {
        setSuggestions(res.data);
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [
    debouncedSearchValue,
    isOpen,
    currentTimer?.missions,
    dispatch,
    currentTimer?.project_id,
  ]);

  useEffect(() => {
    if (
      currentTimer &&
      currentTimer.missions &&
      currentTimer.missions.length > 0
    ) {
      setCanSearch(false);
    } else {
      setCanSearch(true);
    }
  }, [currentTimer]);

  return (
    <>
      {currentTimer?.missions && currentTimer?.missions.length > 0 && (
        <div className={styles.selectedMissions}>
          {currentTimer?.missions.map((mission) =>
            renderSuggestion(mission, true)
          )}
          {!canSearch && (
            <button className={styles.addMission} onClick={addMission}>
              <Plus weight="bold" />
              <span className="sr-only">Ouvrir les mission</span>
            </button>
          )}
        </div>
      )}
      {canSearch && (
        <input
          className={styles.input}
          type="text"
          placeholder="Mission(s)"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
        />
      )}
      {isOpen && (
        <div className={styles.container}>
          <Dropdown setIsOpen={setIsOpen} padding={false} color={false}>
            <div className={styles.suggestionsContainer}>
              <p className={styles.suggestions}>
                {inputValue === "" ? "Suggérés" : "Recherche"}
              </p>
              <div className={styles.tags}>
                {suggestions?.map((suggestion) => (
                  <button
                    key={`${suggestion.name}-${suggestion.id}`}
                    onClick={() => selectSuggestion(suggestion)}
                  >
                    {renderSuggestion(suggestion, false)}
                  </button>
                ))}
              </div>
            </div>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default MissionsTimer;
