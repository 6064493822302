import { ArrowRight, CalendarBlank, X } from "@phosphor-icons/react";
import styles from "./CreateSprintPopup.module.scss";
import { useState, useContext } from "react";
import Popup from "ui/components/popup/Popup";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import { handleCreateSprint } from "sprint/controllers";
import { EditingSprintsContext } from "planification/context/EditingSprintsProvider";
import { EditingCurrentSprintContext } from "sprint/contexts/SprintContext";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

type Props = {
  handleCloseCreateSprintPopup: () => void;
  setUpdateSprints?: (value: boolean) => void;
  createActive?: boolean;
};

const CreateSprintPopup = ({
  handleCloseCreateSprintPopup,
  createActive = false,
}: Props) => {
  const dispatch = useDispatch();
  const { setEditingCurrentSprint } = useContext(EditingCurrentSprintContext);
  const { setEditingSprints } = useContext(EditingSprintsContext);

  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [nameError, setNameError] = useState<string>("");
  const [startDateError, setStartDateError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");

  const humanStartDate = startDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const humanEndDate = endDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const handleCalendarOpen = () => {
    setShowCalendar(true);
    setStartDateError("");
    setEndDateError("");
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameError("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const form = e.currentTarget; // Get the form element
      const nameInput = form.elements.namedItem("name") as HTMLInputElement;

      if (!startDate) {
        setStartDateError("La date de début est requise.");
      }

      if (!endDate) {
        setEndDateError("La date de fin est requise.");
      }

      if (!nameInput.value || nameInput.value === "") {
        setNameError("Le nom du sprint est requis.");
      }

      if (!startDate || !endDate || !nameInput.value || nameInput.value === "")
        return;

      const nextDayStart = new Date(startDate);
      nextDayStart.setDate(nextDayStart.getDate() + 1);

      const nextDayEnd = new Date(endDate);
      nextDayEnd.setDate(nextDayEnd.getDate() + 1);

      const sprint = {
        start_date: nextDayStart.toISOString().substring(0, 10),
        end_date: nextDayEnd.toISOString().substring(0, 10),
        name: nameInput.value,
        active: createActive ? 1 : 2,
      };
      await handleCreateSprint(sprint);
      setEditingSprints(true);
      setEditingCurrentSprint(true);
      handleCloseCreateSprintPopup();
    } catch (err: any) {
      console.log(err);
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="m-popup__header">
        <p>Créer un sprint</p>
        <button
          type="button"
          className="m-popup__header__close"
          onClick={handleCloseCreateSprintPopup}
        >
          <X weight="bold" />
        </button>
      </div>
      <div className="m-popup__content">
        <div className={styles.input}>
          <p className="m-label">
            Période du sprint <span>*</span>
          </p>
          <button
            className={styles.selectDate}
            onClick={handleCalendarOpen}
            type="button"
          >
            <CalendarBlank weight="fill" />
            <p className={styles.startDate}>
              {humanStartDate || "Choisir une date"}
            </p>
            <ArrowRight />
            <p className={styles.endDate}>
              {humanEndDate || "Choisir une date"}
            </p>
          </button>
          {showCalendar && (
            <Popup setIsOpen={setShowCalendar} width="648px">
              <div className={styles.calendar}>
                <CalendarDouble
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setIsOpen={setShowCalendar}
                />
              </div>
            </Popup>
          )}
          {startDateError && <p className="m-error">{startDateError}</p>}
          {endDateError && <p className="m-error">{endDateError}</p>}
        </div>
        <div className={styles.input}>
          <label htmlFor="name" className="m-label">
            Nom du sprint <span>*</span>
          </label>
          <input
            type="text"
            className="m-input m-input--grey"
            placeholder="Nom du sprint"
            name="name"
            id="name"
            onChange={handleNameChange}
          />
          {nameError && <p className="m-error">{nameError}</p>}
        </div>
        <div className={styles.buttons}>
          <button type="submit" className="m-button m-button--black">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateSprintPopup;
