import { createContext, useState } from "react";

type EditingCurrentSprintContextType = {
  editingCurrentSprint: boolean;
  setEditingCurrentSprint: (value: boolean) => void;
};

export const EditingCurrentSprintContext =
  createContext<EditingCurrentSprintContextType>({
    editingCurrentSprint: false,
    setEditingCurrentSprint: () => {},
  });

type Props = {
  children: React.ReactNode;
};

export const EditingCurrentSprintProvider = ({ children }: Props) => {
  const [editingCurrentSprint, setEditingCurrentSprint] = useState(false);

  return (
    <EditingCurrentSprintContext.Provider
      value={{
        editingCurrentSprint,
        setEditingCurrentSprint,
      }}
    >
      {children}
    </EditingCurrentSprintContext.Provider>
  );
};
