import styles from "./Statistiques.module.scss";
import { ArrowRight, XCircle } from "@phosphor-icons/react";
import HeaderColor from "ui/components/header-color/HeaderColor";
import { useEffect, useState } from "react";
import PlanningIcon from "ui/icons/PlanningIcon";
import MissionsStatistics from "statistics/components/missions-statistics/MissionsStatistics";
import ProjectsStatistics from "statistics/components/projects-statistics/ProjectsStatistics";
import CustomersStatistics from "statistics/components/customers-statistics/CustomersStatistics";
import { UserType } from "users/types";
import ProfilIcon from "ui/icons/ProfilIcon";
import Dropdown from "app/components/dropdown/Dropdown";
import UsersDropdown from "clients/components/project-timers/users-dropdown/UsersDropdown";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import { X } from "phosphor-react";

const Statistiques = () => {
  const [active, setActive] = useState("missions");
  const [personsDropdownOpen, setPersonsDropdownOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<UserType | null>(null);
  const [calendarDropdownOpen, setCalendarDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handlePersonsDropdownOpen = () => {
    setPersonsDropdownOpen(true);
  };

  const handleCalendarDropdownOpen = () => {
    setCalendarDropdownOpen(true);
  };

  const onUserClick = (user: UserType) => {
    setUserSelected(user);
    setPersonsDropdownOpen(false);
  };

  const resetUserFilter = () => {
    setUserSelected(null);
  };

  const resetDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const humanStartDate = startDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const humanEndDate = endDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const changePage = (newActive: string) => {
    setActive(newActive);
  };

  useEffect(() => {
    if (calendarDropdownOpen === false && !endDate) {
      setEndDate(startDate);
    }
  }, [startDate, endDate, calendarDropdownOpen]);

  const renderLinks = () => {
    if (active === "missions") {
      return (
        <>
          <p className={styles.activePage}>Missions</p>
          <button
            onClick={() => changePage("projects")}
            className={styles.inactivePage}
          >
            Projets
          </button>
          <button
            onClick={() => changePage("customers")}
            className={styles.inactivePage}
          >
            Clients
          </button>
        </>
      );
    } else if (active === "projects") {
      return (
        <>
          <button
            onClick={() => changePage("missions")}
            className={styles.inactivePage}
          >
            Missions
          </button>
          <p className={styles.activePage}>Projets</p>
          <button
            onClick={() => changePage("customers")}
            className={styles.inactivePage}
          >
            Clients
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            onClick={() => changePage("missions")}
            className={styles.inactivePage}
          >
            Missions
          </button>
          <button
            onClick={() => changePage("projects")}
            className={styles.inactivePage}
          >
            Projets
          </button>
          <p className={styles.activePage}>Clients</p>
        </>
      );
    }
  };

  const renderContent = () => {
    if (active === "missions") {
      return (
        <MissionsStatistics
          startDate={startDate}
          endDate={endDate}
          user_id={userSelected?.id}
        />
      );
    } else if (active === "projects") {
      return (
        <ProjectsStatistics
          startDate={startDate}
          endDate={endDate}
          user_id={userSelected?.id}
        />
      );
    } else {
      return (
        <CustomersStatistics
          startDate={startDate}
          endDate={endDate}
          user_id={userSelected?.id}
        />
      );
    }
  };

  return (
    <div className="layout-container">
      <HeaderColor color="#151419">
        <div className={styles.headerContainer}>
          <div className={styles.headWrapper}>
            <h1 className={styles.title}>Statistiques</h1>
            <div className={styles.dropdownContent}>
              <div className={styles.calendarDropdown}>
                <div className={startDate ? styles.date : styles.noDate}>
                  <button
                    className={styles.selectDate}
                    onClick={handleCalendarDropdownOpen}
                    type="button"
                  >
                    <PlanningIcon />
                    <p className={humanStartDate ? styles.startDate : ""}>
                      {humanStartDate || "Choisir une date"}
                    </p>
                    {startDate && endDate && (
                      <>
                        <ArrowRight />
                        <p className={styles.endDate}>
                          {humanEndDate || "Choisir une date"}
                        </p>
                      </>
                    )}
                  </button>
                  {startDate && (
                    <button
                      className={styles.resetFilters}
                      onClick={resetDateFilter}
                    >
                      <X weight="bold" />
                    </button>
                  )}
                </div>
                {calendarDropdownOpen && (
                  <div className={styles.calendarDropdownContent}>
                    <Dropdown
                      setIsOpen={setCalendarDropdownOpen}
                      padding={false}
                    >
                      <CalendarDouble
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsOpen={setCalendarDropdownOpen}
                      />
                    </Dropdown>
                  </div>
                )}
              </div>
              <div className={styles.personsDropdown}>
                <div className={styles.persons}>
                  <button
                    onClick={handlePersonsDropdownOpen}
                    className={styles.selectPerson}
                    style={userSelected ? { padding: "0 38px 0 16px" } : {}}
                  >
                    <ProfilIcon />
                    <p>
                      {userSelected?.team_user_link?.username ||
                        "Tout le monde"}
                    </p>
                  </button>
                  {userSelected?.email && (
                    <button
                      onClick={resetUserFilter}
                      className={styles.resetMissionFilter}
                    >
                      <span className="sr-only">
                        Enlever le filtre d'utilisateur
                      </span>
                      <XCircle weight="fill" />
                    </button>
                  )}
                </div>
                {personsDropdownOpen && (
                  <div className={styles.personsDropdownContent}>
                    <Dropdown setIsOpen={setPersonsDropdownOpen}>
                      <UsersDropdown onClick={onUserClick} />
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.subpages}>{renderLinks()}</div>
        </div>
      </HeaderColor>
      <div className="container">{renderContent()}</div>
    </div>
  );
};

export default Statistiques;
