import { X } from "phosphor-react";

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  closePopup: () => void;
  edit?: boolean;
};

const CancelStoryPopup = ({ setIsOpen, closePopup, edit }: Props) => {
  return (
    <>
      <div className="m-popup__header">
        <p className="m-popup__header__title">
          {edit
            ? "Annuler la modification de la story"
            : "Annuler la création de la story"}
        </p>
        <button
          className="m-popup__header__close"
          onClick={() => setIsOpen(false)}
          type="button"
        >
          <X weight="bold" />
        </button>
      </div>
      <div className="m-popup__content">
        <p className="m-popup__content__text-grey">
          {edit
            ? "Confirmez-vous l'annulation de la modification de la story ?"
            : "Confirmez-vous l'annulation de la création de la story ?"}
        </p>
        <button
          type="button"
          onClick={closePopup}
          className="m-button m-button--black"
        >
          {edit
            ? "Annuler la modification de la story"
            : "Annuler la création de la story"}
        </button>
      </div>
    </>
  );
};

export default CancelStoryPopup;
