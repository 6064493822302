import styles from "./ProjectsList.module.scss";
import { Link } from "react-router-dom";
import CardTime from "app/components/card-time/CardTime";
import { Project } from "clients/interfaces/project";
import AddProject from "clients/components/add-project/AddProject";
import SidePopup from "ui/components/side-popup/SidePopup";

type Props = {
  projects: Project[];
  customerId: any;
  setCreatingProject: (value: boolean) => void;
  children: React.ReactNode;
  showCreateProject: boolean;
  setShowCreateProject: (value: boolean) => void;
  projectsVisibility: string;
};

const ProjectsList = ({
  projects,
  customerId,
  setCreatingProject,
  children,
  showCreateProject,
  setShowCreateProject,
  projectsVisibility,
}: Props) => {
  return (
    <>
      <div className={styles.header}>
        <h2>Projets du client</h2>
        {children}
      </div>
      {showCreateProject && (
        <SidePopup setIsOpen={setShowCreateProject} dataLoaded={true}>
          <AddProject
            customerId={customerId}
            setCreatingProject={setCreatingProject}
            setShowCreateProject={setShowCreateProject}
          />
        </SidePopup>
      )}
      {projects.length > 0 ? (
        <ul className={styles.list}>
          {projects.map((project) => (
            <li key={project.id}>
              <Link to={`/clients/${customerId}/projects/${project.id}`}>
                <CardTime project={project} />
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <>
          {projectsVisibility === "actives" ? (
            <p className={styles.empty}>
              Aucun projet n’a été créé pour ce client
            </p>
          ) : (
            <p className={styles.empty}>
              Aucun projet n’a été archivé pour ce client
            </p>
          )}
        </>
      )}
    </>
  );
};

export default ProjectsList;
