const PlanificationIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
      <g clipPath="url(#PlanificationIcon)">
        <path d="m1.316 10.385 12.25 7a.875.875 0 0 0 .868 0l12.25-7a.875.875 0 0 0 0-1.52l-12.25-7a.876.876 0 0 0-.868 0l-12.25 7a.875.875 0 0 0 0 1.52Z" />
        <path d="M25.816 13.24 14 19.992 2.184 13.24a.875.875 0 0 0-.868 1.52l12.25 7a.876.876 0 0 0 .868 0l12.25-7a.874.874 0 1 0-.868-1.52Z" />
        <path d="M25.816 17.615 14 24.367 2.184 17.615a.875.875 0 0 0-.868 1.52l12.25 7a.876.876 0 0 0 .868 0l12.25-7a.874.874 0 1 0-.868-1.52Z" />
      </g>
      <defs>
        <clipPath id="PlanificationIcon">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlanificationIcon;
