import { Plus } from "@phosphor-icons/react";
import styles from "./KanbanItem.module.scss";
import SidePopup from "ui/components/side-popup/SidePopup";
import CreateStory from "story/components/create-story/CreateStory";
import { useState, useMemo, useCallback } from "react";
import { StoryType } from "story/types";
import CardStory from "story/components/card-story/CardStory";
import { Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "app/components/strict-mode-droppable/StrictModeDroppable";
import CancelStoryPopup from "story/components/cancel-story-popup/CancelStoryPopup";
import Popup from "ui/components/popup/Popup";

type Props = {
  title: string;
  sprint_id: number;
  createStoryType: "todo" | "doing" | "review" | "done";
  currentSprintStories: StoryType[];
  isHovered: boolean;
  typeCard: string | null;
};

const KanbanItem = ({
  title,
  sprint_id,
  createStoryType,
  currentSprintStories,
  isHovered,
  typeCard,
}: Props) => {
  const [createStoryPopup, setCreateStoryPopup] = useState<boolean>(false);
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);

  const renderStories = useMemo(() => {
    return [...currentSprintStories].map((story: StoryType, index: number) => (
      <Draggable draggableId={story.id.toString()} index={index} key={story.id}>
        {(provided) => {
          return (
            <CardStory
              key={story.id}
              story={story}
              showPlay={true}
              sprint_id={sprint_id}
              type={createStoryType}
              provided={provided}
              typeCard={typeCard}
            />
          );
        }}
      </Draggable>
    ));
  }, [createStoryType, currentSprintStories, sprint_id, typeCard]);

  const sprintPoints = [...currentSprintStories].reduce(
    (acc: number, story: StoryType) => {
      if (story.points) return acc + story.points;
      return acc;
    },
    0
  );

  const showCreateStoryPopup = () => {
    setCreateStoryPopup(true);
  };

  const hideCreateStoryPopup = useCallback(() => {
    setCreateStoryPopup(false);
    setCancelPopup(false);
  }, []);

  const renderKanban = useMemo(() => {
    if (!createStoryPopup) return null;

    return (
      <SidePopup
        setIsOpen={setCreateStoryPopup}
        dataLoaded={true}
        onClickOutside={() => setCancelPopup(true)}
        modal={true}
      >
        <CreateStory
          sprint_id={sprint_id}
          type={createStoryType}
          closePopup={hideCreateStoryPopup}
          newOrder={currentSprintStories.length}
          modal={true}
        />
        {cancelPopup && (
          <Popup setIsOpen={setCancelPopup} width="496px">
            <CancelStoryPopup
              closePopup={hideCreateStoryPopup}
              setIsOpen={setCancelPopup}
            />
          </Popup>
        )}
      </SidePopup>
    );
  }, [
    currentSprintStories,
    createStoryType,
    cancelPopup,
    hideCreateStoryPopup,
    createStoryPopup,
    sprint_id,
  ]);

  return (
    <>
      <div className={`${styles.container} ${isHovered ? styles.hovered : ""}`}>
        <div className="m-top-points">
          <p className="m-top-points__text">{title}</p>
          <span className="m-points m-points--fill">{sprintPoints || 0}</span>
        </div>
        <StrictModeDroppable droppableId={createStoryType}>
          {(provided) => {
            return (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.stories}
              >
                {renderStories}
                {provided.placeholder}
              </ul>
            );
          }}
        </StrictModeDroppable>
        <button className={styles.addNew} onClick={showCreateStoryPopup}>
          <Plus weight="bold" />
          Ajouter une story
        </button>

        {renderKanban}
      </div>
    </>
  );
};

export default KanbanItem;
