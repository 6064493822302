import styles from "./Contact.module.scss";
import { CopySimple, PencilSimple, Trash, X } from "@phosphor-icons/react";
import { useState } from "react";
import { ContactInterface } from "clients/interfaces/contact";
import AddContact from "../add-contact/AddContact";
import SidePopup from "ui/components/side-popup/SidePopup";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { handleUpdateCustomer } from "clients/controllers/customers";
import Popup from "ui/components/popup/Popup";

type Props = {
  setEditClient: (value: boolean) => void;
  contacts: ContactInterface[];
  firstName: string;
  lastName: string;
  capacity: string;
  mail: string;
  phone?: string;
};

const Contact = ({
  setEditClient,
  contacts,
  firstName,
  lastName,
  capacity,
  mail,
  phone,
}: Props) => {
  const { id } = useParams();
  const user = useSelector((state: any) => state.user.user);
  const [editOpen, setEditOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [email, setEmail] = useState<string>(mail);
  const [emailCopied, setEmailCopied] = useState<Boolean>();

  const data = {
    firstName: firstName,
    lastName: lastName,
    function: capacity,
    email: mail,
    phone: phone,
  };

  const handleOpenEdit = () => {
    setEditOpen(true);
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
  };

  const deleteContact = () => {
    if (!id) return;
    const newContacts = [
      ...contacts.filter(
        (contact) =>
          contact.firstName !== firstName ||
          contact.lastName !== lastName ||
          contact.function !== capacity ||
          contact.email !== mail ||
          contact.phone !== phone
      ),
    ];
    handleUpdateCustomer(Number(id), {
      contacts: newContacts,
    });
    setOpenDelete(false);
    setEditClient(true);
  };

  const copyEmail = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        setEmail(email);
        setEmailCopied(true);
        setTimeout(() => {
          setEmailCopied(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Failed to copy email:", error);
      });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.cardRow}>
          <p className={styles.name}>
            {firstName} {lastName}
          </p>
          <div className={styles.endButtons}>
            {user?.team_user_link?.role_name === "SUPER_ADMIN" && (
              <button className={styles.editButton} onClick={handleOpenEdit}>
                <span className="sr-only">Editer le contact</span>
                <PencilSimple weight="fill" />
              </button>
            )}
            {user?.team_user_link?.role_name === "SUPER_ADMIN" && (
              <button className={styles.editButton} onClick={handleOpenDelete}>
                <span className="sr-only">Supprimer le contact</span>
                <Trash weight="fill" />
              </button>
            )}
          </div>
        </div>
        <div className={styles.cardRow}>
          <p className={styles.rowName}>Fonction</p>
          <p className={styles.capacity}>{capacity}</p>
        </div>
        <div className={styles.cardRow}>
          <p className={styles.rowName}>Email</p>
          <div className={styles.mail}>
            {mail}
            <div className={styles.mail__content}>
              {emailCopied && (
                <div className={styles.toolTip}>
                  <span>Email copié</span>
                </div>
              )}
              <button type="button" onClick={copyEmail}>
                <CopySimple weight="bold" />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.cardRow}>
          <p className={styles.rowName}>Téléphone</p>
          <p className={styles.phone}>{phone}</p>
        </div>

        {editOpen && (
          <SidePopup setIsOpen={setEditOpen} dataLoaded={true}>
            <AddContact
              contacts={contacts}
              setEditClient={setEditClient}
              closePopup={handleCloseEdit}
              edit={true}
              data={data}
            />
          </SidePopup>
        )}
        {openDelete && (
          <Popup setIsOpen={setOpenDelete} width="496px">
            <div className="m-popup__header">
              <p>Supprimer le contact</p>
              <button
                className="m-popup__header__close"
                onClick={handleCloseDeletePopup}
              >
                <X weight="bold" />
              </button>
            </div>
            <div className="m-popup__content">
              <p className="m-popup__content__text-grey">
                Confirmez-vous la suppression du contact ? Cette action est
                irréversible.
              </p>
              <button
                onClick={deleteContact}
                className="m-button m-button--black"
              >
                <Trash weight="fill" />
                Supprimer
              </button>
            </div>
          </Popup>
        )}
      </div>
    </>
  );
};

export default Contact;
