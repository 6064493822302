import {
  ArrowRight,
  CalendarBlank,
  ClockCounterClockwise,
  X,
} from "@phosphor-icons/react";
import styles from "./SprintSettingsPopup.module.scss";
import { Link } from "react-router-dom";
import Popup from "ui/components/popup/Popup";
import CalendarDouble from "app/components/calendar-double/CalendarDouble";
import { useState, useContext } from "react";
import { handleUpdateSprint } from "sprint/controllers";
import { useDispatch } from "react-redux";
import { showModal } from "app/actions/modal";
import { EditingSprintsContext } from "planification/context/EditingSprintsProvider";

type Props = {
  sprint_id: number;
  start_date?: string;
  end_date?: string;
  name?: string;
  handleClosePopup: () => void;
};

const SprintSettingsPopup = ({
  sprint_id,
  start_date,
  end_date,
  name,
  handleClosePopup,
}: Props) => {
  const dispatch = useDispatch();
  const { setEditingSprints } = useContext(EditingSprintsContext);
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(
    start_date ? new Date(start_date) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    end_date ? new Date(end_date) : null
  );
  const [nameError, setNameError] = useState<string>("");
  const [startDateError, setStartDateError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");

  const humanStartDate = startDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const humanEndDate = endDate?.toLocaleString("fr", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const handleCalendarOpen = () => {
    setShowCalendar(true);
    setStartDateError("");
    setEndDateError("");
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameError("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget; // Get the form element
    const nameInput = form.elements.namedItem("name") as HTMLInputElement;

    if (!startDate) {
      setStartDateError("La date de début est requise.");
    }

    if (!endDate) {
      setEndDateError("La date de fin est requise.");
    }

    if (!nameInput.value || nameInput.value === "") {
      setNameError("Le nom du sprint est requis.");
    }

    if (!startDate || !endDate || !nameInput.value || nameInput.value === "")
      return;

    const nextDayStart = new Date(startDate);
    nextDayStart.setDate(nextDayStart.getDate() + 1);

    const nextDayEnd = new Date(endDate);
    nextDayEnd.setDate(nextDayEnd.getDate() + 1);

    const sprint = {
      start_date: nextDayStart.toISOString().substring(0, 10),
      end_date: nextDayEnd.toISOString().substring(0, 10),
      name: nameInput.value,
    };
    await handleUpdateSprint(sprint_id, sprint);
    handleClosePopup();
    setEditingSprints(true);
    const toastData = {
      status: true,
      message: "Le sprint a bien été modifié",
      error: false,
    };
    dispatch(showModal(toastData));
  };

  return (
    <>
      <div className="m-popup__header">
        <p>Réglages du sprint</p>
        <button className="m-popup__header__close" onClick={handleClosePopup}>
          <X weight="bold" />
        </button>
      </div>
      <div className="m-popup__content">
        <form onSubmit={handleSubmit}>
          <div className={styles.input}>
            <p className="m-label">
              Période du sprint <span>*</span>
            </p>
            <button
              className={styles.selectDate}
              onClick={handleCalendarOpen}
              type="button"
            >
              <CalendarBlank weight="fill" />
              <p className={styles.startDate}>
                {humanStartDate || "Choisir une date"}
              </p>
              <ArrowRight />
              <p className={styles.endDate}>
                {humanEndDate || "Choisir une date"}
              </p>
            </button>
            {showCalendar && (
              <Popup setIsOpen={setShowCalendar} width="648px">
                <div className={styles.calendar}>
                  <CalendarDouble
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setIsOpen={setShowCalendar}
                  />
                </div>
              </Popup>
            )}
            {startDateError && <p className="m-error">{startDateError}</p>}
            {endDateError && <p className="m-error">{endDateError}</p>}
          </div>
          <div className={styles.input}>
            <label htmlFor="name" className="m-label">
              Nom du sprint <span>*</span>
            </label>
            <input
              type="text"
              className="m-input m-input--border"
              placeholder="Nom du sprint"
              name="name"
              id="name"
              defaultValue={name}
              onChange={handleNameChange}
            />
            {nameError && <p className="m-error">{nameError}</p>}
          </div>
          <div className={styles.buttons}>
            <button type="submit" className="m-button m-button--black">
              Enregistrer
            </button>
            <Link className="m-button m-button--grey" to="/sprint/historique">
              <ClockCounterClockwise weight="bold" />
              Voir l’historique des sprints
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default SprintSettingsPopup;
