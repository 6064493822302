import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  handleCreateTimer,
  handleGetCurrentTimer,
  handleUpdateTimer,
} from "timer/controllers/timer";
import { CreateTimer, TimerType } from "timer/types";
import { formatDay } from "utils/formatDate";

type CurrentTimerType = {
  currentTimer:
    | (Partial<TimerType> & Pick<CreateTimer, keyof CreateTimer>)
    | null;
  setCurrentTimer: (
    timer: (Partial<TimerType> & Pick<CreateTimer, keyof CreateTimer>) | null
  ) => void;
  timerOpen: boolean;
  setTimerOpen: (open: boolean) => void;
  createdTimer: boolean;
  setCreatedTimer: (created: boolean) => void;
  triggerTimer: (
    currentTimerData: Partial<TimerType> & Pick<CreateTimer, keyof CreateTimer>
  ) => void;
};

export const CurrentTimerContext = createContext<CurrentTimerType>({
  currentTimer: null,
  setCurrentTimer: () => {},
  timerOpen: false,
  setTimerOpen: () => {},
  createdTimer: false,
  setCreatedTimer: () => {},
  triggerTimer: async (
    currentTimerData: Partial<TimerType> & Pick<CreateTimer, keyof CreateTimer>
  ) => {},
});

type Props = {
  children: React.ReactNode;
};

export const CurrentTimerProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [currentTimer, setCurrentTimer] = useState<
    (Partial<TimerType> & Pick<CreateTimer, keyof CreateTimer>) | null
  >(null);
  const [timerOpen, setTimerOpen] = useState(false);
  const [createdTimer, setCreatedTimer] = useState<boolean>(false);

  const triggerTimer = async (
    currentTimerData: Partial<TimerType> & Pick<CreateTimer, keyof CreateTimer>
  ) => {
    if (!currentTimerData) return;
    if (currentTimerData?.end_at === null && currentTimerData?.id) {
      try {
        const data: Partial<CreateTimer> = {
          customer_id: currentTimerData?.customer_id,
          project_id: currentTimerData?.project_id,
          sub_task: currentTimerData?.sub_task,
          mission_ids: currentTimerData?.mission_ids,
          name: currentTimerData?.name,
          end_at: formatDay(new Date()),
          story_id: currentTimerData?.story_id,
        };

        await handleUpdateTimer(currentTimerData.id, data);
        setTimerOpen(false);
        const modalData = {
          status: true,
          message: `Le timer a bien été créé`,
          error: false,
        };
        dispatch(showModal(modalData));
        setCreatedTimer(true);
      } catch (err: any) {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      }
    } else {
      try {
        if (!currentTimerData?.name) return;
        const data: CreateTimer = {
          customer_id: currentTimerData?.customer_id,
          project_id: currentTimerData?.project_id,
          sub_task: currentTimerData?.sub_task,
          mission_ids: currentTimerData?.mission_ids || [],
          name: currentTimerData?.name,
          start_at: formatDay(new Date()),
          story_id: currentTimerData?.story_id,
        };

        await handleCreateTimer(data);
      } catch (err: any) {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      }
    }
    handleGetCurrentTimer()
      .then((res) => {
        if (res.data.start_at) {
          setCurrentTimer(res.data);
        } else {
          setCurrentTimer(null);
        }
      })
      .catch((err) => {
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  };

  return (
    <CurrentTimerContext.Provider
      value={{
        currentTimer,
        setCurrentTimer,
        timerOpen,
        setTimerOpen,
        createdTimer,
        setCreatedTimer,
        triggerTimer,
      }}
    >
      {children}
    </CurrentTimerContext.Provider>
  );
};
