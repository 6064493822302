import useFocusTrap from "hooks/useFocusTrap";
import styles from "./Popup.module.scss";
import { useEffect, useCallback, useRef } from "react";

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  readonly children: React.ReactNode;
  readonly className?: string;
  width?: string;
};

const Popup = ({ setIsOpen, children, className, width }: Props) => {
  const popupRef = useRef<HTMLDivElement | null>(null);

  useFocusTrap(popupRef, "Tab", true);

  // const handlePopupClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   e.nativeEvent.stopImmediatePropagation();
  //   if (e.target === e.currentTarget) {
  //     setIsOpen(false);
  //   }
  // };

  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    const popupElement = popupRef.current;
    if (popupElement) {
      popupElement.addEventListener("keydown", handleEscapeKey);
      const firstButton = popupElement.querySelector("button");
      if (firstButton) {
        firstButton.focus();
      }
      return () => {
        popupElement.removeEventListener("keydown", handleEscapeKey);
      };
    }
  }, [handleEscapeKey]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.popup} ref={popupRef}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
