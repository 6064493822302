import styles from "./Timer.module.scss";
import CtaIcon from "ui/components/cta-icon/CtaIcon";
import { Timer } from "@phosphor-icons/react";
import { TimerType } from "timer/types";
import { useContext, useEffect, useRef, useState } from "react";
import { handleGetTimers } from "timer/controllers/timer";
import AllTimers from "timer/components/all-timers/AllTimers";
import TimerFilters from "timer/components/timer-filters/TimerFilters";
import { formatDateToDay } from "utils/formatDate";
import { EditingTimerContext } from "timer/context/EditingTimerProvider";
import { CurrentTimerContext } from "timer/context/CurrentTimerProvider";
import LoadingIcon from "ui/icons/LoadingIcon";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";

const TimerPage = () => {
  const dispatch = useDispatch();
  const { editingTimer, setEditingTimer } = useContext(EditingTimerContext);
  const { createdTimer, setCreatedTimer } = useContext(CurrentTimerContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
  const [allTimers, setAllTimers] = useState<TimerType[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [defaultLoading, setDefaultLoading] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let filter = {};
    if (startDateFilter && endDateFilter) {
      filter = {
        startDate: formatDateToDay(startDateFilter),
        endDate: formatDateToDay(endDateFilter),
        page,
      };
    } else if (startDateFilter && !endDateFilter) {
      const endDate = new Date(startDateFilter);
      endDate.setDate(endDate.getDate() + 1);
      filter = {
        startDate: formatDateToDay(startDateFilter),
        endDate: formatDateToDay(endDate),
        page,
      };
    } else {
      filter = {
        startDate: "",
        endDate: "",
        page,
      };
    }

    handleGetTimers(filter)
      .then((res) => {
        if (page > 1) {
          setAllTimers((allTimers) => [...allTimers, ...res.data]);
        } else {
          setAllTimers(res.data);
        }
        if (res.data.length < 20) {
          setShowMore(false);
        } else {
          setShowMore(true);
        }
        setLoading(false);
        setDefaultLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const toastData = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(toastData));
      });
  }, [startDateFilter, endDateFilter, page, dispatch]);

  useEffect(() => {
    if (startDateFilter || endDateFilter) {
      setPage(1);
    }
  }, [startDateFilter, endDateFilter]);

  useEffect(() => {
    if (editingTimer || createdTimer) {
      let filter = {};
      if (startDateFilter && endDateFilter) {
        filter = {
          startDate: formatDateToDay(startDateFilter),
          endDate: formatDateToDay(endDateFilter),
          page: 1,
        };
      } else if (startDateFilter && !endDateFilter) {
        const endDate = new Date(startDateFilter);
        endDate.setDate(endDate.getDate() + 1);
        filter = {
          startDate: formatDateToDay(startDateFilter),
          endDate: formatDateToDay(endDate),
          page: 1,
        };
      } else {
        filter = {
          startDate: "",
          endDate: "",
          page: 1,
        };
      }

      handleGetTimers(filter)
        .then((res) => {
          setAllTimers(res.data);
          setEditingTimer(false);
          setLoading(false);
          if (res.data.length < 20) {
            setShowMore(false);
          } else {
            setShowMore(true);
          }
          setPage(1);
          setCreatedTimer(false);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [
    startDateFilter,
    endDateFilter,
    editingTimer,
    setEditingTimer,
    createdTimer,
    setCreatedTimer,
    dispatch,
  ]);

  console.log(allTimers);

  useEffect(() => {
    const handleShowMore = () => {
      if (
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.body.offsetHeight
      ) {
        setLoading(true);
        setShowMore(false);
        setPage((page) => page + 1);
      }
    };

    if (showMore) {
      window.addEventListener("scroll", handleShowMore);
    } else {
      window.removeEventListener("scroll", handleShowMore);
    }
    return () => window.removeEventListener("scroll", handleShowMore);
  }, [showMore]);

  useEffect(() => {
    if (!containerRef.current) return;

    const containerHeight = containerRef.current.offsetHeight;
    if (allTimers.length === 20 && containerHeight < window.innerHeight - 80) {
      setLoading(true);
      setShowMore(false);
      setPage((page) => page + 1);
    }
  }, [allTimers.length]);

  return (
    <div className="layout-container">
      <div className={styles.wrapper}>
        <div className="container" ref={containerRef}>
          <div className={styles.header}>
            <h1 className={styles.title}>Timer</h1>
            <div className={styles.filters}>
              <TimerFilters
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
              />
            </div>
          </div>
          {defaultLoading ? (
            <div className={styles.defaultLoading}>
              <LoadingIcon />
            </div>
          ) : (
            <>
              {allTimers && allTimers.length > 0 ? (
                <div className={styles.mainContent}>
                  <AllTimers
                    allTimers={allTimers}
                    setAllTimers={setAllTimers}
                  />
                </div>
              ) : (
                <div className={styles.content}>
                  <CtaIcon
                    icon={<Timer weight="fill" />}
                    title="Gérez ici vos temps passés
                sur chaque story"
                    subtitle="Timez-vous automatiquement sur les stories sur lesquelles vous travaillez ou manuellement à l’aide de l’icône en bas à droite de votre écran"
                  />
                </div>
              )}
              {loading && (
                <div className={styles.loading}>
                  <LoadingIcon />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimerPage;
