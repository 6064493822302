import { useState } from "react";
import { Customer } from "clients/interfaces/customer";
import AllCustomerStory from "./all-customer-story/AllCustomerStory";
import AllProjectStory from "./all-project-story/AllProjectStory";
import { StoryType } from "story/types";

type Props = {
  close: () => void;
  setStory: (story: StoryType) => void;
  story: StoryType;
};

const CustomerStory = ({ close, setStory, story }: Props) => {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );

  return (
    <>
      {selectedCustomer ? (
        <AllProjectStory
          close={close}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          setStory={setStory}
          story={story}
        />
      ) : (
        <AllCustomerStory
          close={close}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </>
  );
};

export default CustomerStory;
