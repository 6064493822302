import { CreateTimer } from "timer/types";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/useApi";

export const handleGetTimers = (formData?: any) => {
  return getRequest("timer", formData).then((response) => {
    return response.data;
  });
};

export const handleGetTimersTeam = (formData?: any) => {
  return getRequest("timer/team", formData).then((response) => {
    return response.data;
  });
};

export const handleGetCurrentTimer = () => {
  return getRequest("timer/current").then((response) => {
    return response.data;
  });
};

export const handleGetCustomerSuggestions = (search: string) => {
  return getRequest("timer/customer-suggestions", { search }).then(
    (response) => {
      return response.data;
    }
  );
};

export const handleGetMissionSuggestions = ({
  search,
  exclude,
  projectId,
}: {
  search: string;
  exclude?: string;
  projectId?: number;
}) => {
  return getRequest("timer/mission-suggestions", {
    search,
    exclude,
    projectId,
  }).then((response) => {
    return response.data;
  });
};

export const handleCreateTimer = (data: CreateTimer) => {
  return postRequest("timer", data).then((response) => {
    return response.data;
  });
};

export const handleUpdateTimer = (id: number, data: Partial<CreateTimer>) => {
  return putRequest(`timer/${id}`, data).then((response) => {
    return response.data;
  });
};

export const handleDeleteTimer = (id: number) => {
  return deleteRequest(`timer/${id}`).then((response) => {
    return response.data;
  });
};
