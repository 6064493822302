const PartyIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
      <path
        d="m25.241 90.597 16.925-37.833c-.766 3.983 3.845 11.188 8.864 16.208 6.099 6.099 12.944 9.676 16.49 8.764A90092.078 90092.078 0 0 1 29.404 94.76c-.87.4-2.184-.019-3.159-1.004-.985-.975-1.404-2.289-1.004-3.16Z"
        fill="#F0F1F1"
      />
      <path
        d="m25.241 90.597 16.925-37.833c-.765 3.98 3.836 11.175 8.85 16.193L26.23 93.74c-.975-.972-1.388-2.277-.99-3.143Z"
        fill="#fff"
      />
      <path
        d="M50.182 42.545a2.182 2.182 0 1 0 0-4.363 2.182 2.182 0 0 0 0 4.363Z"
        fill="#F769AD"
      />
      <path
        d="M67.637 46.91a2.182 2.182 0 1 0 0-4.364 2.182 2.182 0 0 0 0 4.363Z"
        fill="#F37A10"
      />
      <path
        d="M88.364 55.636a2.182 2.182 0 1 0 0-4.363 2.182 2.182 0 0 0 0 4.363Z"
        fill="#A5E125"
      />
      <path
        d="M89.455 79.636a2.182 2.182 0 1 0 0-4.363 2.182 2.182 0 0 0 0 4.363Z"
        fill="#A769F7"
      />
      <path
        d="M64.364 31.636a2.182 2.182 0 1 0 0-4.363 2.182 2.182 0 0 0 0 4.363Z"
        fill="#55E3F6"
      />
      <path
        d="m79.502 29.075-4.312 2.197-3.422-3.422.757 4.78-4.312 2.197 4.78.757.757 4.78 2.197-4.313 4.78.757-3.422-3.42 2.197-4.313Z"
        fill="#F7E543"
      />
      <path
        d="m78.584 65.454-1.04 3.2h-3.362l2.72 1.806-1.04 3.113 2.722-2.02 2.72 1.956-1.04-3.038 2.721-1.818h-3.362l-1.04-3.199Z"
        fill="#F6A255"
      />
      <path
        d="M70.91 54.545c10.866 0 13.15-6.908 14.985-12.459 1.529-4.619 2.735-8.268 7.923-8.268a1.091 1.091 0 0 0 0-2.182c-6.764 0-8.406 4.965-9.993 9.765-1.865 5.638-3.626 10.963-12.916 10.963a1.09 1.09 0 0 0 0 2.181Z"
        fill="#F65555"
      />
      <path
        d="M56.318 47.761c7.645-6.117 4.93-10.21 2.304-14.172-1.469-2.213-2.986-4.502-2.986-7.407a1.09 1.09 0 1 0-2.182 0c0 3.563 1.78 6.245 3.35 8.614 2.552 3.848 4.238 6.392-1.85 11.262a1.09 1.09 0 0 0 1.364 1.703Z"
        fill="#5568F6"
      />
      <path
        d="M93.818 67.636a1.091 1.091 0 0 0 0-2.181c-3.118 0-5.606-1.66-8.013-3.264-3.886-2.591-7.904-5.272-13.486.31a1.09 1.09 0 1 0 1.543 1.543c4.317-4.316 6.868-2.614 10.732-.038 2.553 1.702 5.445 3.63 9.224 3.63Z"
        fill="#25E197"
      />
      <path
        d="M42.786 88.748c-9.945 4.482 2.03-.89-7.926 3.571a76.752 76.752 0 0 1-6.788-8.05l4.054-9.063a57.502 57.502 0 0 0 10.66 13.542Z"
        fill="#F65555"
      />
      <path
        d="M42.786 88.748c-9.945 4.482 2.03-.89-7.926 3.571a76.752 76.752 0 0 1-6.788-8.05l4.054-9.063a57.502 57.502 0 0 0 10.66 13.542Z"
        fill="#000"
        fill-opacity=".1"
      />
      <path
        d="m37.236 82.734-5.8 5.8a72.33 72.33 0 0 1-3.364-4.267l4.054-9.062a57.294 57.294 0 0 0 5.11 7.53Z"
        fill="#F65555"
      />
      <path
        d="M51.03 68.97c7.07 7.069 15.152 10.766 17.922 7.992 2.748-2.749-.837-10.769-7.993-17.935-7.167-7.156-15.185-10.73-17.934-7.993-3.2 3.196 2.137 12.069 8.005 17.937Z"
        fill="#E2E4E5"
      />
      <path
        d="M49.441 72.609c-3.898-5.277-6.258-10.85-6.757-15.83-.53-1.516-.735-2.89-.52-4.013l-6.121 13.705c1.534 3.313 3.52 6.643 5.902 9.867a63.327 63.327 0 0 0 8.242 9.137l8.293-3.705c-3.288-2.455-6.405-5.582-9.038-9.161Z"
        fill="#F65555"
      />
      <path
        d="M49.441 72.609c-3.898-5.277-6.258-10.85-6.757-15.83-.53-1.516-.735-2.89-.52-4.013l-6.121 13.705c1.534 3.313 3.52 6.643 5.902 9.867a63.327 63.327 0 0 0 8.242 9.137l8.293-3.705c-3.288-2.455-6.405-5.582-9.038-9.161Z"
        fill="#000"
        fill-opacity=".1"
      />
      <path
        d="m60.951 59.02-9.935 9.935c-5.862-5.868-11.187-14.728-7.992-17.921 2.75-2.734 10.763.836 17.927 7.986Z"
        fill="#F0F1F1"
      />
      <path
        d="m42.164 52.766-6.121 13.705c1.534 3.313 3.52 6.643 5.902 9.867.239.324.494.631.738.95l5.905-5.906C45.204 66.47 43.14 61.368 42.68 56.77c-.528-1.513-.73-2.884-.516-4.004Z"
        fill="#FA5655"
      />
    </svg>
  );
};

export default PartyIcon;
