import { ArrowUpRight, X } from "@phosphor-icons/react";
import styles from "./GenerateReport.module.scss";
import TimerFilters from "timer/components/timer-filters/TimerFilters";
import { useState } from "react";
import { Mission } from "clients/interfaces/mission";
import ReportMission from "../report-mission/ReportMission";
import { handleCreateReport } from "clients/controllers/reports";
import { CreateReport } from "clients/interfaces/report";
import { useParams } from "react-router-dom";
import { formatDateToDay } from "utils/formatDate";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/useWindowSize";

type Props = {
  missions?: Mission[];
  setGenerateReport: (value: boolean) => void;
};

const GenerateReport = ({ missions, setGenerateReport }: Props) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { id } = useParams();
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [missionsChecked, setMissionsChecked] = useState<number[]>(
    (missions && missions.map((mission) => mission.id)) || []
  );

  const clickGenerateReport = async () => {
    if (!id || !startDateFilter || !endDateFilter) return;
    try {
      const report: CreateReport = {
        project_id: Number(id),
        mission_ids: missionsChecked,
        start_at: formatDateToDay(startDateFilter),
        end_at: formatDateToDay(endDateFilter),
      };
      const {
        data: { unique_hash },
      } = await handleCreateReport(report);
      const newTab = window.open(`/rapport/${unique_hash}`, "_blank");

      if (newTab) {
        newTab.focus();
      }
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const handleClose = () => {
    setGenerateReport(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Générer un rapport</p>
        {width && width > 768 ? (
          <>
            <button
              className="m-button m-button--black"
              onClick={clickGenerateReport}
            >
              <p>Générer le rapport</p>
              <ArrowUpRight weight="bold" />
            </button>
          </>
        ) : null}
        <button
          className="m-button m-button--grey m-button--ratio"
          type="button"
          onClick={handleClose}
        >
          <span className="sr-only">Fermer l'ajout de mission</span>
          <X weight="bold" />
        </button>
      </div>
      <p className={styles.titleDate}>Période à générer</p>
      <p className={styles.subtitleDate}>
        Sélectionnez la période des données du rapport
      </p>
      <TimerFilters
        startDateFilter={startDateFilter}
        setStartDateFilter={setStartDateFilter}
        endDateFilter={endDateFilter}
        setEndDateFilter={setEndDateFilter}
      />
      <p className={styles.titleMissions}>
        Missions à afficher sur le rapport :
      </p>
      <ul className={styles.missions}>
        {missions &&
          missions.map((mission) => {
            const isChecked = missionsChecked.includes(mission.id);
            return (
              <li key={mission.id}>
                <ReportMission
                  isChecked={isChecked}
                  mission={mission}
                  missionsChecked={missionsChecked}
                  setMissionsChecked={setMissionsChecked}
                />
              </li>
            );
          })}
      </ul>
      {width && width < 768 ? (
        <>
          <button
            className="m-button m-button--black"
            onClick={clickGenerateReport}
          >
            <p>Générer le rapport</p>
            <ArrowUpRight weight="bold" />
          </button>
        </>
      ) : null}
    </div>
  );
};

export default GenerateReport;
