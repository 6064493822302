import { MissionProjectLink } from "clients/interfaces/missionProjectLink";
import styles from "./MissionProjectStory.module.scss";
import ProjectTag from "ui/components/project-tag/ProjectTag";
import { secondsToHours } from "utils/formatTime";
import { Link } from "react-router-dom";
import { ArrowUpRight } from "@phosphor-icons/react";
import ProgressBar from "ui/components/progress-bar/ProgressBar";

type Props = {
  customerId?: number;
  missionProjectLink: MissionProjectLink;
};

const MissionProjectStory = ({ customerId, missionProjectLink }: Props) => {
  if (!missionProjectLink.mission) return null;

  return (
    <Link
      to={`/clients/${customerId}/projects/${missionProjectLink.project_id}`}
      target="_blank"
      rel="noopener"
    >
      <div className={styles.container}>
        <div className={styles.infos}>
          <ProjectTag
            content={missionProjectLink.mission?.name}
            contentColor={missionProjectLink.mission?.color}
          />
          <div className={styles.right}>
            <p>
              {missionProjectLink.time && (
                <>
                  {secondsToHours(
                    (missionProjectLink.time && missionProjectLink.time) || 0
                  )}{" "}
                  consommées
                  {missionProjectLink.hours !== 0 && " sur "}
                </>
              )}
              {missionProjectLink.hours !== 0 && (
                <>
                  {missionProjectLink.hours}h00
                  {missionProjectLink.time &&
                  missionProjectLink.hours * 3600 - missionProjectLink.time <
                    0 ? (
                    <span className={styles.red}>
                      +{" "}
                      {secondsToHours(
                        Math.abs(
                          missionProjectLink.hours * 3600 -
                            missionProjectLink.time
                        )
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </p>
            <ArrowUpRight weight="bold" />
          </div>
        </div>
        <ProgressBar
          color={missionProjectLink.mission.color}
          completedPercent={
            (missionProjectLink.time &&
              (missionProjectLink.time * 100) /
                (missionProjectLink.hours * 3600)) ||
            0
          }
        />
      </div>
    </Link>
  );
};

export default MissionProjectStory;
