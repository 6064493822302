import { CreateMediaInterface } from "profil/interfaces/media";
import { deleteRequest, postRequest } from "utils/useApi";

export const handleCreateMedia = (media: CreateMediaInterface) => {
  return postRequest("medias", media).then((response) => {
    return response.data;
  });
};

export const handleDeleteMedia = (mediaId: number) => {
  return deleteRequest("medias/" + mediaId).then((response) => {
    return response.data;
  });
};
