import styles from "./Login.module.scss";
import { Check, Eye, EyeSlash, Timer } from "@phosphor-icons/react";
import SprintIcon from "ui/icons/SprintIcon";
import StatistiquesIcon from "ui/icons/StatistiquesIcon";
import { userLogin } from "account/types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleLogin } from "account/controllers/users";
import { onLogin } from "users/actions";
import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import { useNavigate } from "react-router-dom";
import LogoIcon from "ui/icons/LogoIcon";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [user, setUser] = useState<userLogin>({
    email: "",
    password: "",
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    setUser((user) => ({
      ...user,
      [name]: value,
    }));
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    handleLogin(user)
      .then(function (response: any) {
        dispatch(onLogin(response.data.user, response.data.token.token));
        const data = {
          status: true,
          message: `Bienvenue, ${response.data.user.firstname} !`,
          error: false,
        };
        dispatch(showModal(data));
        setTimeout(() => navigate("/planification", { replace: true }), 1500);
      })
      .catch((err) => {
        const data = {
          status: true,
          message:
            errorsAPI[err.response.data.message as keyof typeof errorsAPI],
          error: true,
        };
        dispatch(showModal(data));
      });
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <LogoIcon />
        </div>
        <div className={styles.left}>
          <h1 className={styles.title}>Connexion</h1>
          <form onSubmit={handleSubmit}>
            <div className={styles.input}>
              <label htmlFor="email" className="m-label m-label--white">
                Adresse email <span>*</span>
              </label>
              <input
                data-cy="email"
                type="email"
                className="m-input m-input--dark"
                placeholder="Adresse email"
                onChange={handleChange}
                value={user.email}
                name="email"
              />
            </div>
            <div className={styles.input}>
              <label htmlFor="email" className="m-label m-label--white">
                Mot de passe <span>*</span>
              </label>

              <div className={styles.passwordInput}>
                <input
                  data-cy="password"
                  type={passwordShown ? "text" : "password"}
                  className="m-input m-input--dark"
                  placeholder="Mot de passe"
                  onChange={handleChange}
                  value={user.password}
                  name="password"
                />
                <button type="button" onClick={togglePassword}>
                  {!passwordShown ? (
                    <Eye size={20} weight="fill" />
                  ) : (
                    <EyeSlash size={20} weight="fill" />
                  )}
                </button>
              </div>
            </div>
            <div className={styles.remember}>
              <div className="m-checkbox">
                <input type={"checkbox"} />
                <div className="checkmark checkmark--white">
                  <Check weight="bold" />
                </div>
              </div>
              <p>Se souvenir de moi</p>
            </div>
            <button
              data-cy="submit-login"
              type="submit"
              className="m-button m-button--white"
            >
              Connexion
            </button>
          </form>
        </div>
        <div className={styles.right}>
          <div className={styles.card}>
            <div className={styles.icon}>
              <SprintIcon />
            </div>
            <div className={styles.content}>
              <h2 className={styles.subtitle}>Gérez votre production</h2>
              <p className={styles.cardText}>
                Créez vos clients, vos projets et gérez un backlog de stories et
                des sprints de travail très simplement sous forme de kanban ou
                de planing macro
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.icon}>
              <Timer weight="fill" />
            </div>
            <div className={styles.content}>
              <h2 className={styles.subtitle}>
                Time tracking simple et efficace
              </h2>
              <p className={styles.cardText}>
                Suivez votre activité en temps réel sur chaque projet et générez
                des reportings détaillés pour vos clients en 1 clic
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.icon}>
              <StatistiquesIcon />
            </div>
            <div className={styles.content}>
              <h2 className={styles.subtitle}>Statistiques détaillées</h2>
              <p className={styles.cardText}>
                Visualisez les temps de travail de chaque membre de votre équipe
                sur vos différents projets
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
