import { destroyCookie, setCookie } from "nookies";
import { UserType } from "users/types";

export const actionTypesUser = {
  ON_LOGIN: "ON_LOGIN",
  ON_LOGOUT: "ON_LOGOUT",
  UPDATE_USER: "UPDATE_USER",
};

export const ON_LOGIN = "ON_LOGIN";

export const onLogin = (user: UserType, token: string) => {
  const cookiesOptions = {
    maxAge: 7 * 24 * 60 * 60,
    path: "/",
  };
  setCookie(null, "token", token, cookiesOptions);

  return {
    type: ON_LOGIN,
    payload: { user, token },
  };
};

export const onUpdateUser = (user: any) => {
  return { type: actionTypesUser.UPDATE_USER, payload: { user } };
};

export const onLogout = () => {
  destroyCookie(null, "token");
  return { type: actionTypesUser.ON_LOGOUT };
};
