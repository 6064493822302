import styles from "./SprintOptions.module.scss";
import {
  ArrowUpRight,
  Lightning,
  LightningSlash,
  Trash,
} from "@phosphor-icons/react";
import Dropdown from "app/components/dropdown/Dropdown";
import { useState } from "react";
import Popup from "ui/components/popup/Popup";
import DeletePopup from "./delete-popup/DeletePopup";
import FinishPopup from "./finish-popup/FinishPopup";
import StartPopup from "./start-popup/StartPopup";
import { PencilSimple } from "phosphor-react";
import SprintSettingsPopup from "../sprint-settings-popup/SprintSettingsPopup";
import OptionsIcon from "ui/icons/OptionsIcon";
import { useNavigate } from "react-router-dom";

type Props = {
  isActive: boolean;
  sprintNb: string;
  storiesNb: number;
  sprintId: number;
  start_date?: string;
  end_date?: string;
  sprintName: string;
};

const SprintOptions = ({
  isActive,
  sprintNb,
  storiesNb,
  sprintId,
  start_date,
  end_date,
  sprintName,
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(false);
  const navigate = useNavigate();

  const openOptions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowOptions(true);
  };

  const openUpdateModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowUpdateModal(true);
  };

  const openDeleteModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteModal(false);
  };

  const openFinishModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowFinishModal(true);
  };

  const closeFinishModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowFinishModal(false);
  };

  const openStartModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowStartModal(true);
  };

  const closeStartModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowStartModal(false);
  };

  const renderFinishSprint = () => {
    if (isActive) {
      return (
        <>
          <li>
            <button className={styles.dropdownItem} onClick={openFinishModal}>
              <LightningSlash weight="fill" />
              Terminer le sprint
            </button>
          </li>
          {showFinishModal && (
            <Popup width="496px" setIsOpen={setShowFinishModal}>
              <FinishPopup
                closePopup={closeFinishModal}
                sprintNb={sprintNb}
                sprintId={sprintId}
              />
            </Popup>
          )}
        </>
      );
    } else {
      return (
        <>
          <li>
            <button className={styles.dropdownItem} onClick={openStartModal}>
              <Lightning weight="fill" />
              Démarrer le sprint
            </button>
          </li>
          {showStartModal && (
            <Popup width="496px" setIsOpen={setShowStartModal}>
              <StartPopup
                closePopup={closeStartModal}
                sprintNb={sprintNb}
                sprintId={sprintId}
              />
            </Popup>
          )}
        </>
      );
    }
  };

  const redirectSingle = () => {
    navigate("/planification/sprint", { replace: true });
  };

  return (
    <div className={styles.optionsContainer}>
      <button type="button" onClick={redirectSingle}>
        <ArrowUpRight size={16} color="#5B5B6D" />
      </button>
      <button className={styles.options} onClick={openOptions}>
        <OptionsIcon />
      </button>
      {showOptions && (
        <div className={styles.dropdown}>
          <Dropdown setIsOpen={setShowOptions}>
            <ul>
              <li>
                <button
                  className={styles.dropdownItem}
                  onClick={openUpdateModal}
                >
                  <PencilSimple weight="fill" />
                  Modifier le sprint
                </button>
              </li>
              {showUpdateModal && (
                <Popup width="496px" setIsOpen={setShowUpdateModal}>
                  <SprintSettingsPopup
                    sprint_id={sprintId}
                    start_date={start_date}
                    end_date={end_date}
                    name={sprintName}
                    handleClosePopup={() => setShowUpdateModal(false)}
                  />
                </Popup>
              )}
              <li>
                <button
                  className={styles.dropdownItem}
                  onClick={openDeleteModal}
                >
                  <Trash weight="fill" />
                  Supprimer le sprint
                </button>
              </li>
              {showDeleteModal && (
                <Popup width="496px" setIsOpen={setShowDeleteModal}>
                  <DeletePopup
                    closePopup={closeDeleteModal}
                    sprintNb={sprintNb}
                    storiesNb={storiesNb}
                    sprintId={sprintId}
                  />
                </Popup>
              )}
              {renderFinishSprint()}
            </ul>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default SprintOptions;
