import { XCircle } from "@phosphor-icons/react";
import styles from "./ProjectTag.module.scss";
import { useCallback, useMemo } from "react";

type Props = {
  content: string;
  contentColor: string;
  project?: string;
  size?: "small" | "large";
  onDelete?: Function | null;
  mainTimer?: Boolean | null;
};

const ProjectTag = ({
  content,
  contentColor,
  project,
  size = "small",
  onDelete,
  mainTimer,
}: Props) => {
  const handleDelete = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (onDelete) {
        onDelete();
      }
    },
    [onDelete]
  );

  const renderDelete = useMemo(() => {
    if (!onDelete) return null;
    return (
      <button
        type="button"
        className={styles.deleteButtonStory}
        onClick={handleDelete}
      >
        <XCircle weight="fill" />
        <span className="sr-only">Supprimer</span>
      </button>
    );
  }, [handleDelete, onDelete]);

  if (project) {
    return (
      <div
        className={`${styles.container} ${size === "large" && styles.large}`}
      >
        <div
          className={styles.titleContainer}
          style={{ backgroundColor: contentColor }}
        >
          <p className={styles.title}>{content}</p>
        </div>
        <div
          className={styles.project}
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), ${contentColor}`,
          }}
        >
          <p className={styles.text}>{project}</p>
          {renderDelete}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${styles.titleOnlyContainer} ${
          size === "large" && styles.large
        }`}
        style={{ backgroundColor: contentColor }}
      >
        <p className={styles.titleOnly}>{content}</p>
        {renderDelete}
      </div>
    );
  }
};

export default ProjectTag;
