import styles from "./CalendarSingle.module.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

registerLocale("fr", fr);

type Props = {
  startDate: Date | null;
  setStartDate: (date: Date) => void;
  size?: string;
};

const CalendarSingle = ({ startDate, setStartDate, size }: Props) => {
  return (
    <div
      className={`${styles.container} ${size === "small" ? styles.small : ""}`}
    >
      <DatePicker
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        locale="fr"
        startDate={startDate}
        dateFormat={"dd/MM/yyyy"}
        inline
        formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 3)}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
          <div className={styles.calendarHeader}>
            <button
              type="button"
              onClick={decreaseMonth}
              className={styles.decreaseMonth}
            >
              <CaretLeft weight="bold" />
            </button>
            <p>
              {monthDate.toLocaleString("fr", {
                month: "long",
                year: "numeric",
              })}
            </p>
            <button
              type="button"
              onClick={increaseMonth}
              className={styles.increaseMonth}
            >
              <CaretRight weight="bold" />
            </button>
          </div>
        )}
      />
    </div>
  );
};

export default CalendarSingle;
