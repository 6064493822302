import styles from "./ProgressBar.module.scss";

type Props = {
  color: string;
  completedPercent: number;
  hoverText?: string;
  backgroundColor?: string;
};

const ProgressBar = ({
  color,
  completedPercent,
  hoverText,
  backgroundColor = "#e6ecf4",
}: Props) => {
  return (
    <div
      className={`${styles.wrapper} ${hoverText ? styles.hover : ""}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={styles.progress}
        style={{ width: `${completedPercent}%`, backgroundColor: color }}
      >
        {hoverText && <span className={styles.hoverText}>{hoverText}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
