import { useEffect, useState, useContext } from "react";
import styles from "./Planification.module.scss";
import Backlog from "sprint/components/backlog/Backlog";
import SprintsList from "sprint/components/sprints-list/SprintsList";
import Popup from "ui/components/popup/Popup";
import CreateSprintPopup from "sprint/components/create-sprint-popup/CreateSprintPopup";
import { DragDropContext, DragUpdate, DropResult } from "react-beautiful-dnd";
import { handleGetStories, handleUpdateStory } from "story/controllers/story";
import { useSelector } from "react-redux";
import { RootState } from "app/redux/store";
import { StoryType } from "story/types";
import socket from "utils/socket";
import ProfilLayout from "account/components/profilLayout/ProfilLayout";
import { SprintType } from "sprint/types";
import { handleGetSprints } from "sprint/controllers";
import { EditingSprintsContext } from "planification/context/EditingSprintsProvider";
import LoadingIcon from "ui/icons/LoadingIcon";
import { useDispatch } from "react-redux";
import { showModal } from "app/actions/modal";
import { errorsAPI } from "app/constants/errors";
import useLocalStorage from "hooks/useLocalStorage";
import { updateFormatCard } from "sprint/actions/sprints";

const Planification = () => {
  const dispatch = useDispatch();
  const [typeCard] = useLocalStorage("typeCard");
  const { editingSprints, setEditingSprints } = useContext(
    EditingSprintsContext
  );

  useEffect(() => {
    dispatch(updateFormatCard(typeCard));
  }, [typeCard, dispatch]);

  const user = useSelector((state: RootState) => state.user.user);
  const [sprints, setSprints] = useState<SprintType[]>([]);
  const [backlogStories, setBacklogStories] = useState<StoryType[]>([]);
  const [backlogData, setBacklogData] = useState<any[]>([]);
  const [createSprint, setCreateSprint] = useState(false);
  const [loadingSprint, setLoadingSprint] = useState(true);
  const [loadingBacklog, setLoadingBacklog] = useState(true);
  const [hovered, setHovered] = useState<string | null>(null);

  const showCreateSprint = () => {
    setCreateSprint(true);
  };

  const closeCreateSprint = () => {
    setCreateSprint(false);
  };

  useEffect(() => {
    handleGetStories()
      .then((res) => {
        setBacklogStories(res.data.stories);
        setBacklogData(res.data);
        setLoadingBacklog(false);
      })
      .catch((err) => {
        setLoadingBacklog(false);
        const toastData = {
          status: true,
          message: "Le backlog n'a pas pu être récupéré",
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });

    handleGetSprints({ forPlanif: true })
      .then((res) => {
        setSprints(res.data);
        setLoadingSprint(false);
      })
      .catch((err) => {
        setLoadingSprint(false);
        const toastData = {
          status: true,
          message: "Les sprints n'ont pas pu être récupérés",
          error: true,
        };
        dispatch(showModal(toastData));
        console.log(err);
      });
  }, [dispatch]);

  useEffect(() => {
    if (editingSprints) {
      handleGetSprints({ forPlanif: true })
        .then((res) => {
          setSprints(res.data);
          setEditingSprints(false);
        })
        .catch((err) => {
          const toastData = {
            status: true,
            message:
              errorsAPI[err.response.data.message as keyof typeof errorsAPI],
            error: true,
          };
          dispatch(showModal(toastData));
          console.log(err);
        });
    }
  }, [dispatch, editingSprints, setEditingSprints]);

  const onDragEnd = async (result: DropResult) => {
    setHovered(null);
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let type = "todo";

    const copySprints = [...sprints];

    const sourceSprint = copySprints.find(
      (sprint) => sprint.id === Number(source.droppableId)
    );

    const destinationSprint = copySprints.find(
      (sprint) => sprint.id === Number(destination.droppableId)
    );

    if (
      destination.droppableId === "backlog" &&
      source.droppableId === "backlog"
    ) {
      const copyBacklogStories = [...backlogStories];

      const draggedStoryIndex = copyBacklogStories.findIndex(
        (story) => story.id === Number(draggableId)
      );

      if (draggedStoryIndex !== -1) {
        const [draggedStory] = copyBacklogStories.splice(draggedStoryIndex, 1);
        copyBacklogStories.splice(destination.index, 0, draggedStory);

        setBacklogStories(copyBacklogStories);
      }
    } else if (
      destination.droppableId !== "backlog" &&
      source.droppableId !== "backlog"
    ) {
      if (!sourceSprint || !destinationSprint) return;
      const draggedStoryIndex = sourceSprint.stories.findIndex(
        (story: StoryType) => story.id === Number(draggableId)
      );

      if (draggedStoryIndex !== -1) {
        const updatedSourceStories = [...sourceSprint.stories];
        const updatedDestinationStories = [...destinationSprint.stories];

        const [draggedStory] = updatedSourceStories.splice(
          draggedStoryIndex,
          1
        );

        if (source.droppableId === destination.droppableId) {
          updatedSourceStories.splice(destination.index, 0, draggedStory);

          const updatedSprints = sprints.map((sprint) => {
            if (sprint.id === Number(source.droppableId)) {
              return {
                ...sprint,
                stories: updatedSourceStories,
              };
            }
            return sprint;
          });

          setSprints(updatedSprints);
        } else {
          updatedDestinationStories.splice(destination.index, 0, draggedStory);

          const updatedSprints = sprints.map((sprint) => {
            if (sprint.id === Number(source.droppableId)) {
              return {
                ...sprint,
                stories: updatedSourceStories,
              };
            } else if (sprint.id === Number(destination.droppableId)) {
              return {
                ...sprint,
                stories: updatedDestinationStories,
              };
            }
            return sprint;
          });

          setSprints(updatedSprints);
        }
        type = draggedStory.type;
      }
    } else if (
      destination.droppableId !== "backlog" &&
      source.droppableId === "backlog"
    ) {
      if (!destinationSprint) return;
      const draggedStoryIndex = backlogStories.findIndex(
        (story: StoryType) => story.id === Number(draggableId)
      );

      if (draggedStoryIndex !== -1) {
        const updatedBacklogStories = [...backlogStories];
        const updatedDestinationStories = [...destinationSprint.stories];

        const [draggedStory] = updatedBacklogStories.splice(
          draggedStoryIndex,
          1
        );

        updatedDestinationStories.splice(destination.index, 0, draggedStory);

        // update sprints and backlogStories states

        const updatedSprints = sprints.map((sprint) => {
          if (sprint.id === Number(destination.droppableId)) {
            return {
              ...sprint,
              stories: updatedDestinationStories,
            };
          }
          return sprint;
        });

        setSprints(updatedSprints);

        setBacklogStories(updatedBacklogStories);
      }
    } else {
      if (!sourceSprint) return;
      const draggedStoryIndex = sourceSprint.stories.findIndex(
        (story: StoryType) => story.id === Number(draggableId)
      );

      if (draggedStoryIndex !== -1) {
        const updatedSourceStories = [...sourceSprint.stories];
        const updatedBacklogStories = [...backlogStories];

        const [draggedStory] = updatedSourceStories.splice(
          draggedStoryIndex,
          1
        );

        updatedBacklogStories.splice(destination.index, 0, draggedStory);

        // update sprints and backlogStories states

        const updatedSprints = sprints.map((sprint) => {
          if (sprint.id === Number(source.droppableId)) {
            return {
              ...sprint,
              stories: updatedSourceStories,
            };
          }
          return sprint;
        });

        setSprints(updatedSprints);

        setBacklogStories(updatedBacklogStories);
      }
    }

    try {
      await handleUpdateStory(Number(draggableId), {
        sprint_id:
          destination.droppableId === "backlog"
            ? 0
            : Number(destination.droppableId),
        planif_order: destination.index,
        type: type as "todo" | "doing" | "review" | "done",
        order: 0,
      });
    } catch (err: any) {
      const toastData = {
        status: true,
        message: errorsAPI[err.response.data.message as keyof typeof errorsAPI],
        error: true,
      };
      dispatch(showModal(toastData));
      console.log(err);
    }
  };

  const onDragUpdate = (update: DragUpdate) => {
    setHovered(update.destination?.droppableId || null);
  };

  useEffect(() => {
    socket.on("updateSprint", async (data) => {
      if (user?.team_selected === data) {
        handleGetStories()
          .then((res) => {
            setBacklogStories(res.data.stories);
          })
          .catch((err) => {
            const toastData = {
              status: true,
              message:
                errorsAPI[err.response.data.message as keyof typeof errorsAPI],
              error: true,
            };
            dispatch(showModal(toastData));
            console.log(err);
          });

        handleGetSprints({ forPlanif: true })
          .then((res) => {
            setSprints(res.data);
          })
          .catch((err) => {
            const toastData = {
              status: true,
              message:
                errorsAPI[err.response.data.message as keyof typeof errorsAPI],
              error: true,
            };
            dispatch(showModal(toastData));
            console.log(err);
          });
      }
    });

    return () => {
      socket.off("updateSprint");
    };
  }, [user?.team_selected, dispatch]);

  return (
    <ProfilLayout>
      <div className="layout-container">
        <div className={styles.wrapper}>
          <div className="container">
            <div className={styles.header}>
              <h1 className={styles.title}>Planification</h1>
              <button
                className="m-button m-button--black"
                onClick={showCreateSprint}
              >
                Créer un sprint
              </button>
              {createSprint && (
                <Popup setIsOpen={setCreateSprint} width="496px">
                  <CreateSprintPopup
                    handleCloseCreateSprintPopup={closeCreateSprint}
                  />
                </Popup>
              )}
            </div>
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              <div className={styles.flex_wrapper}>
                <div
                  className={`${styles.left} ${
                    hovered === "backlog" ? styles.hovered : ""
                  }`}
                >
                  {loadingBacklog ? (
                    <div className={styles.loader}>
                      <LoadingIcon />
                    </div>
                  ) : (
                    <div className={styles.backlog}>
                      <Backlog
                        backlogStories={backlogStories}
                        typeCard={typeCard}
                      />
                    </div>
                  )}
                </div>
                {loadingSprint ? (
                  <div className={styles.right}>
                    <div className={styles.loader}>
                      <LoadingIcon />
                    </div>
                  </div>
                ) : (
                  <SprintsList
                    hovered={hovered}
                    sprints={sprints}
                    showCreateSprint={showCreateSprint}
                  />
                )}
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
    </ProfilLayout>
  );
};

export default Planification;
