export const secondsToHours = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedMinutes = String(minutes).padStart(2, "0");

  const formattedTime = `${hours}h${formattedMinutes}`;

  return formattedTime;
};
